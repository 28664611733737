// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    margin-top: 10px;
    margin-bottom: 16px;
}

.headerText {
    font-size: 26px;
    font-weight: bold;
    font-family: "Helvetica" !important;
}`, "",{"version":3,"sources":["webpack://./src/screens/DoctorNew/patient-details/Header.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,mCAAmC;AACvC","sourcesContent":[".header {\n    margin-top: 10px;\n    margin-bottom: 16px;\n}\n\n.headerText {\n    font-size: 26px;\n    font-weight: bold;\n    font-family: \"Helvetica\" !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
