import axios from "axios";

import { BASE_URL } from "../constants";
import { TEST_BASE_URL } from "../constants";

export const getDoctorProfile = async (id) => {
  try {
    //${BASE_URL}
    const response = await axios.post(
      `${BASE_URL}/api/users/getDoctorProfile`,
      { id }
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const setDoctorProfile = async (id, values) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/setDoctorProfile`,
      { id, ...values }
    );
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const setDoctorPassword = async (id, oldPassword, newPassword) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/setDoctorPassword`,
      { id, oldPassword, newPassword }
    );
    return response.data.status;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getDoctors = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/users/getDoctors`);
    return response.data.result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getPatientProfile = async (id) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/getPatientProfile`,
      { id }
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

//获取病人周期性数据
export const getPatientPeriodicMeasurements = async ({patientId}) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/getPatientPeriodicMeasurements`,
      { patientId }
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// 为患者请求对应医生
export const getPatientDoctor = async (patientId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/getPatientDoctor`, {
        patientId
      },
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching doctors for the patient:", error);
    throw error;
  }
};

export const setPatientProfile = async (id, values) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/setPatientProfile`,
      { id, ...values }
    );
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const setPatientPassword = async (id, oldPassword, newPassword) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/setPatientPassword`,
      { id, oldPassword, newPassword }
    );
    return response.data.status;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

//提取当前登录的医药公司的所有临床试验信息
export const getClinicalTrialsList = async (companyId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/getClinicalTrialsList`, {
        companyId,
      },
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching clinical trials list:", error);
    throw error;
  }
};

//提取当前登录的医药公司的所有临床试验的详细信息
export const getDetailedClinicalTrialsList = async (companyId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/getDetailedClinicalTrialsList`, {
        companyId,
      },
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching detailed clinical trials list:", error);
    throw error;
  }
};

export const checkExistingClinicalTrialsId = async (trialId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/checkExistingClinicalTrialsId`, {
        trialId,
      },
    );
    return response.data.result;
  } catch (error) {
    console.error("Error checking if clinical trials id existed:", error);
    throw error;
  }
};

// 修改 createNewClinicalTrials 函数
export const createNewClinicalTrials = async ({formDataToSubmit, companyInfo}) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/createNewClinicalTrials`, 
      { formDataToSubmit, companyInfo }
    );
    console.log(response.data.result);
    return response.data.result;
  } catch (error) {
    console.error("Error creating new clinical trials:", error);
    throw error;
  }
};



//提取当前指定的临床试验的详细信息
export const getSpecificClinicalTrialsInfo = async (trial_id) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/getSpecificClinicalTrialsInfo`, {
        trial_id,
      },
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching specific clinical trials information.", error);
    throw error;
  }
};
//获取特定临床试验对应的已注册患者列表
export const getSpecificClinicalTrialsPatients = async (trial_id) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/getSpecificClinicalTrialsPatients`, {
        trial_id,
      },
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching specific clinical trials information.", error);
    throw error;
  }
};

//获取特定临床试验对应的邀请中的患者列表
export const getSpecificClinicalTrialsInvitingPatients = async (trial_id) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/getSpecificClinicalTrialsInvitingPatients`, {
        trial_id,
      },
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching specific clinical trials information.", error);
    throw error;
  }
};

//获取特定临床试验对应的申请中的患者列表
export const getSpecificClinicalTrialsApplyingPatients = async (trial_id) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/getSpecificClinicalTrialsApplyingPatients`, {
        trial_id,
      },
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching specific clinical trials information.", error);
    throw error;
  }
};

// 获取特定临床试验匹配患者的信息
export const getSpecificClinicalTrialsMatchedPatients = async (criteria, criteriaValues) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/getSpecificClinicalTrialsMatchedPatients`, 
      {
        criteria,
        criteriaValues,
      },
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching specific clinical trials information.", error);
    throw error;
  }
};


//获取所有临床试验匹配患者的信息
export const getClinicalTrialsMatchedPatients = async () => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/getClinicalTrialsMatchedPatients`, {
      },
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching specific clinical trials information.", error);
    throw error;
  }
};
//提取当前登录医药公司的消息列表
export const getPharmaceuticals_Notifications = async (companyId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/getPharmaceuticals_Notifications`, {
        companyId,
      },
    );
    return response.data.result;
  } catch (error) {
    console.error("Failed to fetch notifications:", error);
    throw error;
  }
};

//更改消息的状态
export const updatePharmaceuticalsNotificationStatus = async (messageId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/updatePharmaceuticalsNotificationStatus`, {
        messageId,
      },
    );
    return response.data.result;
  } catch (error) {
    console.error("Failed to update notifications:", error);
    throw error;
  }
};
//获取药店行动状态
export const getPharmaceuticals_ActionsStatus = async (companyId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/getPharmaceuticals_ActionsStatus`, {
        companyId,
      },
    );
    return response.data.result;
  } catch (error) {
    console.error("Failed to fetch action status:", error);
    throw error;
  }
};
//获取药店总结
export const getPharmaceuticals_DashboardSummary = async (companyId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/getPharmaceuticals_DashboardSummary`, {
        companyId,
      },
    );
    return response.data.result;
  } catch (error) {
    console.error("Failed to fetch dashboard summary:", error);
    throw error;
  }
};
//获取医药公司病人来源
export const getPharmaceuticals_PatientSource = async (companyId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/getPharmaceuticals_PatientSource`, {
        companyId,
      },
    );
    return response.data.result;
  } catch (error) {
    console.error("Failed to fetch source of the patients:", error);
    throw error;
  }
};


//药店浏览病人信息
export const PharmaceuticalsViewPatientProfile = async (patientId,viewMode) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/PharmaceuticalsViewPatientProfile`, {
        patientId,
        viewMode,
      },
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching patient data:", error);
    throw error;
  }
};

// 查询行为是否已存在
export const checkExistingActions = async (trialId, receivedUserId, actionType, isCompleted) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/checkExistingActions`, {
        trialId, receivedUserId, actionType, isCompleted,
      }
    );
    return response.data.exists;
  } catch (error) {
    console.error("Error checking existing actions:", error);
    throw error;
  }
};

//邀请行为
export const PharmaceuticalsActionCreate = async (trialId, initiatorType, initiatorId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/PharmaceuticalsActionCreate`, {
        trialId, initiatorType, initiatorId,
      },
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching patient data:", error);
    throw error;
  }
};

//邀请行为
export const PharmaceuticalsRequestCreate = async (actionId, receivedUserType, receivedUserId, inviteMessage, isPrimaryRequest) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/PharmaceuticalsRequestCreate`, {
        actionId, receivedUserType, receivedUserId, inviteMessage, isPrimaryRequest,
      },
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching patient data:", error);
    throw error;
  }
};

//查询clinical staff的所有行为详情
export const getWebStaffActions  = async (userId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/getWebStaffActions`, {
        userId,
      },
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching Web Staff actions", error);
    throw error;
  }
};

//查询药房的所有行为详情
export const getPharmaceuticals_DetailedActions = async (companyId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/getPharmaceuticals_DetailedActions`, {
        companyId,
      },
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching patient data:", error);
    throw error;
  }
};

//更新对反馈的阅读状态
export const updateResponseReadStatus = async (actionId, responseUserType, responseUserId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/updateResponseReadStatus`, {
        actionId, responseUserType, responseUserId
      },
    );
    return response.data.result;
  } catch (error) {
    console.error("Error updating read status:", error);
    throw error;
  }
};

//更新对请求的阅读状态
export const updateRequestReadStatus = async (actionId, responseUserType, responseUserId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/updateRequestReadStatus`, {
        actionId, responseUserType, responseUserId
      },
    );
    return response.data.result;
  } catch (error) {
    console.error("Error updating read status:", error);
    throw error;
  }
};

// 更新对请求的阅读状态
export const updateActionResponse = async ({ actionId, responseUserType, responseUserId, readStatus, responseStatus, note, timestamp }) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/users/updateActionResponse`, {
        actionId, responseUserType, responseUserId, readStatus, responseStatus, note, timestamp
      },
    );
    return response.data.result;
  } catch (error) {
    console.error("Error updating action response status:", error);
    throw error;
  }
};


// 医药公司message模块
export const MessageSend = async ({conversationId,senderType,sender_id,receiverType,receiver_id,viewer_permissions,subject,content})=> {
  try {
    const response = await axios.post(`${BASE_URL}/api/users/MessageSend`, {
      conversationId,senderType,sender_id,receiverType,receiver_id,viewer_permissions,subject,content,
    });
    return response.data.result;
  } catch (error) {
    console.error("Error sending message:", error);
    throw error;
  }
};

// 根据ID查询医药公司相关消息记录
export const getMessagesByTypeAndId = async ({user_id, user_type})=> {
  try {
    const response = await axios.post(`${BASE_URL}/api/users/getMessagesByTypeAndId`, {
      user_id, user_type
    });
    return response.data.result;
  } catch (error) {
    console.error("Error fetching:", error);
    throw error;
  }
};

// 更新消息阅读状态
export const MessageReadStatusUpdate = async ({ messageIds }) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/users/MessageReadStatusUpdate`, {
      messageIds,
    });
    return response.data.result;
  } catch (error) {
    console.error("Error updating read status:", error);
    throw error;
  }
};


