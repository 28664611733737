import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  Row,
  Col,
  Divider,
  message,
  Checkbox,
  Select,
} from "antd";
import {
  SaveOutlined,
  PrinterOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import BASE_URLS from "../constants";

const { Title } = Typography;
const { Option } = Select;

const ReferralForm = ({ patientId, doctorId, closeModal }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [doctors, setDoctors] = useState([]);

  const printForm = () => {
    window.print();
  };

  useEffect(() => {
    setIsDisabled(!form.getFieldValue("isReferredDoctorInSystem"));
  }, [form]);

  useEffect(() => {
    fetchDoctors();
  }, []);

  const initialValues = {
    referredDoctorFName: "",
    referredDoctorLName: "",
    referredDoctorPhone: "",
    referredDoctorSpecialization: "",
    isReferredDoctorInSystem: false,
    referredDoctorId: "",
    referralDate: moment().format("YYYY-MM-DD"),
    referralMessage: "",
  };

  const fetchDoctors = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URLS.PRODUCTION}/doctorsRegistration`
      );
      setDoctors(response.data);
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error fetching doctor list", error);
      message.error("Error fetching doctor list");
    } finally {
      setLoading(false);
    }
  };

  const saveReferral = async (values) => {
    const dataToSend = {
      ...values,
      patientId,
      doctorId,
    };

    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URLS.PRODUCTION}/saveReferral`,
        dataToSend
      );
      message.success("Referral saved successfully!");
      if (closeModal) closeModal();
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error saving referral:", error);
      message.error("Error saving referral");
    } finally {
      setLoading(false);
    }
  };

  const handleDoctorChange = (value) => {
    if (value) {
      const selectedDoctor = doctors.find((doctor) => doctor.id === value);

      if (selectedDoctor) {
        form.setFieldsValue({
          referredDoctorFName: selectedDoctor.Fname,
          referredDoctorLName: selectedDoctor.Lname,
          referredDoctorPhone: selectedDoctor.MobileNumber,
          referredDoctorSpecialization: selectedDoctor.Specialization,
        });
        setIsDisabled(false);
      }
    } else {
      form.setFieldsValue({
        referredDoctorFName: "",
        referredDoctorLName: "",
        referredDoctorPhone: "",
        referredDoctorSpecialization: "",
      });
      setIsDisabled(false);
    }
  };

  return (
    <Card
      className="antd-style-form"
      style={{ border: "0", maxWidth: 900, minWidth: 550, margin: "0 auto" }}
    >
      <Button
        className="antd-style-form-close-button"
        type="text"
        icon={<CloseOutlined className="antd-style-form-close-icon" />}
        onClick={closeModal}
      />
      <Title
        className="antd-style-form-title"
        level={3}
        style={{ textAlign: "center" }}
      >
        Referral Form
      </Title>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={saveReferral}
      >
        {/* Referral Doctor Information */}
        <Title level={4} className="antd-style-form-subtitle">
          Referred Doctor Information
        </Title>
        <Divider className="antd-style-form-divider" />
        <Row gutter={16} className="antd-style-form-row">
          <Col span={12}>
            <Form.Item
              label="First Name"
              name="referredDoctorFName"
              rules={[
                {
                  required: true,
                  message: "Please input doctor's first name!",
                },
              ]}
            >
              <Input className="antd-style-form-input" disabled={!isDisabled} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              name="referredDoctorLName"
              rules={[
                { required: true, message: "Please input doctor's last name!" },
              ]}
            >
              <Input className="antd-style-form-input" disabled={!isDisabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} className="antd-style-form-row">
          <Col span={12}>
            <Form.Item
              label="Phone Number"
              name="referredDoctorPhone"
              rules={[
                { required: true, message: "Please input doctor's phone!" },
              ]}
            >
              <Input className="antd-style-form-input" disabled={!isDisabled} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Specialization"
              name="referredDoctorSpecialization"
              rules={[
                { required: true, message: "Please input specialization!" },
              ]}
            >
              <Input className="antd-style-form-input" disabled={!isDisabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} className="antd-style-form-row">
          <Col span={24}>
            <Form.Item
              name="isReferredDoctorInSystem"
              valuePropName="checked"
              style={{ display: "flex" }}
            >
              <Checkbox onChange={(e) => setIsDisabled(!e.target.checked)}>
                Is the referred doctor in the system?
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} className="antd-style-form-row">
          <Col span={24}>
            <Form.Item
              label="Referred Doctor ID (if in system)"
              name="referredDoctorId"
              rules={[
                {
                  required: !isDisabled,
                  message: "Please provide doctor ID!",
                },
              ]}
            >
              {/* <Input className="antd-style-form-input" disabled={isDisabled} /> */}
              <Select
                placeholder="Select a doctor"
                className="antd-style-form-input"
                allowClear
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                disabled={isDisabled}
                onChange={handleDoctorChange}
              >
                {doctors.map((doctor) => (
                  <Option key={doctor.id} value={doctor.id}>
                    ID: {doctor.id} - {doctor.Fname} {doctor.Mname}
                    {doctor.Lname}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Referral Details */}
        <Title level={4} className="antd-style-form-subtitle">
          Referral Details
        </Title>
        <Divider className="antd-style-form-divider" />
        <Row gutter={16} className="antd-style-form-row">
          <Col span={12}>
            <Form.Item
              label="Referral Date"
              name="referralDate"
              rules={[
                { required: true, message: "Please select referral date!" },
              ]}
            >
              <Input type="date" className="antd-style-form-input" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Referral Message"
          name="referralMessage"
          rules={[
            { required: true, message: "Please input referral message!" },
          ]}
        >
          <Input.TextArea rows={2} className="antd-style-form-input" />
        </Form.Item>

        <Divider className="antd-style-form-divider" />

        {/* Action Buttons */}
        <Form.Item className="antd-style-form-button-row">
          <Row justify="end" gutter={16}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
                loading={loading}
                className="antd-style-form-button"
              >
                Save Referral
              </Button>
            </Col>
            <Col>
              <Button
                className="prescription-form-button-white"
                icon={<PrinterOutlined />}
                onClick={printForm}
              >
                Print
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ReferralForm;
