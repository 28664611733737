import React, { useState, useEffect } from "react";
import axios from "axios";
import { Typography, IconButton, Divider, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CommonCard from "./CommonCard";
import BASE_URLS from "./constants";

const Prescriptions = ({ patientId }) => {
  const [prescriptions, setPrescriptions] = useState([]);

  useEffect(() => {
    const fetchPrescriptions = async () => {
      try {
        const response = await axios.post(
          `${BASE_URLS.PRODUCTION}/getPrescriptionsByPatientId`,
          {
            patientId: patientId,
          }
        );
        const data = response.data;
        data.sort(
          (a, b) =>
            new Date(b.prescription_creation_time) -
            new Date(a.prescription_creation_time)
        );
        setPrescriptions(data);
      } catch (error) {
        console.error("Error fetching patient prescriptions:", error);
      }
    };
    fetchPrescriptions();
  }, [patientId]);

  return (
    <CommonCard collapsible={true} title="Prescriptions">
      <div style={{ backgroundColor: "transparent" }}>
        {prescriptions.map((prescription, index) => (
          <React.Fragment key={prescription.id}>
            <PrescriptionItem index={index} prescription={prescription} />
            {index < prescriptions.length - 1 && (
              <Divider style={{ backgroundColor: "#efefef" }} />
            )}
          </React.Fragment>
        ))}
      </div>
    </CommonCard>
  );
};

const PrescriptionItem = ({ prescription, index }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  // Prepare the entries to display, filtering out null or undefined values
  const entries = [
    {
      label: "Patient",
      value:
        `${prescription.patient_FName} ${prescription.patient_LName}`.trim(),
    },
    {
      label: "Prescribing Doctor",
      value: `${prescription.doctor_FName} ${prescription.doctor_LName}`.trim(),
    },
    {
      label: "Medicine Name",
      value: `${prescription.medicine_name} ${prescription.dose} ${prescription.dose_unit}`,
    },
    {
      label: "Quantity",
      value: `${prescription.quantity} ${prescription.quantity_unit}`,
    },
    {
      label: "Frequency",
      value: `${prescription.frequency}`,
    },
    {
      label: "Route",
      value: `${prescription.route}`,
    },
    {
      label: "Duration",
      value: `${prescription.duration}`,
    },
    {
      label: "Prescription Description",
      value: prescription.prescription_description,
    },
    {
      label: "Doctor Office Address",
      value: prescription.doctor_office_address,
    },
    {
      label: "Doctor Phone",
      value: prescription.doctor_phone,
    },
  ].filter(
    (entry) =>
      entry.value !== null && entry.value !== undefined && entry.value !== ""
  );

  return (
    <div style={{ textAlign: "left" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          padding: "8px 0",
          backgroundColor: "transparent",
        }}
        onClick={handleToggle}
      >
        <Typography variant="body1" style={{ flexGrow: 1 }}>
          {`${index + 1}. ${new Date(
            prescription.prescription_creation_time
          ).toLocaleDateString()}`}
        </Typography>
        <IconButton
          size="small"
          style={{
            transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s",
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div style={{ paddingLeft: 16, paddingBottom: 8, color: "#555" }}>
          {entries.map((entry, idx) => (
            <React.Fragment key={entry.label}>
              <div style={{ display: "flex", marginBottom: 4 }}>
                <Typography
                  variant="body2"
                  style={{ width: "170px", fontWeight: "bold" }}
                >
                  {entry.label}:
                </Typography>
                <Typography variant="body2">
                  {entry.value || "Empty"}
                </Typography>
              </div>
              {idx < entries.length - 1 && (
                <Divider
                  style={{
                    backgroundColor: "#f0f0f0",
                    height: 1,
                    width: "50%",
                    marginLeft: 0,
                    marginTop: 4,
                    marginBottom: 4,
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export default Prescriptions;
