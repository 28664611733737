// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doctor-appointment-list-page .ant-radio-button-wrapper-checked {
    /* border: 1px solid #666666 !important; */
    border: 1px solid #000 !important;
    background-color: black !important;
    /* border: 1px solid #0984e3 !important; */
    /* background-color: aliceblue !important; */
}

.doctor-appointment-list-page .ant-radio-button-wrapper-checked::before {
    background-color: #666666 !important;
    /* background-color: #0984e3 !important; */
}

.doctor-appointment-list-page .ant-radio-button-wrapper-checked span {
    /* color: #333 !important; */
    color: #FFF !important;
    /* color: #0984e3 !important; */
    /* font-weight: bold !important; */
}

.doctor-appointment-list-page .doctor-appointment-list-radio .ant-btn {
    background-color: black !important;
    color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/screens/Calendar/AppointmentList.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,iCAAiC;IACjC,kCAAkC;IAClC,0CAA0C;IAC1C,4CAA4C;AAChD;;AAEA;IACI,oCAAoC;IACpC,0CAA0C;AAC9C;;AAEA;IACI,4BAA4B;IAC5B,sBAAsB;IACtB,+BAA+B;IAC/B,kCAAkC;AACtC;;AAEA;IACI,kCAAkC;IAClC,uBAAuB;AAC3B","sourcesContent":[".doctor-appointment-list-page .ant-radio-button-wrapper-checked {\n    /* border: 1px solid #666666 !important; */\n    border: 1px solid #000 !important;\n    background-color: black !important;\n    /* border: 1px solid #0984e3 !important; */\n    /* background-color: aliceblue !important; */\n}\n\n.doctor-appointment-list-page .ant-radio-button-wrapper-checked::before {\n    background-color: #666666 !important;\n    /* background-color: #0984e3 !important; */\n}\n\n.doctor-appointment-list-page .ant-radio-button-wrapper-checked span {\n    /* color: #333 !important; */\n    color: #FFF !important;\n    /* color: #0984e3 !important; */\n    /* font-weight: bold !important; */\n}\n\n.doctor-appointment-list-page .doctor-appointment-list-radio .ant-btn {\n    background-color: black !important;\n    color: white !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
