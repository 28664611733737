// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e-pharmacy-box__products {
    display: grid;
    /* grid-template-columns: repeat(4,auto); */
    gap: 1rem;
}

.e-pharmacy-section__products {
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 1rem;

}

.e-pharmacy-box__product {
    background-color: white;
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    padding: 20px;

}

/* ------------------section media--------- */
@media screen and (min-width:992px) {
    .e-pharmacy-box__products {

        grid-template-columns: repeat(4, auto);

    }


}

@media screen and (max-width:992px) {}

@media screen and (max-width:768px) {
    .e-pharmacy-section__products {
        grid-template-columns: repeat(2, 1fr) !important;

    }

}

@media screen and (max-width:560px) {
    .e-pharmacy-section__products {
        grid-template-columns: repeat(1, 1fr) !important;

    }

}`, "",{"version":3,"sources":["webpack://./src/screens/E-Pharmacy/components/product/product.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2CAA2C;IAC3C,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sCAAsC;IACtC,SAAS;;AAEb;;AAEA;IACI,uBAAuB;IACvB,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;;AAEjB;;AAEA,6CAA6C;AAC7C;IACI;;QAEI,sCAAsC;;IAE1C;;;AAGJ;;AAEA,qCAAqC;;AAErC;IACI;QACI,gDAAgD;;IAEpD;;AAEJ;;AAEA;IACI;QACI,gDAAgD;;IAEpD;;AAEJ","sourcesContent":[".e-pharmacy-box__products {\n    display: grid;\n    /* grid-template-columns: repeat(4,auto); */\n    gap: 1rem;\n}\n\n.e-pharmacy-section__products {\n    display: grid;\n    grid-template-columns: repeat(3, auto);\n    gap: 1rem;\n\n}\n\n.e-pharmacy-box__product {\n    background-color: white;\n    border: 1px solid #e6e6e6;\n    border-radius: 12px;\n    padding: 20px;\n\n}\n\n/* ------------------section media--------- */\n@media screen and (min-width:992px) {\n    .e-pharmacy-box__products {\n\n        grid-template-columns: repeat(4, auto);\n\n    }\n\n\n}\n\n@media screen and (max-width:992px) {}\n\n@media screen and (max-width:768px) {\n    .e-pharmacy-section__products {\n        grid-template-columns: repeat(2, 1fr) !important;\n\n    }\n\n}\n\n@media screen and (max-width:560px) {\n    .e-pharmacy-section__products {\n        grid-template-columns: repeat(1, 1fr) !important;\n\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
