import React from "react";
import "./order.css";
import { toast, Toaster } from "react-hot-toast";
import { order } from "../../assets/data/data";

function Order() {
  return (
    <div className="e-pharmacy-section__order">
      <div className="e-pharmacy-box__items ">
        {order.map((item) => {
          return (
            <div key={item.id} className="e-pharmacy-box">
              <div
                className="e-pharmacy-box__content"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="e-pharmacy-number">
                  <h2 className={"e-pharmacy-h" + item.id}>{item.id}</h2>
                </div>
                <div className="e-pharmacy-text">
                  <div
                    className="e-pharmacy-title "
                    style={{ textAlign: "left" }}
                  >
                    {item.title}
                  </div>
                  <div
                    className="e-pharmacy-sub__title "
                    style={{ textAlign: "left" }}
                  >
                    {item.desc}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Order;
