// ResultPage.jsx
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SkinCancerResult = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { result, info } = location.state || {};

  // If no result data is present, redirect back to upload page
  if (!result || !info) {
    navigate("/");
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: "black",
        color: "white",
        textAlign: "center",
        minHeight: "100vh",
        paddingTop: "100px",
      }}
    >
      <br />
      <br />
      <h2>Diagnosed with {result}</h2>
      <br />
      <h4>{info}</h4>
      <br />
      <br />
      <h5 style={{ color: "red" }}>
        DISCLAIMER! Please consult a dermatologist immediately to seek treatment
      </h5>
      <br />
    </div>
  );
};

export default SkinCancerResult;
