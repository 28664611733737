import moment from "moment";
import dayjs from "dayjs";
import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, List, Space, Spin, DatePicker, Radio, Tooltip } from "antd";
import { doctorGetCalendar } from "../../api/calendar";
import { readLoginData } from "../../loginData";
import PageLayout from "../../components/PageLayout/PageLayout";
import TimeSegmentDialog from "../DoctorNew/TimeSegmentDialog";
import "./AppointmentList.css";

import "react-big-calendar/lib/css/react-big-calendar.css";

const dateFormat = "YYYY-MM-DD HH:mm:ss";

const { RangePicker } = DatePicker;

const getTimeSegmentState = (status) => {
  if (status < 0) {
    return "Approved";
  } else if (status > 0) {
    return "Pending Approval";
  } else {
    return "Not Booked";
  }
};

const getTimeSegmentCategory = (category) => {
  switch (category) {
    case 1:
      return "Task";
    case 2:
      return "Available Slot";
    case 3:
      return "Surgery";
    case 4:
      return "General Consultation";
    case 5:
      return "Lab Test";
    default:
      return "Unknown";
  }
};

const getTimeSegmentColor = (event) => {
  const { category, status, appointmentStatus } = event;
  if (
    appointmentStatus === undefined ||
    appointmentStatus === 1 ||
    (appointmentStatus === null && status === 0)
  ) {
    if (category === 1) {
      return "#EB00FF";
    } else if (category === 3) {
      return "#D9D9D9";
    } else if (category === 4) {
      return "#FFA900";
    } else if (category === 5) {
      return "#3377DD";
    } else {
      return "#40DC82";
    }
  } else if (appointmentStatus === 0) {
    return "#ADEDCD";
  } else if (appointmentStatus === null) {
    return "#DD5233";
  }
};

const TimeSegmentsView = (props) => {
  console.log("TimeSegmentsView", props);
  const navigate = useNavigate();

  const [status, setStatus] = useState("All");
  const [detailId, setDetailId] = useState(null);

  const eventStatusFilter = ((status) => {
    switch (status) {
      default:
      case "All":
        return (e) => true;
      case "Approved":
        return (e) => e.status < 0;
      case "PendingApproval":
        return (e) => e.status > 0;
      case "NotBooked":
        return (e) => e.status === 0;
    }
  })(status);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleRangeChange = (dates) => {
    if (dates) {
      props.onRangeChange(dates[0].toDate(), dates[1].toDate());
    } else {
      props.onRangeChange(moment().startOf("week"), moment().endOf("week"));
    }
  };

  const eventsList = props.data
    .filter((e) => e.category !== 1 && eventStatusFilter(e))
    .sort((a, b) => moment(a.start).isAfter(moment(b.start)))
    .map((e) => ({
      ...e,
      start: moment(e.start).toDate(),
      end: moment(e.end).toDate(),
      color: getTimeSegmentColor(e),
    }));

  return (
    <>
      <Space
        direction="vertical"
        style={{ marginBottom: "20px" }}
        size="middle"
        className="doctor-appointment-list-radio"
      >
        <Space
          direction="horizontal"
          style={{ justifyContent: "flex-end", marginRight: "20px" }}
        >
          <RangePicker
            value={[dayjs(props.range[0]), dayjs(props.range[1])]}
            onChange={handleRangeChange}
          />
          <Radio.Group value={status} onChange={handleStatusChange}>
            <Radio.Button value="All">All</Radio.Button>
            <Radio.Button value="Approved">Approved</Radio.Button>
            <Radio.Button value="PendingApproval">
              Pending Approval
            </Radio.Button>
            <Radio.Button value="NotBooked">Not Booked</Radio.Button>
          </Radio.Group>
          <Button type="primary" onClick={() => navigate("/doctor/calendar")}>
            Edit Appointments
          </Button>
        </Space>
      </Space>
      <List
        grid={{ gutter: 16, column: 1 }}
        dataSource={eventsList}
        renderItem={(item) => {
          const durationMinutes = moment(item.end).diff(
            moment(item.start),
            "minutes"
          );
          const maxDuration = 240;
          const stripeWidth = Math.min(Math.max(durationMinutes, 30), 200);
          return (
            <List.Item>
              <div
                style={{
                  // border: "1px solid #d6d6d6",
                  borderRadius: "14px",
                  padding: "16px",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#F4F4F4",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <Tooltip title={getTimeSegmentState(item.status)}>
                    <span
                      style={{
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: item.color,
                        marginRight: "8px",
                      }}
                    ></span>
                  </Tooltip>
                  <Button
                    type="link"
                    style={{
                      color: "#000",
                      fontWeight: "bold",
                      padding: "0",
                      fontSize: "16px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "100%",
                    }}
                    onClick={() => setDetailId(item.id)}
                  >
                    <span style={{ width: "180px", flexShrink: 0 }}>
                      {moment(item.start).format(dateFormat)}{" "}
                      {/* {item.patient.id
                        ? ` Patient: ${item.patient.name}`
                        : null} */}
                    </span>
                    <span
                      style={{
                        marginRight: "4px",
                        fontSize: "14px",
                        color: "#555",
                        width: "80px",
                      }}
                    >
                      {moment
                        .duration(moment(item.end).diff(item.start))
                        .humanize()}
                    </span>
                    <div
                      style={{
                        width: `${stripeWidth}px`,
                        height: "4px",
                        backgroundColor: "#0984e3",
                        // backgroundColor: "#74b9ff",
                        borderRadius: "2px",
                        marginRight: "16px",
                      }}
                    ></div>
                    <span style={{ width: "180px", flexShrink: 0 }}>
                      {item.patient.id
                        ? ` Patient: ${item.patient.name}`
                        : null}
                    </span>
                  </Button>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "90px",
                      fontSize: "14px",
                      color: "#666666",
                      marginBottom: "4px",
                    }}
                  >
                    Status:&nbsp;
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "#555",
                      fontWeight: "500",
                    }}
                  >
                    {getTimeSegmentState(item.status)}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "90px",
                      fontSize: "14px",
                      color: "#666666",
                      marginBottom: "4px",
                    }}
                  >
                    Category:&nbsp;
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "#555",
                      fontWeight: "500",
                    }}
                  >
                    {getTimeSegmentCategory(item.category)}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "90px",
                      fontSize: "14px",
                      color: "#666666",
                    }}
                  >
                    Description:&nbsp;
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      color: item.description ? "#555" : "#CCCCCC",
                      fontWeight: item.description ? "500" : "normal",
                    }}
                  >
                    {item.description || "No description"}
                  </div>
                </div>
              </div>
            </List.Item>
          );
        }}
      />
      {detailId ? (
        <TimeSegmentDialog
          id={detailId}
          onClose={() => setDetailId(null)}
          onApprove={props.onNeedLoad}
        />
      ) : null}
    </>
  );
};

const DoctorAppointmentList = (props) => {
  const navigate = useNavigate();

  const loginData = readLoginData();
  const [needLoad, setNeedLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentStart, setCurrentStart] = useState(moment().startOf("week"));
  const [currentEnd, setCurrentEnd] = useState(moment().endOf("week"));

  const setCurrentRange = (start, end) => {
    setCurrentStart(start);
    setCurrentEnd(end);
    setNeedLoad(true);
  };

  const fetchData = async () => {
    setLoading(true);
    setData([]);
    try {
      const response = await doctorGetCalendar(
        loginData,
        currentStart.toDate(),
        currentEnd.toDate()
      );
      setData(response);
    } catch (error) {
      console.error("Failed to fetch calendar data:", error);
    }
    setLoading(false);
  };

  const handleRangeChange = useCallback((start, end) => {
    setCurrentRange(moment(start), moment(end));
  }, []);

  if (needLoad) {
    setNeedLoad(false);
    fetchData();
  }

  const handleSelect = (event) => {
    if (event.type === 1) {
      navigate(`/Tasks/${event.id}`);
    } else if (event.type === 2) {
      navigate(`/calendar/timesegment/${event.id}`);
    }
  };

  return (
    <>
      <Spin spinning={loading}>
        <TimeSegmentsView
          range={[currentStart, currentEnd]}
          onRangeChange={handleRangeChange}
          data={data}
          onSelect={handleSelect}
          onNeedLoad={() => setNeedLoad(true)}
        />
      </Spin>
    </>
  );
};

export const DoctorAppointmentListPage = (props) => {
  return (
    <>
      <PageLayout
        clearUser={props.clearUser}
        className="doctor-appointment-list-page"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            height: "100%",
            backgroundColor: "#FEFEFE",
            padding: "2rem",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: "100%",
            }}
          >
            <DoctorAppointmentList {...props} />
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default DoctorAppointmentListPage;
