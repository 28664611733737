import React, { useState } from "react";
import "../styles/screens/CervicalCancerPrediction.css";
import { useLocation } from "react-router-dom";

function CervicalCancerPrediction() {
  const location = useLocation();

  const [age, setAge] = useState(location.state?.Age ?? null);
  const patientName = `${location.state.FName} ${location.state.LName}`;

  const [smokingStatus, setSmokingStatus] = useState("Non-smoker");
  const [selectedFile, setSelectedFile] = useState(null);
  const [predictionResult, setPredictionResult] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [preprocessedImage, setPreprocessedImage] = useState(null);
  const [model, setModel] = useState("DenseNet169");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    if (file) {
      setOriginalImage(URL.createObjectURL(file));
    }
  };

  const handlePreprocess = async () => {
    if (!selectedFile) {
      alert("Please upload an image first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await fetch("http://127.0.0.1:5000/preprocess", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setOriginalImage(data.original_image); // Backend URL for the original image
        setPreprocessedImage(data.preprocessed_image); // Backend URL for the preprocessed image
      } else {
        const errorData = await response.json();
        console.error("Error response:", errorData);
        alert(`Error: ${errorData.error}`);
      }
    } catch (error) {
      console.error("Error during preprocessing:", error);
      alert("Error during preprocessing. Please try again.");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      alert("Please upload an image first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("age", age);
    formData.append("smoking_status", smokingStatus);
    formData.append("model", model);

    try {
      const response = await fetch("http://127.0.0.1:5000/predict", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setPredictionResult(data);
      } else {
        const errorData = await response.json();
        console.error("Error response:", errorData);
        alert(`Error: ${errorData.error}`);
      }
    } catch (error) {
      console.error("Network error:", error);
      alert("An error occurred while connecting to the server.");
    }
  };

  return (
    <div className="cervical-upload-form">
      <h1 className="cervical-title">Enter Your Health Information</h1>

      <div className="cervical-form-group">
        <label>Name</label>
        <input type="text" value={patientName || ""} disabled />
      </div>

      <div className="cervical-form-group">
        <label>Age</label>
        <input
          type="number"
          value={age || ""}
          onChange={(e) => setAge(Number(e.target.value))}
          placeholder="Enter your age"
        />
      </div>

      <div className="cervical-form-group">
        <label>Smoking Status</label>
        <select
          value={smokingStatus}
          onChange={(e) => setSmokingStatus(e.target.value)}
        >
          <option value="Non-smoker">Non-smoker</option>
          <option value="Smoker">Smoker</option>
        </select>
      </div>

      <div className="cervical-form-group">
        <label>Upload Cell Image</label>
        <input type="file" onChange={handleFileChange} accept="image/*" />
      </div>

      <div className="cervical-form-group">
        <label>Model</label>
        <select value={model} onChange={(e) => setModel(e.target.value)}>
          <option value="DenseNet169">DenseNet169</option>
          <option value="ResNet101">ResNet101</option>
          <option value="XceptionNet">XceptionNet</option>
        </select>
      </div>

      <div className="cervical-buttons">
        <button className="cervical-btn" onClick={handlePreprocess}>
          Preprocess Image
        </button>
        <button className="cervical-btn" onClick={handleSubmit}>
          Submit
        </button>
      </div>

      <div className="cervical-image-preview">
        {originalImage && preprocessedImage && (
          <div className="image-container">
            <div className="image-box">
              <h3>Original Image</h3>
              <img src={originalImage} alt="Original" />
            </div>
            <div className="image-box">
              <h3>Preprocessed Image</h3>
              <img src={preprocessedImage} alt="Preprocessed" />
            </div>
          </div>
        )}
      </div>

      {predictionResult && (
        <div className="cervical-result-section">
          <h2>Prediction Results</h2>
          <p>
            <strong>Class Name:</strong> {predictionResult.class_name}
          </p>
          <p>
            <strong>Cancerous:</strong>{" "}
            {predictionResult.is_cancerous ? "Yes" : "No"}
          </p>
          <p>
            <strong>Risk:</strong> {predictionResult.risk}
          </p>
          <p>
            <strong>Recommendation:</strong> {predictionResult.recommendation}
          </p>
        </div>
      )}
    </div>
  );
}

export default CervicalCancerPrediction;
