import React, { useEffect, useState } from "react";
import { getPatientDoctor } from '../../api/user';
import { readLoginData } from '../../loginData';
import MUIDataTable from "mui-datatables";
import { Chip, Button, Menu, MenuItem, IconButton } from '@mui/material';
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

const DoctorPage = () => {
  const patientInfo = readLoginData();
  const [PatientDoctor, setPatientDoctor] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null); // 控制 Menu 打开/关闭
  const [selectedDoctor, setSelectedDoctor] = useState(null); // 存储选中的医生信息

  useEffect(() => {
    const fetchPatientDoctor = async () => {
      try {
        const doctorInfo = await getPatientDoctor(patientInfo.id);
        // 添加 "Name" 和 "Location" 字段到每个医生对象
        const doctorDataWithNames = doctorInfo.map(doctor => ({
          ...doctor,
          Name: `${doctor.Fname} ${doctor.Mname ? doctor.Mname + ' ' : ''}${doctor.Lname}`,
          Location: `${doctor.City}, ${doctor.Province}, ${doctor.Country}`,
        }));
        setPatientDoctor(doctorDataWithNames);
      } catch (error) {
        console.error('Error fetching doctors for the patient:', error);
      }
    };
    fetchPatientDoctor();
  }, [patientInfo]);

  // 打开选项菜单
  const handleOpenMenu = (event, doctor) => {
    setAnchorEl(event.currentTarget);
    setSelectedDoctor(doctor);
  };

  // 关闭选项菜单
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedDoctor(null);
  };

  // 操作按钮点击事件（暂时只用 alert 提示）
  const handleMenuItemClick = (action) => {
    alert(`You clicked the ${action} button.`);
    handleCloseMenu(); // 操作完成后关闭菜单
  };

  const columns = [
    {
      name: "Name",
      label: "Name",
      options: {
        filter: false,
        sort: false,}
    },
    {
      name: "MobileNumber",
      label: "Mobile Number",
      options: {
        filter: false,
        sort: false,}
    },
    {
      name: "Location",
      label: "Location",
    },
    {
      name: "Specialization",
      label: "Specialization",
    },
    {
      name: "PractincingHospital",
      label: "Practicing Hospital",
    },
    {
      name: "Gender",
      label: "Gender",
    },
    {
      name: "Availability",
      label: "Availability",
      options: {
        customBodyRender: (value) => {
          if (value === 1) {
            return <Chip label="Available" style={{ backgroundColor: '#d0f5dd', color: '#237a3b' }} />;
          } else {
            return <Chip label="Not Available" style={{ backgroundColor: '#fde2e1', color: '#a61d24' }} />;
          }
        },
        filterOptions: {
          names: ["Available", "Not Available"],
          logic: (value, filters) => {
            if (filters.indexOf("Available") >= 0 && value === 1) {
              return false; // 显示可用医生
            }
            if (filters.indexOf("Not Available") >= 0 && value === 0) {
              return false; // 显示不可用医生
            }
            return true; // 隐藏不匹配的行
          },
        },
      },
    },
    {
      name: "options",
      label: "Options",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <IconButton
              sx={{ color: "#A3AED0" }}
              onClick={(event) => handleOpenMenu(event, PatientDoctor[dataIndex])} // 打开选项菜单
            >
              <MoreHorizOutlinedIcon />
            </IconButton>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: 'none',
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20],
    print: false,
    download: false,
    viewColumns: false,
    filter: true,
    filterType: 'checkbox',
  };

  return (
    <>
      <MUIDataTable
        title={"Doctors"}
        data={PatientDoctor}
        columns={columns}
        options={options}
      />

      {/* 操作菜单 */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => handleMenuItemClick('Message')}>Message</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('Chatbot')}>Chatbot</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('Video Call')}>Video Call</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('Book Appointment')}>Book Appointment</MenuItem>
      </Menu>
    </>
  );
};

export default DoctorPage;
