import React from 'react';
import { addToCart, decreaseFromCart, removeFromCart } from '../../redux-store/CartSlice';
import { useDispatch, useSelector } from 'react-redux';

function CartItems({ closeCard }) {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  return (
    <>
      <div className="cartlist">
        {cart.length === 0 ? (
          <div className="text-danger text-center mt-5">
            <h2>Cart is empty 😊!</h2>
            <div>
              <button type="button" className="btn btn-dark mt-4" onClick={closeCard}>
                🚀 Back to home
              </button>
            </div>
          </div>
        ) : (
          cart.map((product) => {
            return (
              <div
                className="cartlist__item d-flex align-items-center gap-3 mb-3"
                key={product.id}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                  marginBottom: '1rem',
                  width: '100%',
                  borderBottom: '1px solid #ddd', // Border between items
                  paddingBottom: '1rem',
                }}
              >
                {/* Product Image */}
                <div className="img">
                  <img src={product.img} alt="" style={{ width: '106px' }} />
                </div>

                {/* Product Details */}
                <div className="details" style={{ flex: 1, textAlign: 'left' }}>
                  {/* Unit Price */}
                  <div
                    className="price"
                    style={{
                      fontWeight: 'bold',
                      fontSize: '16px',
                      marginBottom: '0.5rem',
                    }}
                  >
                    Unit Price: ${product.price}
                  </div>

                  {/* Quantity Controls (+ and - buttons) */}
                  <div className="add__minus d-flex align-items-center gap-2">
                    <button
                      style={{
                        fontSize: '10px', // Reduced button size
                        padding: '5px 12px',
                        backgroundColor: '#007bff',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s ease',
                      }}
                      onClick={() => dispatch(addToCart(product))}
                    >
                      +
                    </button>

                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                      {product.quantity}
                    </span>

                    <button
                      style={{
                        fontSize: '10px', // Reduced button size
                        padding: '5px 12px',
                        backgroundColor: '#f44336',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s ease',
                      }}
                      onClick={() => {
                        if (product.quantity > 1) {
                          dispatch(decreaseFromCart(product));
                        } else {
                          dispatch(removeFromCart(product));
                        }
                      }}
                    >
                      -
                    </button>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </>
  );
}

export default CartItems;
