import React, { useEffect, useState } from "react";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./PatientPage.css";
import "./ReferralHistory.jsx";
import { Widget, addResponseMessage } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import { Button } from "@mui/material";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import ViewModal from "./patient-details/ViewModal.jsx";
import PatientInfoTable from "./PatientInfoTable/PatientInfoTable.jsx";

const PatientPage = ({ clearUser }) => {
  const doctorID = useOutletContext();
  const [viewModal, setViewModal] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState({});
  const [data, setData] = useState([]);
  const [lastTimeoutId, setLastTimeoutId] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.post(
          "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/api/example/test"
        );
        console.log(response);
        setData(response.data.result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, []);

  useEffect(() => {
    const handlePopState = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const patientIdFromUrl = urlParams.get("patientId");

      if (patientIdFromUrl) {
        setViewModal(true);
        setSelectedPatientId(patientIdFromUrl);
      } else {
        setViewModal(false);
        setSelectedPatientId(null);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const typoFontFamily = {
    fontFamily: "Poppins, Roboto ,Helvetica,Arial, sans-serif !important",
  };

  const getMuiTheme = () =>
    createTheme({
      typography: {
        ...typoFontFamily,
        color: "black",
      },
      palette: {
        background: {
          paper: "#ffff",
        },
      },
      components: {
        MuiTableCell: {
          styleOverrides: {
            head: {
              // padding: "10px 4px",
              borderLeft: "none", // Removes the left border from the header cell
              borderRight: "none",
            },
            body: {
              // padding: "7px 15px",
              borderLeft: "none", // Removes the left border from the body cell
              borderRight: "none",
              borderBottom: "none",
              borderTop: "none",
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              "&:nth-of-type(odd)": {
                backgroundColor: "#fff", // setting odd rows to white
              },
              "&:nth-of-type(even)": {
                backgroundColor: "#fff", // setting even rows to white
              },
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              borderRadius: "20px",
              overflow: "hidden",
              // marginTop: "18px",
            },
          },
        },
      },
    });

  const callDeleteDoctorConversationAPI = async () => {
    try {
      // TODO: Replace 58 with doctorId
      const url = `https://chatbot-2024-90539106da8b.herokuapp.com/delete_doctor_app_conversation/58`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(), // Assuming requestData is defined globally
      });
      if (!response.ok) {
        console.error("Error:", response.status);
      }
    } catch (error) {
      console.error("Error calling delete_doctor_app_conversation API:", error);
    }
  };

  const handleNewUserMessage = async (newMessage) => {
    clearTimeout(lastTimeoutId);
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const urlParams = new URLSearchParams(window.location.search);
    const patientIdFromUrl = urlParams.get("patientId");

    try {
      const requestData = {
        userObject: {
          userInput: {
            message: newMessage,
            timezone: userTimezone,
          },
        },
      };
      // TODO: replace 58 with docID
      const url = `https://chatbot-2024-90539106da8b.herokuapp.com/personal_doctor/58/${patientIdFromUrl}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      if (response.ok) {
        const responseData = await response.json();
        addResponseMessage(responseData);
        const timeoutId = setTimeout(() => {
          console.log(
            "Chat inactive for 5 minutes. Closing chat or showing message..."
          );
          callDeleteDoctorConversationAPI(); // Call the common function here
          addResponseMessage("Chat reset due to inactivity");
        }, 5 * 60 * 1000); // 5 minutes in milliseconds
        setLastTimeoutId(timeoutId);
      } else {
        console.error("Error:", response.status);
      }
    } catch (error) {
      console.error("Error fetching response from API:", error);
      addResponseMessage(
        "Sorry, something went wrong. Please try again later."
      );
    }
  };

  window.addEventListener("beforeunload", function (event) {
    callDeleteDoctorConversationAPI(); // Call the common function here
    event.preventDefault();
  });

  return (
    <div className="py-10  grid place-items-center">
      <div className="w-full max-w-7xl">
        <PatientInfoTable
          data={data}
          patientId={selectedPatientId}
          clearUser={clearUser}
        />
      </div>
      <Widget handleNewUserMessage={handleNewUserMessage} />
    </div>
  );
};

export default PatientPage;
