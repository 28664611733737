import { useEffect, useState, forwardRef } from 'react';
import { Container, Box, Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import MailSidebar from './MailSidebar';
import MailDetails from './MailDetails';
import MailList from './MailList';
import { getMessagesByTypeAndId } from '../../api/user';
import { readLoginData } from '../../loginData';

const Page = forwardRef(({ children, title = '', meta, ...other }, ref) => (
  <>
    <Helmet>
      <title>{`${title} | Minimal-UI`}</title>
      {meta}
    </Helmet>
    <Box ref={ref} {...other}>
      {children}
    </Box>
  </>
));

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

const Messages = () => {
  const userInfo = readLoginData();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openCompose, setOpenCompose] = useState(false);
  const [labels, setLabels] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedConversation, setSelectedConversation] = useState(null);

  const fetchMessages = async () => {
    try {
      const conversations = await getMessagesByTypeAndId({
        user_id: userInfo.id,
        user_type: userInfo.type,
      });
      const categorizedLabels = {};

      // 动态生成不同类型的分类
      Object.entries(conversations).forEach(([type, convs]) => {
        categorizedLabels[type] = convs;
      });

      setLabels(categorizedLabels);

      // 更新 selectedConversation，保持在新消息中
      if (selectedConversation) {
        const updatedConversation = categorizedLabels[selectedCategory]?.find(
          (conv) => conv.conversation_id === selectedConversation.conversation_id
        );
        if (updatedConversation) {
          setSelectedConversation(updatedConversation);
        }
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [userInfo.id]);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setSelectedConversation(null);
    fetchMessages(); // 选择类别时刷新消息
  };

  const handleConversationSelect = (conversation) => {
    setSelectedConversation(conversation);
  };

  const handleMessageSent = () => {
    fetchMessages(); // 发送消息后刷新
  };

  return (
    <Container maxWidth="xl" sx={{ display: 'flex', justifyContent: 'center', padding: 3 }}>
      <Paper
        elevation={4}
        sx={{
          display: 'flex',
          width: '100%',
          height: '75vh',
          backgroundColor: '#f5f5f5',
          padding: 2,
          borderRadius: 2,
        }}
      >
        <Box sx={{ width: '20%', paddingRight: 2 }}>
          <MailSidebar
            isOpenSidebar={openSidebar}
            onCloseSidebar={() => setOpenSidebar(false)}
            onOpenCompose={() => setOpenCompose(true)}
            labels={labels}
            onSelectCategory={handleCategorySelect}
            selectedCategory={selectedCategory}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            width: '80%',
            gap: 2,
          }}
        >
          {/* Message list card */}
          <Paper
            elevation={3}
            sx={{
              width: '40%',
              overflowY: 'auto',
              padding: 2,
              backgroundColor: '#ffffff',
              borderRadius: 2,
            }}
          >
            <MailList
              category={selectedCategory}
              messages={labels[selectedCategory] || []}
              onMailSelect={handleConversationSelect}
              onOpenSidebar={() => setOpenSidebar(true)}
              selectedConversation={selectedConversation}
            />
          </Paper>

          {/* Message details card */}
          <Paper
            elevation={3}
            sx={{
              width: '60%',
              overflowY: 'auto',
              padding: 2,
              backgroundColor: '#ffffff',
              borderRadius: 2,
            }}
          >
            {selectedConversation ? (
              <MailDetails
                conversation={selectedConversation}
                onMessageSent={handleMessageSent}
              />
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography variant="h6" color="textSecondary">
                  Select a conversation to view details
                </Typography>
              </Box>
            )}
          </Paper>
        </Box>
      </Paper>
    </Container>
  );
};

export default Messages;
