import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  Row,
  Col,
  Divider,
  message,
  Select,
  DatePicker,
  List,
  Avatar,
  Modal,
} from "antd";
import {
  CloseOutlined,
  CopyOutlined,
  MedicineBoxOutlined,
} from "@ant-design/icons";
import moment from "moment";
import BASE_URLS from "../constants";

const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;

function AITreatmentPopup({
  open,
  closeModal,
  patientId,
  doctorId,
  patientData,
}) {
  console.log("Patient Data:", patientData);
  const [diagnosisDetail, setDiagnosisDetail] = useState({
    diagnosis: "",
  });
  const [intelligentTreatment, setIntelligentTreatment] = useState("");
  const [diagnosisHistory, setDiagnosisHistory] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [showAddTreatmentModal, setShowAddTreatmentModal] = useState(false);
  const [treatmentDetails, setTreatmentDetails] = useState({
    treatment: "",
    date: "",
    diseaseType: "",
    diseaseId: "",
  });

  useEffect(() => {
    if (open) {
      setDiagnosisDetail({ diagnosis: "" });
      setIntelligentTreatment("");
      fetchDiagnosisHistory();
      fetchTreatments();
    }
  }, [open]);

  const formatDateForDisplay = (isoDateString) => {
    const [datePart] = isoDateString.split("T");
    const [year, month, day] = datePart.split("-").map(Number);
    const date = new Date(year, month - 1, day);
    return date.toLocaleDateString();
  };

  const fetchDiagnosisHistory = async () => {
    const diagnosisData = [
      { specialty: "Migraine", date: "2023/3/11" },
      { specialty: "Coronary Artery Disease", date: "2023/2/11" },
      { specialty: "Skin Cancer", date: "2023/3/15" },
      { specialty: "Thyroid", date: "2023/4/20" },
    ];
    // Replace with actual API call when available
    setDiagnosisHistory(diagnosisData);
  };

  const getIntelligentTreatment = async () => {
    if (diagnosisDetail.diagnosis !== "") {
      try {
        const response = await axios.post(
          `https://chatbot-2024-90539106da8b.herokuapp.com/intelligent-clinical-decision-support/${patientId}/${diagnosisDetail.diagnosis}`
        );
        setIntelligentTreatment(response.data);
      } catch (error) {
        console.error("Error getting intelligent treatment:", error);
        message.error("Failed to get AI treatment plan.");
      }
    } else {
      message.warning("Please enter a diagnosis.");
    }
  };

  const fetchTreatments = async () => {
    try {
      console.log("Fetching treatments for patient:", patientId);
      const response = await axios.post(
        `${BASE_URLS.PRODUCTION}/patientOverview`,
        { patientId }
      );
      console.log("Treatments Response:", response);
      if (response.data && response.data.treatments) {
        setTreatments(response.data.treatments);
      }
    } catch (error) {
      console.error("Error fetching treatments:", error);
    }
  };

  const handleDiagnosisInputChange = (e) => {
    setDiagnosisDetail({ ...diagnosisDetail, diagnosis: e.target.value });
    setIntelligentTreatment("");
  };

  const handleDiagnosisHistoryChange = (value) => {
    setDiagnosisDetail({ ...diagnosisDetail, diagnosis: value });
    setIntelligentTreatment("");
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    message.success("Copied to clipboard");
  };

  const handleTreatmentChange = (field, value) => {
    setTreatmentDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const saveTreatment = async () => {
    if (treatmentDetails.treatment !== "") {
      try {
        await axios.post(
          "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/saveTreatment/",
          {
            ...treatmentDetails,
            patientId,
            doctorId,
          }
        );
        fetchTreatments();
        setShowAddTreatmentModal(false);
        message.success("Treatment saved successfully.");
      } catch (error) {
        console.error("Error saving treatment:", error);
        message.error("Failed to save treatment.");
      }
    } else {
      message.warning("Please enter the treatment details.");
    }
  };

  return (
    <Card
      className="antd-style-form"
      style={{ border: "0", maxWidth: 900, minWidth: 550, margin: "0 0" }}
    >
      <Button
        className="antd-style-form-close-button"
        type="text"
        icon={<CloseOutlined className="antd-style-form-close-icon" />}
        onClick={closeModal}
      />
      <Title
        className="antd-style-form-title"
        level={3}
        style={{ textAlign: "center" }}
      >
        AI Treatment Plan
      </Title>
      <Form layout="vertical">
        {/* Patient Name */}
        {/* <Title level={4} className="antd-style-form-subtitle">
          {patientData.FName} {patientData.LName}
        </Title> */}
        <Title level={4} className="antd-style-form-subtitle">
          Diagnosis
        </Title>
        <Divider className="antd-style-form-divider" />
        <Form.Item
          label="Diagnosis description"
          className="antd-style-form-item"
        >
          <TextArea
            name="diagnosis"
            value={diagnosisDetail.diagnosis}
            onChange={handleDiagnosisInputChange}
            rows={3}
            className="antd-style-form-input"
          />
        </Form.Item>
        <Form.Item label="Diagnosis History" className="antd-style-form-item">
          <Select
            value={diagnosisDetail.diagnosis || undefined}
            onChange={handleDiagnosisHistoryChange}
            placeholder="Select from history"
            className="antd-style-form-input"
            allowClear
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          >
            {diagnosisHistory.map((history, index) => (
              <Option key={index} value={history.specialty}>
                {history.specialty} - {history.date}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Title level={4} className="antd-style-form-subtitle">
          Treatment Plan List
        </Title>
        <Divider className="antd-style-form-divider" />
        <div
          style={{
            padding: 16,
            paddingTop: 0,
            maxHeight: 150,
            overflowY: "auto",
            marginBottom: 16,
          }}
        >
          {treatments.length > 0 ? (
            <List
              itemLayout="horizontal"
              dataSource={treatments}
              renderItem={(treatment) => (
                <List.Item style={{ textAlign: "left" }}>
                  <List.Item.Meta
                    avatar={<Avatar icon={<MedicineBoxOutlined />} />}
                    title={`${formatDateForDisplay(treatment.RecordDate)}`}
                    description={
                      <div style={{ whiteSpace: "pre-line" }}>
                        {treatment.treatment}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          ) : (
            <Typography.Text>No Treatments Found...</Typography.Text>
          )}
        </div>
        {/* AI Treatment Plan Section */}
        <Title level={4} className="antd-style-form-subtitle">
          AI Treatment Plan
        </Title>
        <Divider className="antd-style-form-divider" />
        <Form.Item className="antd-style-form-item">
          {intelligentTreatment ? (
            <ul style={{ listStyleType: "disc", marginLeft: 24 }}>
              {intelligentTreatment.split("\n").map((line, index) => (
                <li key={index}>
                  <Typography.Text>{line}</Typography.Text>
                </li>
              ))}
            </ul>
          ) : (
            <Typography.Text>
              No intelligent treatment plan available
            </Typography.Text>
          )}
          {intelligentTreatment && (
            <Button
              icon={<CopyOutlined />}
              onClick={() => copyToClipboard(intelligentTreatment)}
              className="antd-style-form-button"
              style={{ marginTop: 16 }}
            >
              Copy Treatment Plan
            </Button>
          )}
        </Form.Item>
        <Divider className="antd-style-form-divider" />
        {/* Action Buttons */}
        <Form.Item className="antd-style-form-button-row">
          <Row justify="end" gutter={16}>
            {intelligentTreatment ? (
              <Button
                type="primary"
                onClick={() => setShowAddTreatmentModal(true)}
                className="antd-style-form-button"
              >
                Add Treatment Plan
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={getIntelligentTreatment}
                className="antd-style-form-button"
              >
                Get AI Treatment Plan
              </Button>
            )}
          </Row>
        </Form.Item>
      </Form>
      {/* Add Treatment Modal */}
      <Modal
        visible={showAddTreatmentModal}
        onCancel={() => setShowAddTreatmentModal(false)}
        title="Add Treatment Plan"
        footer={null}
        className="antd-style-form"
      >
        <Form layout="vertical">
          <Form.Item label="Treatment" className="antd-style-form-item">
            <TextArea
              name="treatment"
              value={treatmentDetails.treatment}
              onChange={(e) =>
                handleTreatmentChange("treatment", e.target.value)
              }
              rows={3}
              className="antd-style-form-input"
            />
          </Form.Item>
          <Form.Item label="Date of Treatment" className="antd-style-form-item">
            <DatePicker
              name="date"
              value={
                treatmentDetails.date ? moment(treatmentDetails.date) : null
              }
              onChange={(date, dateString) =>
                handleTreatmentChange("date", dateString)
              }
              style={{ width: "100%" }}
              className="antd-style-form-input"
            />
          </Form.Item>
          <Form.Item label="Disease Type" className="antd-style-form-item">
            <Input
              name="diseaseType"
              value={treatmentDetails.diseaseType}
              onChange={(e) =>
                handleTreatmentChange("diseaseType", e.target.value)
              }
              className="antd-style-form-input"
            />
          </Form.Item>
          <Form.Item label="Disease ID" className="antd-style-form-item">
            <Input
              name="diseaseId"
              value={treatmentDetails.diseaseId}
              onChange={(e) =>
                handleTreatmentChange("diseaseId", e.target.value)
              }
              className="antd-style-form-input"
            />
          </Form.Item>
        </Form>
        <div style={{ textAlign: "right", marginTop: 16 }}>
          <Button
            type="primary"
            onClick={saveTreatment}
            className="antd-style-form-button"
            style={{ marginRight: 8 }}
          >
            Save Treatment
          </Button>
          <Button
            onClick={() => setShowAddTreatmentModal(false)}
            className="antd-style-form-button-white"
          >
            Exit
          </Button>
        </div>
      </Modal>
    </Card>
  );
}

export default AITreatmentPopup;
