import React from "react";
import Home from "./home.png";
import './login.css';
import Email from "./email.png";
import Password from "./password.png";
import { Link as RouterLink } from "react-router-dom";
import { withRouter } from "../../../withRouter";
import "tachyons";
import Logo from "./logo.svg";

class LogIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logInEmail: "",
            logInPassword: "",
            selectedOption: "",
            wrongCredentials: false,
            accountNotVerified: false,
            rememberMe: false,
        };
    }

    onEmailChange = (event) => {
        this.setState({ logInEmail: event.target.value });
    };

    onPasswordChange = (event) => {
        this.setState({ logInPassword: event.target.value });
    };

    onOptionChange = (event) => {
        this.setState({ selectedOption: event.target.value });
    };

    onRememberMeChange = (event) => {
        this.setState({ rememberMe: event.target.checked });
    };

    onSubmitLogIn = () => {
        if (!this.state.logInEmail.includes("@")) {
            return alert("Please enter a valid email");
        }
        if (
            this.state.logInEmail === "" ||
            this.state.logInPassword === "" ||
            this.state.selectedOption === ""
        ) {
            return alert("Please enter valid credentials");
        }
        this.setState({ wrongCredentials: false, accountNotVerified: false });

        fetch(
            "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/api/users/login",
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: this.state.logInEmail,
                    password: this.state.logInPassword,
                    selectedOption: this.state.selectedOption,
                }),
            }
        )
            .then((response) => {
                if (response.status === 400) {
                    return response.json().then(body => {
                        if (body === "Account hasn't been verified") {
                            throw new Error("AccountNotVerified");
                        } else {
                            throw new Error("Wrong credentials");
                        }
                    });
                }
                return response.json();
            })
            .then((user) => {
                let new_user;
                switch (this.state.selectedOption) {
                    case "Admin":
                        if (user.admin_id) {
                            new_user = {
                                type: "Admin",
                                id: user.admin_id,
                                name: user.full_name,
                                email: user.email,
                                startInPage: "/Admin",
                            };
                        }
                        break;
                    case "Patient":
                        if (user.id) {
                            new_user = {
                                type: "Patient",
                                id: user.id,
                                name: user.FName,
                                email: user.EmailId,
                                startInPage: "/patient/dashboard",
                            };
                        }
                        break;
                    case "Doctor":
                        if (user.id) {
                            new_user = {
                                type: "Doctor",
                                id: user.id,
                                name: user.Fname,
                                email: user.EmailId,
                                startInPage: "/doctor/dashboard",
                            };
                        }
                        break;
                    case "Clinic":
                        if (user.id) {
                            new_user = {
                                type: "Clinic",
                                id: user.id,
                                name: user.Fname,
                                email: user.EmailId,
                                startInPage: "/clinicalstaff/dashboard",
                            };
                        }
                        break;
                    case "Pharma":
                        if (user.id) {
                            new_user = {
                                type: "Pharma",
                                id: user.id,
                                name: user.name,
                                email: user.email,
                                startInPage: "/pharmaceuticals/dashboard",
                            };
                        }
                        break;
                    case "Hospital":
                        if (user.id) {
                            new_user = {
                                id: user.id,
                                name: user.Hospital_Name,
                                email: user.Email_Id,
                                startInPage: "/",
                            };
                        }
                        break;
                    default:
                        break;
                }

                if (new_user) {
                    if (this.state.rememberMe) {
                        this.props.loadUser(new_user);
                        this.setState({ rememberMe: false });
                    } else {
                        this.props.loadTempUser(new_user);
                    }
                }
            })
            .catch((error) => {
                if (error.message === "Wrong credentials") {
                    this.setState({ wrongCredentials: true });
                } else if (error.message === "AccountNotVerified") {
                    this.setState({ accountNotVerified: true });
                } else {
                    console.error("There was an error during the fetch:", error);
                }
            });
    };

    render() {
        return (
            <div className="login-page">
                <section id="login" className="login-section">
                    <div className="login-content">
                        <div className="left">
                            <div className="login-title">
                                <img src={Logo} alt="e-Hospital Logo" className="login-logo" />
                                {/* <h4>Smart Digital Medicine Solutions</h4> */}
                            </div>

                            <div className="login-frame">
                                <div className="login-info">
                                    <select
                                        id="role-select"
                                        className="login-role-input-box"
                                        value={this.state.selectedOption}
                                        onChange={this.onOptionChange}
                                    >
                                        <option value="">Select a role</option>
                                        <option value="Admin">Admin</option>
                                        <option value="Patient">Patient</option>
                                        <option value="Doctor">Doctor</option>
                                        <option value="Hospital">Hospital</option>
                                        <option value="Clinic">Clinic Staff</option>
                                        <option value="Pharma">Pharmaceutical Office</option>
                                        <option value="EPharma">E-Pharmacy</option>
                                        <option value="PharmaAdmin">Pharmacy-Admin</option>
                                    </select>
                                </div>
                                <div className="login-email">
                                    {/* <img className="email-icon" style={{ maxWidth: '18px' }} src={Email}
                                        alt="Email Icon" /> */}
                                    <input
                                        placeholder="Email address"
                                        type="email"
                                        onChange={this.onEmailChange}
                                        className="input-box"
                                    />
                                </div>
                                <div className="login-password">
                                    {/* <img className="password-icon" style={{ maxWidth: '18px' }} src={Password}
                                        alt="Password Icon" /> */}
                                    <input
                                        placeholder="Password"
                                        type="password"
                                        onChange={this.onPasswordChange}
                                        className="input-box"
                                    />
                                </div>
                                <div className="remember-me">
                                    <input
                                        type="checkbox"
                                        id="remember"
                                        className="larger-checkbox"
                                        checked={this.state.rememberMe}
                                        onChange={this.onRememberMeChange}
                                    />
                                    <label htmlFor="remember">Remember me</label>
                                </div>
                                {this.state.wrongCredentials && (
                                    <div className="error-message">⚠️ Incorrect Email or Password</div>
                                )}
                                {this.state.accountNotVerified && (
                                    <div className="error-message">⚠️ Account hasn't been verified</div>
                                )}

                                <button
                                    className="btn-login"
                                    type="button"
                                    onClick={this.onSubmitLogIn}
                                >
                                    Login
                                </button>

                                <p>Don’t have an account?
                                    <a href="/SignUp"> Sign up</a>
                                </p>
                            </div>
                        </div>
                        <div className="right">
                            <img src={Home} alt="Home" />
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default withRouter(LogIn);
