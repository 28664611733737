import React from 'react';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Home from '../components/home/Home';
import PharmacyHeader from '../components/header/PharmacyHeader.jsx';
import Register from '../components/login/Register';
import Login from '../components/login/Login';
import Account from '../components/account/Account';
import Pharmacyfooter from '../components/footer/Pharmacyfooter';
// import 'bootstrap/dist/css/bootstrap.css';

// Placeholder reducer (replace with actual reducers)
const placeholderReducer = (state = {}, action) => state;

const rootReducer = combineReducers({
  placeholder: placeholderReducer,
});

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

function MainApp() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className="App">
          <PharmacyHeader />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/myaccount" element={<Account />} />
          </Routes>
          <Pharmacyfooter />
          <Toaster />
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default MainApp;
