import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'antd';
import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
  Tooltip,
  Avatar,
  Tab,
  Tabs,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SimpleBarReact from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { formatDistanceToNow } from 'date-fns';

const fToNow = (date) => {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
};

const IconifyComponent = ({ icon, sx, ...other }) => {
  return <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} />;
};

IconifyComponent.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  sx: PropTypes.object,
};

const RootStyle = styled('div')(() => ({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
}));

const SimpleBarStyle = styled(SimpleBarReact)(({ theme }) => ({
  maxHeight: '100%',
  '& .simplebar-scrollbar': {
    '&:before': {
      backgroundColor: theme.palette.grey[600],
    },
    '&.simplebar-visible:before': {
      opacity: 1,
    },
  },
  '& .simplebar-track.simplebar-vertical': {
    width: 10,
  },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: 6,
  },
  '& .simplebar-mask': {
    zIndex: 'inherit',
  },
}));

const Scrollbar = ({ children, sx, ...other }) => {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <RootStyle>
      <SimpleBarStyle timeout={500} clickOnTrack={false} sx={sx} {...other}>
        {children}
      </SimpleBarStyle>
    </RootStyle>
  );
};

Scrollbar.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
};

const MenuPopoverStyled = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    padding: theme.spacing(1),
    width: '500px',
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '15px',
  },
}));

const MenuPopover = ({ children, sx, ...other }) => {
  return (
    <MenuPopoverStyled
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          p: 1,
          width: '550px',
          height: '100vh',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          ...sx,
        },
      }}
      {...other}
    >
      {children}
    </MenuPopoverStyled>
  );
};

MenuPopover.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
};
//模拟数据
const _notifications = [
  {
    id: '1',
    title: 'New message from John',
    description: 'John sent you a message.',
    type: 'message',
    url: 'https://example.com/message/1',
    isUnRead: true,
    createdAt: new Date(),
  },
  {
    id: '2',
    title: 'Meeting Reminder',
    description: "Don't forget your meeting at 3 PM.",
    type: 'reminder',
    url: 'https://example.com/reminder/2',
    isUnRead: false,
    createdAt: new Date(Date.now() - 1000 * 60 * 60),
  },
  {
    id: '3',
    title: 'Order Placed Successfully',
    description: "Your order #1234 has been placed.",
    type: 'alert',
    url: 'https://example.com/order/3',
    isUnRead: true,
    createdAt: new Date(Date.now() - 1000 * 60 * 60 * 2),
  },
  {
    id: '4',
    title: 'Order Shipped',
    description: "Your order #1234 has been shipped.",
    type: 'alert',
    url: 'https://example.com/order/4',
    isUnRead: false,
    createdAt: new Date(Date.now() - 1000 * 60 * 60 * 24),
  },
  {
    id: '5',
    title: 'System Update Tonight',
    description: "System will be updated tonight.",
    type: 'alert',
    url: 'https://example.com/system-update/5',
    isUnRead: true,
    createdAt: new Date(Date.now() - 1000 * 60 * 60 * 3),
  },
  {
    id: '6',
    title: 'New Feature Available',
    description: "Check out the new feature in your dashboard.",
    type: 'message',
    url: 'https://example.com/feature/6',
    isUnRead: false,
    createdAt: new Date(Date.now() - 1000 * 60 * 60 * 5),
  },
  {
    id: '7',
    title: 'New Friend Request',
    description: "John Doe sent you a friend request.",
    type: 'message',
    url: 'https://example.com/friend-request/7',
    isUnRead: true,
    createdAt: new Date(Date.now() - 1000 * 60 * 30),
  },
  {
    id: '8',
    title: 'Weekly Report Ready',
    description: "Your weekly report is ready.",
    type: 'reminder',
    url: 'https://example.com/report/8',
    isUnRead: false,
    createdAt: new Date(Date.now() - 1000 * 60 * 60 * 4),
  },
  {
    id: '9',
    title: 'Password Change Successful',
    description: "Your password was successfully changed.",
    type: 'alert',
    url: 'https://example.com/password-change/9',
    isUnRead: true,
    createdAt: new Date(Date.now() - 1000 * 60 * 10),
  },
  {
    id: '10',
    title: 'New Comment on Post',
    description: "Someone commented on your post.",
    type: 'message',
    url: 'https://example.com/comment/10',
    isUnRead: true,
    createdAt: new Date(Date.now() - 1000 * 60 * 45),
  },
  {
    id: '11',
    title: 'Payment Received',
    description: "We have received your payment.",
    type: 'alert',
    url: 'https://example.com/payment/11',
    isUnRead: true,
    createdAt: new Date(Date.now() - 1000 * 60 * 20),
  },
  {
    id: '12',
    title: 'Jane Started Following You',
    description: "Jane Smith started following you.",
    type: 'message',
    url: 'https://example.com/follow/12',
    isUnRead: true,
    createdAt: new Date(Date.now() - 1000 * 60 * 5),
  },
  {
    id: '13',
    title: 'New Task Assigned',
    description: "You have been assigned a new task.",
    type: 'reminder',
    url: 'https://example.com/task/13',
    isUnRead: true,
    createdAt: new Date(Date.now() - 1000 * 60 * 25),
  },
];

const getNotificationIcon = (type) => {
  switch (type) {
    case 'message':
      return 'eva:email-fill';
    case 'reminder':
      return 'eva:bell-fill';
    case 'alert':
      return 'eva:alert-triangle-fill';
    default:
      return 'eva:question-mark-circle-fill';
  }
};

const Notifications = () => {
  const [notifications, setNotifications] = useState(_notifications);

  const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  const [value, setValue] = useState('all');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleViewAll = () => {
    window.open('/notifications', '_blank');
  };

  return (
    <>
      <Box sx={{ marginRight: '1rem', position: 'relative' }}>
        <IconButton
          color={open ? 'primary' : 'default'}
          onClick={handleOpen}
          sx={{ width: 40, height: 40 }}
        >
          <Badge
            count={totalUnRead}
            overflowCount={99}
            color="error"
            style={{
              position: 'absolute',
              top: '5px',
              right: '7px',
              borderRadius: '50%',
            }}
          >
            <IconifyComponent
              icon="eva:bell-fill"
              sx={{ fontSize: '32px', color: '#B1B5C5', position: 'relative' }}
            />
          </Badge>
        </IconButton>
      </Box>

      <MenuPopover open={Boolean(open)} anchorEl={open} onClose={handleClose}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 0 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h6" sx={{ pl: 2 }}>Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', pl: 2 }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title="Mark all as read">
              <IconButton onClick={handleMarkAllAsRead}>
                <IconifyComponent icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            bgcolor: '#f4f4f4',
            width: '100%',
            '& .MuiTabs-indicator': {
              backgroundColor: 'transparent',
            },
            '& .MuiTab-root': {
              textTransform: 'none',
              color: '#000',
              width: '50%',
              fontSize: '16px',
              height: '75px',
              position: 'relative',
              '& .MuiTab-wrapper': {
                position: 'relative',
                zIndex: 1,
              },
              '&.Mui-selected': {
                color: '#000',
                zIndex: 0,
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: '#fff',
                  borderRadius: '9px',
                  transform: 'scale(0.75)',
                  transition: 'transform 0.3s ease',
                  zIndex: -1,
                },
                '& .count-box': {
                  backgroundColor: 'primary.main',
                },
              },
              '&:not(.Mui-selected)': {
                backgroundColor: 'transparent',
                '& .count-box': {
                  backgroundColor: 'error.main',
                },
              },
            },
          }}
        >
          <Tab
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                <Typography variant="body2" sx={{ marginRight: 1 }}>
                  All
                </Typography>
                <Box
                  className="count-box"
                  sx={{
                    color: '#fff',
                    borderRadius: '12px',
                    padding: '2px 8px',
                    display: 'inline-block',
                    minWidth: '20px',
                    textAlign: 'center',
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}
                >
                  {notifications.length}
                </Box>
              </Box>
            }
            value="all"
          />
          <Tab
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                <Typography variant="body2" sx={{ marginRight: 1 }}>
                  Unread
                </Typography>
                <Box
                  className="count-box"
                  sx={{
                    color: '#fff',
                    borderRadius: '12px',
                    padding: '2px 8px',
                    display: 'inline-block',
                    minWidth: '20px',
                    textAlign: 'center',
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}
                >
                  {totalUnRead}
                </Box>
              </Box>
            }
            value="unread"
          />
        </Tabs>

        <Scrollbar sx={{ flexGrow: 1, px: 2 }}>
          <List disablePadding>
            {notifications
              .filter((n) => value === 'all' || (value === 'unread' && n.isUnRead))
              .map((notification, index) => (
                <React.Fragment key={notification.id}>
                  <NotificationItem notification={notification} />
                  {index !== notifications.length - 1 && <Divider />}
                </React.Fragment>
              ))}
          </List>
        </Scrollbar>

        <Box sx={{ p: 0, display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="text"
            sx={{
              color: '#000',
              height: '75px',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
            onClick={handleViewAll}
          >
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
};

const NotificationItem = ({ notification }) => {
  const handleClick = () => {
    window.open(notification.url, '_blank');
  };

  return (
    <ListItemButton
      onClick={handleClick}
      sx={{
        py: 7,
        px: 3,
        mt: '1px',
        bgcolor: '#fff',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>
          <IconifyComponent icon={getNotificationIcon(notification.type)} width={24} height={24} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="subtitle2">
              {notification.title}
            </Typography>
            {notification.isUnRead && (
              <Box
                sx={{
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  backgroundColor: 'red',
                  marginLeft: '8px',
                }}
              />
            )}
          </Box>
        }
        secondary={
          <>
            <Typography variant="body2" color="text.secondary">
              {notification.description}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                display: 'flex',
                alignItems: 'center',
                color: 'text.disabled',
              }}
            >
              <IconifyComponent icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 15 }} />
              {fToNow(notification.createdAt)}
            </Typography>
          </>
        }
      />
    </ListItemButton>
  );
};

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default Notifications;
