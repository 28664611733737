import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Button, Divider, Select, MenuItem, InputBase, Snackbar, Alert, Paper } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { styled } from "@mui/material/styles";
import { readLoginData } from "../../loginData";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MessageSend } from "../../api/user";

const BASE_URLS = "https://e-react-node-backend-22ed6864d5f3.herokuapp.com";

const GrayBackgroundContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "75vh",
  backgroundColor: "#f5f5f5",
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[4],
}));

const SmallSelectStyle = styled(Select)(({ theme }) => ({
  flex: "none",
  width: "150px",
  height: "35px",
  padding: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
}));

const SearchBox = styled(InputBase)(({ theme, disabled }) => ({
  flex: 1,
  height: "35px",
  padding: theme.spacing(0.5, 1),
  fontSize: "1rem",
  border: `1px solid ${disabled ? theme.palette.error.main : theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: disabled ? theme.palette.action.disabledBackground : theme.palette.background.paper,
  color: disabled ? theme.palette.text.disabled : theme.palette.text.primary,
  cursor: disabled ? "not-allowed" : "text",
}));

const SubjectInput = styled(InputBase)(({ theme }) => ({
  flex: 1,
  height: "35px",
  padding: theme.spacing(0.5, 1),
  fontSize: "1rem",
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
}));

const quillModules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ align: [] }],
    ["bold", "italic", "underline", "link"],
    [{ color: [] }, { background: [] }],
    ["blockquote", "code-block"],
  ],
};

const SendNewMessages = () => {
  const [firstDropdown, setFirstDropdown] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [secondDropdown, setSecondDropdown] = useState("");
  const [userId, setUserId] = useState(null);
  const [userType, setUserType] = useState(null);
  const [recipientOptions, setRecipientOptions] = useState([]);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [subject, setSubject] = useState("");
  const [messageContent, setMessageContent] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate();

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchLoginData = async () => {
      try {
        const loginData = await readLoginData();
        if (loginData) {
          setUserId(loginData.id);
          setUserType(loginData.type);

          if (loginData.type === "Doctor") {
            setRecipientOptions([
              { label: "Patient", value: "Patient" },
              { label: "Clinic Staff", value: "ClinicStaff" },
            ]);
          } else if (loginData.type === "Patient") {
            setRecipientOptions([
              { label: "Doctor", value: "Doctor" },
              { label: "Clinic Staff", value: "ClinicStaff" },
            ]);
          } else if (loginData.type === "Clinic") {
            setRecipientOptions([
              { label: "Patient", value: "Patient" },
              { label: "Doctor", value: "Doctor" },
            ]);
          }
        }
      } catch (error) {
        console.error("Error fetching login data:", error);
      }
    };

    fetchLoginData();
  }, []);

  useEffect(() => {
    const fetchItems = async () => {
      if (!firstDropdown) return;

      try {
        let response;
        if (firstDropdown === "Patient") {
          response = await axios.post(`${BASE_URLS}/DoctorPatientsAuthorized`, { doctorId: userId });
        } else if (firstDropdown === "Doctor") {
          response = await axios.post(`${BASE_URLS}/findDoctorsByPatientId`, { patientId: userId });
        } else if (firstDropdown === "ClinicStaff") {
          response = await axios.post(`${BASE_URLS}/findClinicStaffsByDoctorId`, { doctorId: userId });
        }

        const formattedItems = response?.data?.map((item) => ({
          label: `${item.FName || ""} ${item.LName || ""}`,
          value: item.id || item.patient_id || item.doctor_id || item.staff_id,
        }));
        setItems(formattedItems || []);
        setFilteredItems(formattedItems || []); // 初始化搜索数据
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };

    fetchItems();
  }, [firstDropdown, userId]);

  useEffect(() => {
    if (searchTerm.trim()) {
      const filtered = items.filter((item) =>
        item.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredItems(filtered);
    } else {
      setFilteredItems(items);
    }
  }, [searchTerm, items]);

  const handleSecondDropdownClick = () => {
    if (!firstDropdown) {
      setSnackbarMessage("Please select a category first.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setDropdownVisible(false); // 确保下拉框不会显示
    } else {
      setDropdownVisible(true);
    }
  };

  const handleSend = async () => {
    if (!firstDropdown || !secondDropdown || !messageContent.trim()) {
      setSnackbarMessage("Please fill all fields before sending.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    const finalSubject = subject || "No Subject";
    try {
      const sendMessageData = {
        conversationId: 0,
        senderType: userType,
        sender_id: userId,
        receiverType: firstDropdown,
        receiver_id: secondDropdown,
        viewer_permissions: {},
        subject: finalSubject,
        content: messageContent,
      };
      const result = await MessageSend(sendMessageData);
      if (result === 1) {
        setSnackbarMessage("Message sent successfully!");
        setSnackbarSeverity("success");
        // 重置状态
        setSubject("");
        setMessageContent("");
        setFirstDropdown("");
        setSecondDropdown("");
        setSearchTerm("");
        setFilteredItems([]);
      } else {
        setSnackbarMessage("Failed to send message. Please try again.");
        setSnackbarSeverity("error");
      }
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error sending message:", error);
      setSnackbarMessage("An error occurred. Please try again later.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <GrayBackgroundContainer>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
        <Typography variant="h6">New Message</Typography>
      </Box>

      <Divider />

      <Box sx={{ display: "flex", alignItems: "center", mt: 2, mb: 2 }}>
        <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "1.1rem", mr: 2 }}>
          To:
        </Typography>

        <SmallSelectStyle value={firstDropdown} onChange={(e) => setFirstDropdown(e.target.value)}>
          <MenuItem value="">Select recipient group</MenuItem>
          {recipientOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </SmallSelectStyle>

        <Box sx={{ position: "relative", marginLeft: 2, flex: 1 }}>
          <SearchBox
            placeholder="Search recipient"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onClick={handleSecondDropdownClick}
            disabled={!firstDropdown}
          />
          {isDropdownVisible && searchTerm && (
            <Paper
              sx={{ position: "absolute", top: "100%", left: 0, right: 0, zIndex: 10 }}
              onMouseLeave={() => setDropdownVisible(false)}
            >
              {filteredItems.map((item) => (
                <MenuItem
                  key={item.value}
                  onClick={() => {
                    setSecondDropdown(item.value);
                    setSearchTerm(item.label);
                    setDropdownVisible(false);
                  }}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Paper>
          )}
        </Box>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "1.1rem", mr: 2 }}>
          Subject:
        </Typography>
        <SubjectInput
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          placeholder="Enter subject here"
        />
      </Box>

      <Box sx={{ display: "flex", height: "400px", flexDirection: "column", mb: 2 }}>
        <ReactQuill
          modules={quillModules}
          value={messageContent}
          onChange={setMessageContent}
          className="editor-content"
          style={{ height: "90%" }}
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <Button variant="outlined" color="primary" sx={{ mr: 2 }} onClick={() => navigate(`/${userType}/messages`)}>
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={handleSend}>
          Send
        </Button>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </GrayBackgroundContainer>
  );
};

SendNewMessages.propTypes = {
  children: PropTypes.node,
};

export default SendNewMessages;
