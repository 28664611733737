import React, { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import ImageData from "./Image.json";
import { useNavigate, useLocation } from "react-router-dom";

const organSegmentations = {};
ImageData.shapes.forEach((shape) => {
  organSegmentations[shape.label] = shape.points;
});

const organModelMapping = {
  brain: ["Brain1", "Brain2"],
  heart: ["Heart1", "Heart2"],
  lungs: ["Lung1", "Lung2"],
  liver: ["Liver1", "Liver2"],
  kidney: ["Kidney1", "KidneyLeft1", "KidneyLeft2", "KidneyRight2"],
  thyroid: ["Thyroid1", "Thyroid2"],
  bone: ["Bone1", "Bone2", "Bone3", "Bone4", "Bone5", "Bone6"],
  breast: ["Breast"],
  eye: ["EyeLeft1", "EyeRight1", "EyeRight2"],
  skin: ["Skin1", "Skin2"],
  reproductive: ["Ovary1", "Ovary2"],
  pancreas: ["Pancreas1", "Pancreas2"],
};

const organModels = {
  brain: [
    { name: "Brain Stroke Prediction", url: "/brainstroke", accuracy: "97%" },
    { name: "Second Brain Stroke Prediction", url: "/BrainStrokePred" },
    { name: "Brain Anomalies", url: "/brainAnomalies" },
    { name: "Alzheimer's Disease Risk Assessment", url: "/alzheimer" },
    { name: "Epileptic Seizure Detection", url: "/epilepticSeizure" },
    { name: "Dementia Detection", url: "/Dementia" },
    { name: "Second Dementia Detection", url: "/dementiaDetection" },
    { name: "Tumor Detection", url: "/Tumor" },
    { name: "Psychology Detection", url: "/PsychologyPrediction" },
  ],
  heart: [
    { name: "Heart Failure Prediction", url: "/heartfailure", accuracy: "94%" },
    { name: "Heart Stroke", url: "/heartstroke", accuracy: "93%" },
    { name: "Heart Diagnostic", url: "/Heartdiseaseml" },
    { name: "Heart Disease (Arrhythmia)", url: "/heart-disease" },
    { name: "Heart Disease (CAD)", url: "/heartDisease" },
    { name: "Coronary Artery Disease", url: "/coronaryDisease" },
    { name: "Heart Anomalies", url: "/heartAnomalies" },
    { name: "AtrialFibrillation Prediction", url: "/atrialFibrillation" },
    { name: "Coronary Artery Prediction", url: "/CoronaryArteryPrediction" },
  ],
  lungs: [
    { name: "Lung Cancer Prediction", url: "/LungCancer" },
    {
      name: "Pneumonia Disease Detection",
      url: "/Pneumoniaml",
      accuracy: "94%",
    },
    { name: "Pneumonia Prediction", url: "/pneumoniaPrediction" },
    { name: "Pulmonary Disease Detection", url: "/pulmonaryDiseaseML" },
    { name: "Covid Prediction", url: "/CovidPred" },
  ],
  liver: [
    {
      name: "Liver Disease Diagnosis",
      url: "/liverdiseaseML",
      accuracy: "99.55%",
    },
    { name: "Hepatitis Prediction", url: "/hepatitis" },
  ],
  kidney: [
    { name: "Kidney Stone Diagnosis", url: "/kidneystoneml", accuracy: "96%" },
    {
      name: "Chronic Kidney Disease",
      url: "/chronickidneyml",
      accuracy: "95%",
    },
    { name: "Chronic Kidney", url: "/chronicKidney" },
    { name: "Kidney Failure Prediction", url: "/KidneyFailure" },
  ],
  thyroid: [
    {
      name: "Thyroid Disease Detection",
      url: "/thyroidDiseaseml",
      accuracy: "96%",
    },
    { name: "Thyroid Prediction", url: "/thyroidPrediction" },
  ],
  bone: [
    { name: "Bone Fracture", url: "/BoneFracture" },
    { name: "Bone Cancer Detection", url: "/Bonecancerml" },
    { name: "Osteoporosis Detection", url: "/Osteoporosis" },
  ],
  breast: [
    {
      name: "Breast Disease Diagnosis",
      url: "/BreastDisease",
      accuracy: "98%",
    },
    {
      name: "Breast Cancer Diagnosis (Linear)",
      url: "/breastcancerml",
      accuracy: "97%",
    },
    {
      name: "Breast Cancer Diagnosis (Logistic)",
      url: "/breastcancerpredictionml",
      accuracy: "95%",
    },
  ],
  eye: [
    { name: "Glaucoma Detection", url: "/GlaucomaDetection" },
    { name: "Funduns Prediction", url: "/fundunsEye" },
  ],
  skin: [
    { name: "Skin Cancer Diagnostic", url: "/Skincancerml" },
    { name: "Skin Cancer Diagnosis", url: "/skinCancerMLPage" },
    { name: "Skin Diseases Diagnosis", url: "/skinCancerDiseasesPage" },
  ],
  reproductive: [
    { name: "PCOS Detection", url: "/pcos" },
    { name: "PCOS Prediction", url: "/secondpcos" },
  ],
  joints: [
    { name: "Rheumatoid Arthritis", url: "/rheumatoidArthritisTwo" },
    { name: "Sclerosis Diagnosis", url: "/sclerosisDiagnosis" },
  ],
  general: [
    { name: "Disease Prognosis", url: "/diease-prognosis" },
    { name: "Diabetes Prognosis", url: "/diabetes-prognosis" },
    { name: "Diabetics Detection", url: "/DiabeticML" },
    { name: "Insomnia Prediction", url: "/insomnia" },
  ],
};

const organPositions = {
  front: {
    brain: { top: "5%", left: "25%" },
    eye: { top: "10%", left: "25%" },
    thyroid: { top: "22%", left: "25%" },
    lungs: { top: "35%", left: "21%" },
    heart: { top: "42%", left: "29%" },
    breast: { top: "42%", left: "35%" },
    liver: { top: "57%", left: "20%" },
    kidney: { top: "60%", left: "33%" },
    reproductive: { top: "78%", left: "25%" },
    skin: { top: "38%", left: "15%" },
    bone: { top: "78%", left: "15%" },
    joints: { top: "85%", left: "20%" },
    general: { top: "20%", left: "10%" },
  },
  side: {
    brain: { top: "5%", left: "75%" },
    eye: { top: "10%", left: "70%" },
    thyroid: { top: "20%", left: "75%" },
    lungs: { top: "42%", left: "71%" },
    heart: { top: "46%", left: "74%" },
    breast: { top: "42%", left: "85%" },
    liver: { top: "54%", left: "67%" },
    kidney: { top: "60%", left: "80%" },
    reproductive: { top: "78%", left: "75%" },
    skin: { top: "38%", left: "65%" },
    bone: { top: "50%", left: "76%" },
    joints: { top: "85%", left: "70%" },
    general: { top: "20%", left: "90%" },
  },
};

const SegmentationLayer = ({ selectedOrgan }) => {
  if (!selectedOrgan) return null;

  const baseOrgan = selectedOrgan.replace(/^(front-|side-)/, "");
  const segmentLabels = organModelMapping[baseOrgan] || [];

  return (
    <svg
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        pointerEvents: "none",
        zIndex: 25,
      }}
      viewBox="0 0 4200 4200"
    >
      {segmentLabels.map((label) => {
        const points = organSegmentations[label];
        if (!points) return null;

        const pathData =
          points.reduce(
            (acc, [x, y], i) => acc + (i === 0 ? `M${x},${y}` : ` L${x},${y}`),
            ""
          ) + " Z";

        return (
          <path
            key={label}
            d={pathData}
            fill="rgba(255, 0, 0, 0.3)"
            stroke="rgba(255, 0, 0, 0.8)"
            strokeWidth="4"
            style={{
              transition: "all 0.3s ease",
              filter: "drop-shadow(0 0 15px rgba(255, 0, 0, 0.5))",
              zIndex: 25,
            }}
          />
        );
      })}
    </svg>
  );
};

const OrganMarker = ({
  organ,
  position,
  onClick,
  isActive,
  isHovered,
  onHover,
  onLeave,
}) => {
  const displayName = {
    brain: "Brain",
    heart: "Heart",
    lungs: "Lungs",
    liver: "Liver",
    kidney: "Kidneys",
    thyroid: "Thyroid",
    bone: "Bones",
    breast: "Breast",
    eye: "Eyes",
    skin: "Skin",
    reproductive: "Reproductive",
    joints: "Joints",
    general: "General Health",
  }[organ];

  return (
    <div
      style={{
        position: "absolute",
        top: position.top,
        left: position.left,
        zIndex: 20,
      }}
    >
      <button
        onClick={onClick}
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
        style={{
          background:
            isActive || isHovered
              ? "rgba(255, 255, 255, 0.15)"
              : "rgba(255, 255, 255, 0.1)",
          border: "2.5px solid rgba(255, 255, 255, 0.95)",
          width: "28px",
          height: "28px",
          borderRadius: "50%",
          cursor: "pointer",
          transition: "all 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
          transform: isActive || isHovered ? "scale(1.2)" : "scale(1)",
          boxShadow:
            isActive || isHovered
              ? "0 0 0 2px rgba(255,255,255,0.2), 0 0 20px rgba(255,255,255,0.4)"
              : "0 0 0 1px rgba(255,255,255,0.1)",
          position: "relative",
        }}
      >
        <span
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "6px",
            height: "6px",
            backgroundColor: "white",
            borderRadius: "50%",
            opacity: isActive || isHovered ? 0.95 : 0.8,
            transition: "all 0.3s ease",
          }}
        />

        <span
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isActive || isHovered ? "200%" : "100%",
            height: isActive || isHovered ? "200%" : "100%",
            border: "2px solid rgba(255,255,255,0.2)",
            borderRadius: "50%",
            transition: "all 0.5s ease",
            opacity: isActive || isHovered ? 1 : 0,
            animation: isActive || isHovered ? "pulse 2s infinite" : "none",
          }}
        />
      </button>

      {isHovered && (
        <div
          style={{
            position: "absolute",
            zIndex: 30,
            top: "-45px",
            left: "50%",
            transform: "translateX(-50%)",
            background:
              "linear-gradient(to right, rgba(37,99,235,0.95), rgba(59,130,246,0.95))",
            padding: "6px 12px",
            borderRadius: "8px",
            boxShadow:
              "0 4px 12px rgba(0,0,0,0.1), 0 0 0 1px rgba(255,255,255,0.1)",
            whiteSpace: "nowrap",
            border: "1px solid rgba(255,255,255,0.2)",
            animation: "fadeInZoomIn 200ms forwards",
          }}
        >
          <div
            style={{
              color: "white",
              fontSize: "0.875rem",
              fontWeight: 500,
            }}
          >
            {displayName}
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "-4px",
              left: "50%",
              transform: "translateX(-50%) rotate(45deg)",
              width: "8px",
              height: "8px",
              background:
                "linear-gradient(to right bottom, rgba(59,130,246,0.95), rgba(59,130,246,0.95))",
              border: "1px solid rgba(255,255,255,0.2)",
              borderTop: "none",
              borderLeft: "none",
            }}
          />
        </div>
      )}
    </div>
  );
};

const ModelList = ({ models, onClose, name, navigate, patientInfo }) => {
  return (
    <div
      style={{
        position: "fixed",
        background: "linear-gradient(to bottom right, #2563eb, #1e40af)",
        borderRadius: "1rem",
        padding: "2rem",
        zIndex: 50,
        width: "520px",
        maxHeight: "85vh",
        overflowY: "auto",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        boxShadow:
          "0 8px 32px rgba(37,99,235,0.2), inset 0 0 80px rgba(255,255,255,0.1)",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1.5rem",
          position: "sticky",
          top: "0",
          paddingBottom: "1rem",
          borderBottom: "1px solid rgba(255,255,255,0.2)",
        }}
      >
        <h3
          style={{
            fontWeight: "bold",
            fontSize: "1.5rem",
            color: "white",
          }}
        >
          {name} Models
        </h3>
        <button
          onClick={onClose}
          style={{
            color: "rgba(255,255,255,0.8)",
            padding: "0.5rem",
            borderRadius: "9999px",
            transition: "all 300ms",
            transform: "translateX(0)",
          }}
        >
          <CloseOutlined style={{ fontSize: 20 }} />
        </button>
      </div>
      <div style={{ position: "relative" }}>
        {models.map((model, index) => (
          <button
            key={index}
            onClick={() => navigate(model.url, { state: patientInfo })}
            style={{
              width: "100%",
              padding: "1rem",
              borderRadius: "0.75rem",
              transition: "all 300ms",
              position: "relative",
              textAlign: "left",
              backgroundColor: "rgba(255,255,255,0.1)",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "rgba(255,255,255,0.05)",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              marginBottom: "12px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <div style={{ flex: 1 }}>
                <div
                  style={{
                    fontWeight: 500,
                    color: "white",
                    transition: "color 300ms",
                    fontSize: "1.125rem",
                  }}
                >
                  {model.name}
                </div>
                {model.accuracy && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "0.5rem",
                      gap: "0.5rem",
                    }}
                  >
                    <div
                      style={{
                        height: "0.375rem",
                        width: "0.375rem",
                        borderRadius: "9999px",
                        backgroundColor: "rgba(147,197,253,1)",
                      }}
                    ></div>
                    <div
                      style={{
                        fontSize: "0.875rem",
                        color: "rgba(191,219,254,1)",
                      }}
                    >
                      Accuracy: {model.accuracy}
                    </div>
                  </div>
                )}
              </div>
              <div
                style={{
                  color: "rgba(255,255,255,0.5)",
                  transition: "all 300ms",
                  transform: "translateX(0)",
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M5 12h14M12 5l7 7-7 7" />
                </svg>
              </div>
            </div>

            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                background:
                  "linear-gradient(to right, rgba(96,165,250,0), rgba(96,165,250,0.1), rgba(96,165,250,0))",
                opacity: 0,
                transition: "opacity 500ms",
                borderRadius: "0.75rem",
                pointerEvents: "none",
                backgroundSize: "200% 100%",
                animation: "shimmer 2s infinite linear",
              }}
            />
          </button>
        ))}
      </div>
    </div>
  );
};

const MultiModelScene = () => {
  const [selectedOrgan, setSelectedOrgan] = useState(null);
  const [hoveredOrgan, setHoveredOrgan] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const patientInfo = location.state || {};

  const handleOrganClick = (organ) => {
    if (selectedOrgan === organ) {
      setSelectedOrgan(null);
    } else {
      setSelectedOrgan(organ);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        background: "linear-gradient(to bottom, #f9fafb, #ffffff)",
      }}
    >
      <div style={{ position: "relative", width: "100%", maxWidth: "1024px" }}>
        <div
          style={{
            position: "relative",
            borderRadius: "1rem",
            overflow: "hidden",
            boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
            backgroundColor: "white",
            width: "80%",
            margin: "0 auto",
          }}
        >
          <img
            src="/images/body-diagram.png"
            alt="Human Anatomy"
            style={{ width: "100%", height: "auto", zIndex: 10 }}
          />

          <SegmentationLayer selectedOrgan={selectedOrgan} />

          {Object.entries(organPositions.front).map(([organ, position]) => (
            <OrganMarker
              key={`front-${organ}`}
              organ={organ}
              position={position}
              onClick={() => handleOrganClick(`front-${organ}`)}
              isActive={selectedOrgan === `front-${organ}`}
              isHovered={hoveredOrgan === `front-${organ}`}
              onHover={() => setHoveredOrgan(`front-${organ}`)}
              onLeave={() => setHoveredOrgan(null)}
            />
          ))}

          {Object.entries(organPositions.side).map(([organ, position]) => (
            <OrganMarker
              key={`side-${organ}`}
              organ={organ}
              position={position}
              onClick={() => handleOrganClick(`side-${organ}`)}
              isActive={selectedOrgan === `side-${organ}`}
              isHovered={hoveredOrgan === `side-${organ}`}
              onHover={() => setHoveredOrgan(`side-${organ}`)}
              onLeave={() => setHoveredOrgan(null)}
            />
          ))}

          {selectedOrgan && (
            <ModelList
              models={
                organModels[
                  selectedOrgan.replace("front-", "").replace("side-", "")
                ]
              }
              onClose={() => setSelectedOrgan(null)}
              name={
                selectedOrgan
                  .replace("front-", "")
                  .replace("side-", "")
                  .charAt(0)
                  .toUpperCase() +
                selectedOrgan
                  .replace("front-", "")
                  .replace("side-", "")
                  .slice(1)
              }
              navigate={navigate}
              patientInfo={patientInfo}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const style = document.createElement("style");
style.textContent = `
  @keyframes pulse {
    0% { transform: translate(-50%, -50%) scale(1); opacity: 0.8; }
    50% { transform: translate(-50%, -50%) scale(1.5); opacity: 0.2; }
    100% { transform: translate(-50%, -50%) scale(1); opacity: 0.8; }
  }
  @keyframes shimmer {
    0% { background-position: 200% 0; }
    100% { background-position: -200% 0; }
  }
  @keyframes fadeInZoomIn {
    from { opacity: 0; transform: translateX(-50%) scale(0.95); }
    to { opacity: 1; transform: translateX(-50%) scale(1); }
  }
`;
document.head.appendChild(style);

export default MultiModelScene;
