import PropTypes from 'prop-types';
import { forwardRef,useState, useRef,useEffect } from 'react';
import { Box, Input,Portal,Typography,Backdrop, Button, Tooltip, IconButton, Avatar as MUIAvatar,Link, Divider,MenuItem,Snackbar,Alert } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import { Icon } from '@iconify/react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { MessageSend } from '../../api/user';
import { readLoginData } from '../../loginData';
// --------------------------------------------------------------------
const ReplyInputRootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 1999,
  minHeight: 700,
  minWidth: 750,
  outline: 'none',
  display: 'flex',
  position: 'fixed',
  overflow: 'hidden',
  flexDirection: 'column',
  margin: theme.spacing(3),
  boxShadow:
    (theme.customShadows && theme.customShadows.z20) ||
    '0px 10px 20px rgba(0, 0, 0, 0.2)',
  borderRadius: Number(theme.shape.borderRadius) * 2,
  backgroundColor: theme.palette.background.paper,
}));

const InputStyle = styled(Input)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(1.5, 0),
  border: 'none',
  borderRadius: 0,
  boxShadow: 'none',
  '& .MuiInputBase-root': {
    border: 'none',
    boxShadow: 'none',
  },
  '& .MuiInputBase-input': {
    padding: theme.spacing(0, 2),
    fontSize: '1rem',
    border: 'none',
    boxShadow: 'none',
    color: theme.palette.text.primary,
    '&::placeholder': {
      color: theme.palette.text.secondary,
      opacity: 1,
    },
  },
}));

const ReplyInputCardStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 1.5,
  boxShadow: theme.shadows[2],
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  height: '400px',
  width: '90%',
  padding: theme.spacing(2),
  margin: 'auto',
  '& .editor-content': {
    flexGrow: 1,
    minHeight: 150,
    color: theme.palette.text.primary,
  },
}));

const ReplyInputToolbarStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  padding: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.default,
  '& button': {
    border: 'none',
    background: 'none',
    padding: '8px',
    cursor: 'pointer',
    color: theme.palette.text.primary,
    '& svg': {
      fill: theme.palette.text.primary,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.main,
      '& svg': {
        fill: theme.palette.primary.main,
      },
    },
  },
}));

const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic'],
    [{ color: [] }, { background: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
    ['link'],
  ],
};
// ----------------------------------------------------------------------

const MarkdownRootStyle = styled('div')(({ theme }) => {
  const isLight = theme.palette.mode === 'light';

  return {
    // List
    '& ul, & ol': {
      ...theme.typography.body1,
      paddingLeft: theme.spacing(5),
      '& li': {
        lineHeight: 2,
      },
    },

    // Blockquote
    '& blockquote': {
      lineHeight: 1.5,
      fontSize: '1.5em',
      margin: '40px auto',
      position: 'relative',
      fontFamily: 'Georgia, serif',
      padding: theme.spacing(3, 3, 3, 8),
      borderRadius: Number(theme.shape.borderRadius) * 2,
      backgroundColor: theme.palette.background.neutral,
      color: `${theme.palette.text.secondary} !important`,
      [theme.breakpoints.up('md')]: {
        width: '80%',
      },
      '& p, & span': {
        marginBottom: '0 !important',
        fontSize: 'inherit !important',
        fontFamily: 'Georgia, serif !important',
        color: `${theme.palette.text.secondary} !important`,
      },
      '&:before': {
        left: 16,
        top: -8,
        display: 'block',
        fontSize: '3em',
        content: '"\\201C"',
        position: 'absolute',
        color: theme.palette.text.disabled,
      },
    },

    // Code Block
    '& pre, & pre > code': {
      fontSize: 16,
      overflowX: 'auto',
      whiteSpace: 'pre',
      padding: theme.spacing(2),
      color: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: isLight ? theme.palette.grey[900] : theme.palette.grey[500_16],
    },
    '& code': {
      fontSize: 14,
      borderRadius: 4,
      whiteSpace: 'pre',
      padding: theme.spacing(0.2, 0.5),
      color: theme.palette.warning[isLight ? 'darker' : 'lighter'],
      backgroundColor: theme.palette.warning[isLight ? 'lighter' : 'darker'],
      '&.hljs': { padding: 0, backgroundColor: 'transparent' },
    },
  };
});

// ----------------------------------------------------------------------

const Markdown=({ ...other })=> {
  return (
    <MarkdownRootStyle>
      <ReactMarkdown rehypePlugins={[rehypeRaw]} components={components} {...other} />
    </MarkdownRootStyle>
  );
}

// ----------------------------------------------------------------------

const components = {
  h1: ({ ...props }) => <Typography variant="h1" {...props} />,
  h2: ({ ...props }) => <Typography variant="h2" {...props} />,
  h3: ({ ...props }) => <Typography variant="h3" {...props} />,
  h4: ({ ...props }) => <Typography variant="h4" {...props} />,
  h5: ({ ...props }) => <Typography variant="h5" {...props} />,
  h6: ({ ...props }) => <Typography variant="h6" {...props} />,
  hr: ({ ...props }) => <Divider sx={{ my: 3 }} {...props} />,
  a: ({ ...props }) =>
    props.href.includes('http') ? <Link target="_blank" rel="noopener" {...props} /> : <Link {...props} />,
};

// ----------------------------------------------------------------------

const Iconify = ({ icon, sx, ...other }) => (
  <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} />
);

Iconify.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  sx: PropTypes.object,
};

// ----------------------------------------------------------------------

// Custom Avatar component with color support
const Avatar = forwardRef(({ color = 'default', children, sx, ...other }, ref) => {
  const theme = useTheme();

  return (
    <MUIAvatar
      ref={ref}
      sx={{
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette[color]?.contrastText,
        backgroundColor: theme.palette[color]?.main,
        ...sx,
      }}
      {...other}
    >
      {children}
    </MUIAvatar>
  );
});

Avatar.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'info', 'success', 'warning', 'error']),
};

// ----------------------------------------------------------------------

// Date formatting function
const fDateTimeSuffix = (date) => {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
};

// ----------------------------------------------------------------------

// Utility functions for creating avatar details
const PRIMARY_NAME = ['A', 'N', 'H', 'L', 'Q', '9', '8'];
const INFO_NAME = ['F', 'G', 'T', 'I', 'J', '1', '2', '3'];
const SUCCESS_NAME = ['K', 'D', 'Y', 'B', 'O', '4', '5'];
const WARNING_NAME = ['P', 'E', 'R', 'S', 'C', 'U', '6', '7'];
const ERROR_NAME = ['V', 'W', 'X', 'M', 'Z'];

function getFirstCharacter(name) {
  return name && name.charAt(0).toUpperCase();
}

function getAvatarColor(name) {
  const firstChar = getFirstCharacter(name);
  if (PRIMARY_NAME.includes(firstChar)) return 'primary';
  if (INFO_NAME.includes(firstChar)) return 'info';
  if (SUCCESS_NAME.includes(firstChar)) return 'success';
  if (WARNING_NAME.includes(firstChar)) return 'warning';
  if (ERROR_NAME.includes(firstChar)) return 'error';
  return 'default';
}

const createAvatar = (name) => ({
  name: getFirstCharacter(name),
  color: getAvatarColor(name),
});

// ----------------------------------------------------------------------

// Styles
const ToolBarStyle = styled('div')(({ theme }) => ({
  height: 84,
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 2),
  justifyContent: 'space-between',
}));

// ----------------------------------------------------------------------
const RootStyle = styled('div')({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});
const MarkdownStyle = styled('div')(({ theme }) => ({
  '& > p': {
    ...theme.typography.body1,
    marginBottom: theme.spacing(2),
  },
}));

const MailDetailsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%', // 确保占据父容器的全部高度
});

const ToolBarContainer = styled(Box)({
  flex: 1, // 占1/5高度
  display: 'flex',
  alignItems: 'center',
  padding: '16px',
  borderBottom: '1px solid #ddd',
});

const MessagesContainer = styled(Box)({
  flex: 9, // 占7/15高度
  overflowY: 'auto', // 只允许消息区域滚动
  padding: '16px',
  borderBottom: '1px solid #ddd',
});

const ReplyContainer = styled(Box)({
  flex: 5, // 占1/3高度
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  borderTop: '1px solid #ddd',
});

const MailDetails = ({ conversation, onMessageSent, currentUser }) => {
  const userInfo = readLoginData();
  const [replyContent, setReplyContent] = useState('');
  const messagesEndRef = useRef(null);

  const isConversationAvailable = !!conversation;

  // 获取类别
  const category = conversation.category || '';

  // Participant info
  let otherUserName = '';
  let participantId = 0;
  let participantType = '';

  if (category === 'Read only' && conversation.participants) {
    // 对于只读会话，显示所有参与者
    otherUserName = conversation.participants
      .map((participant) => `${participant.type} ${participant.name}`)
      .join(', ');
  } else {
    // 非只读会话
    otherUserName = isConversationAvailable ? conversation.participant_name : '';
    participantId = conversation.participant_id;
    participantType = category;
  }

  // Current user info
  const myUserName = userInfo.name;
  const myUserId = userInfo.id;
  const myUserType = userInfo.type;

  // Combine messages
  const messages = [];
  if (isConversationAvailable) {
    if (conversation.send && conversation.receive) {
      // Sent messages (we are the sender)
      conversation.send?.forEach((msg) => {
        messages.push({
          ...msg,
          sender_name: myUserName,
          sender_id: myUserId,
          receiver_name: otherUserName,
          receiver_id: participantId,
        });
      });

      // Received messages (we are the receiver)
      conversation.receive?.forEach((msg) => {
        messages.push({
          ...msg,
          sender_name: otherUserName,
          sender_id: participantId,
          receiver_name: myUserName,
          receiver_id: myUserId,
        });
      });
    } else if (conversation.messages_by_sender_type) {
      // For 'Read only' category
      Object.entries(conversation.messages_by_sender_type).forEach(
        ([senderType, msgs]) => {
          msgs.forEach((msg) => {
            messages.push({
              ...msg,
              sender_name: senderType,
              sender_id: null,
              receiver_name: '',
              receiver_id: null,
            });
          });
        }
      );
    }

    // Sort messages by timestamp
    messages.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
  }

  // Scroll to bottom when messages update
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSendReply = async () => {
    if (!isConversationAvailable) return;

    const conversationId = conversation.conversation_id || 0;

    const replyMessageData = {
      conversationId: conversationId,
      senderType: myUserType,
      sender_id: myUserId,
      receiverType: participantType,
      receiver_id: participantId,
      viewer_permissions: conversation.viewer_permissions,
      subject: `Re: ${
        messages.length > 0 ? messages[messages.length - 1].subject : 'Untitled'
      }`,
      content: replyContent,
    };

    try {
      const result = await MessageSend(replyMessageData);

      if (result === 1) {
        console.log('Message sent successfully');
        setReplyContent('');
        onMessageSent();
      } else {
        console.error('Failed to send message');
        alert('Failed to send message. Please try again.');
      }
    } catch (error) {
      console.error('An error occurred while sending the message:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <MailDetailsContainer>
      {/* Top toolbar */}
      <ToolBarContainer>
        <MUIAvatar sx={{ bgcolor: 'primary.main', mr: 2 }}>
          {otherUserName ? otherUserName[0] : 'U'}
        </MUIAvatar>
        <Box>
          <Typography variant="subtitle2">
            {otherUserName || 'Unknown Participant'}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            <span>Visible to </span>
            {conversation.viewer_permissions &&
              Object.entries(conversation.viewer_permissions).map(
                ([type, users], index) => (
                  <span key={type}>
                    <em>{type}(s):</em>{' '}
                    {Array.isArray(users)
                      ? users.map((user) => user?.name || 'Unnamed User').join(', ')
                      : 'No users'}
                    {index <
                      Object.entries(conversation.viewer_permissions).length - 1 &&
                      ', '}
                  </span>
                )
              )}
          </Typography>
        </Box>
      </ToolBarContainer>

      {/* Messages list */}
      <MessagesContainer>
        {isConversationAvailable &&
          messages.map((message) => {
            let isOwnMessage = false;
            let alignStyle = 'row';
            let textAlign = 'left';

            if (category !== 'Read only') {
              // 非只读会话，按照发送者是自己或他人决定位置
              isOwnMessage = message.sender_name === myUserName;
              alignStyle = isOwnMessage ? 'row-reverse' : 'row';
              textAlign = isOwnMessage ? 'right' : 'left';
            } else {
              // 只读会话，所有消息都左对齐
              alignStyle = 'row';
              textAlign = 'left';
            }

            return (
              <Box
                key={message.message_id}
                sx={{
                  mb: 3,
                  p: 2,
                  borderBottom: '1px solid #ddd',
                  display: 'flex',
                  flexDirection: alignStyle,
                  textAlign: textAlign,
                }}
              >
                <MUIAvatar
                  sx={{
                    bgcolor: 'primary.main',
                    ml: isOwnMessage ? 2 : 0,
                    mr: isOwnMessage ? 0 : 2,
                  }}
                >
                  {message.sender_name ? message.sender_name[0] : 'U'}
                </MUIAvatar>
                <Box>
                  <Typography variant="subtitle2">
                    {message.sender_name || 'Unknown Sender'}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {format(new Date(message.timestamp), 'dd/MM/yyyy hh:mm a')}
                  </Typography>
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {message.content || message.content_plain || 'No content'}
                  </ReactMarkdown>
                </Box>
              </Box>
            );
          })}
        <div ref={messagesEndRef} />
      </MessagesContainer>

      {/* Reply input */}
      {category !== 'Read only' && (
        <ReplyContainer>
          <ReactQuill
            value={replyContent}
            onChange={setReplyContent}
            modules={quillModules}
            placeholder="Write something awesome..."
            style={{ height: '100%' }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
            <Button
              variant="contained"
              color="primary"
              endIcon={<Icon icon="mdi:send" width={24} height={24} />}
              onClick={handleSendReply}
            >
              Send
            </Button>
          </Box>
        </ReplyContainer>
      )}
    </MailDetailsContainer>
  );
};

MailDetails.propTypes = {
  conversation: PropTypes.shape({
    conversation_id: PropTypes.number.isRequired,
    participant_name: PropTypes.string,
    participants: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    participant_id: PropTypes.number,
    viewer_permissions: PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        })
      )
    ),
    send: PropTypes.arrayOf(
      PropTypes.shape({
        message_id: PropTypes.number.isRequired,
        subject: PropTypes.string.isRequired,
        content: PropTypes.string,
        content_plain: PropTypes.string,
        timestamp: PropTypes.string.isRequired,
        read_status: PropTypes.number.isRequired,
      })
    ),
    receive: PropTypes.arrayOf(
      PropTypes.shape({
        message_id: PropTypes.number.isRequired,
        subject: PropTypes.string.isRequired,
        content: PropTypes.string,
        content_plain: PropTypes.string,
        timestamp: PropTypes.string.isRequired,
        read_status: PropTypes.number.isRequired,
      })
    ),
    messages_by_sender_type: PropTypes.object,
    category: PropTypes.string,
  }).isRequired,
  onMessageSent: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default MailDetails;