import { useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Avatar,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import CircleIcon from '@mui/icons-material/Circle';
import { MessageReadStatusUpdate } from '../../api/user';

const DEEP_BLUE = '#1A73E8';

const MailList = ({
  category,
  messages = [],
  onMailSelect,
  onOpenSidebar,
  selectedConversation,
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  // 动态生成用户组图标样式
  const getUserGroupIcon = (category) => {
    const letter = category.charAt(0).toUpperCase();
    return { color: DEEP_BLUE, letter };
  };

  // 组合所有消息以进行搜索
  const getAllMessages = (conversation) => {
    let allMessages = [];

    if (conversation.send && conversation.receive) {
      // 现有结构
      allMessages = [...conversation.send, ...conversation.receive];
    } else if (conversation.messages_by_sender_type) {
      // 对于“只读”类别
      Object.values(conversation.messages_by_sender_type).forEach((msgs) => {
        allMessages.push(...msgs);
      });
    }

    return allMessages;
  };

  // 根据搜索查询过滤会话
  const filteredMessages = messages.filter((conversation) => {
    const participantName = conversation.participant_name || 'Unnamed Conversation';

    // 对于“只读”类别，participants 是一个数组
    if (category === 'Read only' && conversation.participants) {
      const participantNames = conversation.participants
        .map((participant) => participant.name)
        .join(' ');
      return participantNames.toLowerCase().includes(searchQuery.toLowerCase());
    }

    return participantName.toLowerCase().includes(searchQuery.toLowerCase());
  });

  // 格式化 viewer_permissions
  const formatViewerPermissions = (viewerPermissions) => {
    const typeCounts = {};
    Object.entries(viewerPermissions).forEach(([type, viewers]) => {
      typeCounts[type] = viewers.length;
    });

    const viewerTypes = [];
    Object.entries(typeCounts).forEach(([type, count]) => {
      viewerTypes.push(`${count} ${type}(s)`);
    });

    return viewerTypes.length > 0 ? `Visible to: ${viewerTypes.join(', ')}` : 'No viewers';
  };

  // 设置已读状态
  const handleMailSelect = async (conversation) => {
    // 获取会话的类别，或者使用组件的类别属性
    const conversationCategory = conversation.category || category;

    if (conversationCategory !== 'Read only') {
      const allMessages = getAllMessages(conversation);
  
      // 获取由对方发送且未读的消息ID
      const messageIds = allMessages
        .filter(
          (msg) =>
            !msg.read_status && msg.sender_type !== conversationCategory // 对方发送的消息
        )
        .map((msg) => msg.message_id);
  
      if (messageIds.length > 0) {
        try {
          await MessageReadStatusUpdate({ messageIds });
          allMessages.forEach((msg) => {
            if (messageIds.includes(msg.message_id)) {
              msg.read_status = true;
            }
          });
        } catch (error) {
          console.error('Failed to mark messages as read:', error);
        }
      } else {
        console.log('No messages to update');
      }
    }
  
    onMailSelect({ ...conversation, category });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        {category} Messages
      </Typography>

      <TextField
        variant="outlined"
        placeholder="Search..."
        fullWidth
        margin="normal"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      <List>
        {filteredMessages.length > 0 ? (
          filteredMessages.map((conversation, index) => {
            const userGroup = getUserGroupIcon(category);

            // 获取所有消息以检查未读状态
            const allMessages = getAllMessages(conversation);

            // 确定会话是否有未读消息
            const hasUnread = allMessages.some((msg) => !msg.read_status);

            // 检查此会话是否是选中的会话
            const isSelected =
              selectedConversation &&
              selectedConversation.conversation_id === conversation.conversation_id &&
              selectedConversation.category === category;

            // 获取参与者姓名和类型
            let participantDisplayName = '';
            if (category === 'Read only' && conversation.participants) {
              participantDisplayName = conversation.participants
                .map((participant) => {
                  return (
                    <span key={participant.id}>
                      <em style={{ fontWeight: 'bold' }}>{participant.type}</em>{' '}
                      {participant.name}
                    </span>
                  );
                })
                .reduce((prev, curr) => [prev, ', ', curr]);
            } else {
              participantDisplayName = conversation.participant_name;
            }

            return (
              <ListItem
                key={index}
                button
                onClick={() => handleMailSelect(conversation)}
                selected={isSelected}
              >
                <Avatar sx={{ bgcolor: userGroup.color, marginRight: 2 }}>
                  {userGroup.letter}
                </Avatar>

                <ListItemText
                  primary={
                    <Typography variant="body1" fontWeight={isSelected ? 'bold' : 'normal'}>
                      {participantDisplayName}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="textSecondary">
                      {formatViewerPermissions(conversation.viewer_permissions)}
                    </Typography>
                  }
                />

                {hasUnread && (
                  <CircleIcon sx={{ color: '#00BFFF', fontSize: 10, marginLeft: 'auto' }} />
                )}
              </ListItem>
            );
          })
        ) : (
          <Typography variant="body1" color="textSecondary">
            No messages in this category.
          </Typography>
        )}
      </List>
    </Box>
  );
};

MailList.propTypes = {
  category: PropTypes.string.isRequired,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      conversation_id: PropTypes.number,
      participant_name: PropTypes.string,
      participants: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          id: PropTypes.number,
          name: PropTypes.string,
        })
      ),
      send: PropTypes.arrayOf(
        PropTypes.shape({
          message_id: PropTypes.number.isRequired,
          read_status: PropTypes.bool,
          sender_type: PropTypes.string,
        })
      ),
      receive: PropTypes.arrayOf(
        PropTypes.shape({
          message_id: PropTypes.number.isRequired,
          read_status: PropTypes.bool,
          sender_type: PropTypes.string,
        })
      ),
      messages_by_sender_type: PropTypes.object,
      viewer_permissions: PropTypes.object,
    })
  ).isRequired,
  onMailSelect: PropTypes.func.isRequired,
  onOpenSidebar: PropTypes.func.isRequired,
  selectedConversation: PropTypes.object,
};

export default MailList;
