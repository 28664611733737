import PropTypes from 'prop-types';
import { Box, List, Typography, ListItemButton, ListItemIcon, ListItemText, Button, Badge } from '@mui/material';
import ScienceIcon from '@mui/icons-material/Science'; // 保留之前的图标
import CreateIcon from '@mui/icons-material/Create'; // 新按钮的图标
import { useNavigate } from 'react-router-dom';
import { readLoginData } from '../../loginData'; // 假设用来获取用户类型

const MailSidebar = ({ labels, onSelectCategory, selectedCategory }) => {
  const navigate = useNavigate();
  const userInfo = readLoginData(); // 获取用户登录信息

  // 点击 "Send New Message" 按钮跳转逻辑
  const handleSendNewMessage = () => {
    const userType = userInfo.type; // 获取用户类型
    const targetPath = `/${userType}/sendnewmessages`; // 构造路由
    navigate(targetPath); // 跳转到对应页面
  };

  return (
    <Box sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
      {/* Send New Message 按钮 */}
      <Button
        variant="contained"
        onClick={handleSendNewMessage}
        sx={{
          backgroundColor: '#1a4fba', // 深色主题背景
          color: '#ffffff', // 白色文字
          mb: 3,
          fontWeight: 'bold',
          textTransform: 'none', // 保持原样文字
          padding: '10px 20px',
          borderRadius: '8px', // 圆角按钮
          justifyContent: 'flex-start',
          '&:hover': {
            backgroundColor: '#455a64', // 鼠标悬浮时的颜色
          },
        }}
        startIcon={<CreateIcon />} // 按钮图标
      >
        Send New Message
      </Button>

      {/* 分类列表 */}
      <List>
        {Object.entries(labels).map(([label, messages]) => (
          <ListItemButton
            key={label}
            onClick={() => onSelectCategory(label)}
            selected={label === selectedCategory}
            sx={{
              mb: 1,
              borderRadius: '4px', // 圆角
            }}
          >
            <ListItemIcon>
              <ScienceIcon /> {/* 使用之前的图标 */}
            </ListItemIcon>
            <ListItemText
              primary={label}
              primaryTypographyProps={{
                fontWeight: label === selectedCategory ? 'bold' : 'normal',
              }}
            />
            <Badge color="primary" badgeContent={messages.length} />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
};

MailSidebar.propTypes = {
  labels: PropTypes.object.isRequired,
  onSelectCategory: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string,
};

export default MailSidebar;
