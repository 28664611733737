import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VisitHistory from "./VisitHistory.jsx";
import Referrals from "./Referrals.jsx";
import Prescriptions from "./Prescriptions.jsx";
import MedicalTest from "./MedicalTest.jsx";

const PatientDetailsModal = ({ open, onClose, patientId, doctorId }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm" // Sets max width to 'sm', you can adjust this to 'xs' or a custom size
      fullWidth={false} // Remove fullWidth to adhere to maxWidth setting
      PaperProps={{
        style: {
          borderRadius: 10,
          position: "relative",
          width: "80%", // Adjust width to make the dialog narrower
          height: "calc(100% - 40px)",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          paddingTop: 6,
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        <Typography
          variant="h5"
          component="div"
          sx={{
            flexGrow: 1,
            fontSize: "2rem",
            fontWeight: 600,
            textAlign: "left",
          }}
        >
          Records Hub
        </Typography>
        <IconButton
          onClick={onClose}
          sx={{
            backgroundColor: "#e0e0e0",
            color: "#555",
            width: 32,
            height: 32,
            "&:hover": {
              backgroundColor: "#f0f0f0",
            },
          }}
        >
          <CloseIcon style={{ fontSize: 18 }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <VisitHistory doctorId={doctorId} patientId={patientId} />
        <Referrals patientId={patientId} />
        <Prescriptions patientId={patientId} />
        <MedicalTest patientId={patientId} />
      </DialogContent>
    </Dialog>
  );
};

export default PatientDetailsModal;