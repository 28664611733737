import { Box, useTheme } from "@mui/material";
import Header from "../dashboard/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";

const FAQ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Header title="FAQ" subtitle="Frequently Asked Questions Page" />

      <Accordion defaultExpanded>
  <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
    <Typography color={colors.greenAccent[500]} variant="h6">
      How do I log in to the Pharmacist Admin Dashboard?
    </Typography>
  </AccordionSummary>
  <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
    <Typography>
      You can log in by entering your username and password on the login page. If you don’t have login credentials, contact your system administrator to create an account.
    </Typography>
  </AccordionDetails>
</Accordion>

<Accordion defaultExpanded>
  <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
    <Typography color={colors.greenAccent[500]} variant="h6">
      How do I view prescriptions?
    </Typography>
  </AccordionSummary>
  <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
    <Typography>
      Click the "View Prescriptions" button to display a list of prescriptions.
    </Typography>
  </AccordionDetails>
</Accordion>

<Accordion defaultExpanded>
  <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
    <Typography color={colors.greenAccent[500]} variant="h6">
      Can I search for specific prescriptions or patients?
    </Typography>
  </AccordionSummary>
  <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
    <Typography>
      Yes, use the search functionality to find prescriptions by name or ID.
    </Typography>
  </AccordionDetails>
</Accordion>

<Accordion defaultExpanded>
  <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
    <Typography color={colors.greenAccent[500]} variant="h6">
      Can I track my progress with prescriptions?
    </Typography>
  </AccordionSummary>
  <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
    <Typography>
      Yes, the dashboard tracks prescription statuses in real-time.
    </Typography>
  </AccordionDetails>
</Accordion>

<Accordion defaultExpanded>
  <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
    <Typography color={colors.greenAccent[500]} variant="h6">
      What do I do if I cannot see a prescription?
    </Typography>
  </AccordionSummary>
  <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
    <Typography>
      Refresh the page or check your access privileges; contact support if needed.
    </Typography>
  </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FAQ;
