import { Link, useLocation } from "react-router-dom";
import { Breadcrumb, Layout, Space } from "antd";
import Notifications from './notifications';  // 导入 Notifications 组件
import "./Header.css";

const breadcrumbNameMap = {
  "/Admin/dashboard": "Dashboard",
  "/Admin/profile": "Profile",
  "/Admin/messages": "Messages",
  "/Admin/sendnewmessage": "sendnewmessage",
  "/Admin/management": "Management",
  "/Admin/help": "Help",

  "/doctor/dashboard": "Dashboard",
  "/doctor/profile": "Profile",
  "/doctor/patientpage": "Patients",
  "/doctor/patient-details-zhiyu": "Patient Details",
  "/doctor/messages": "Messages",
  "/doctor/sendnewmessage": "sendnewmessage",
  "/doctor/calendar": "Calendar",
  "/doctor/services": "Planning",
  "/TasksList": "Task List",
  "/calendar/list": "Appointment List",

  "/patient/planning": "Planning",
  "/patient/dashboard": "Dashboard",
  "/patient/calendar": "Calendar",
  "/patient/report": "Report",

  "/ClinicalStaff/dashboard": "Dashboard",
  "/ClinicalStaff/calendar": "Calendar",
  "/ClinicalStaff/management": "Management",
  "/patient/profile": "Profile",
  "/patient/doctorpage": "Doctors",
  "/patient/help": "Help",
  "/patient/messages": "Messages",
  "/patient/sendnewmessage": "sendnewmessage",

  "/pharmaceuticals/dashboard": "Dashboard",
  "/pharmaceuticals/clinicaltrial": "Clinical Trial",
  "/pharmaceuticals/clinicaltrialadd": "Clinical Trial Add",
  "/pharmaceuticals/messages": "messages",
  "/pharmaceuticals/sendnewmessage": "sendnewmessage",
  "/pharmaceuticals/management": "Management",
  "/pharmaceuticals/help": "Help",
};

// 面包屑导航组件
const HeaderBreadcrumb = (props) => {
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    if (breadcrumbNameMap[url]) {
      return (
        <Breadcrumb.Item key={url}>
          <Link style={{ fontFamily: "Poppins", color: "#6E7191" }} to={url}>
            {breadcrumbNameMap[url]}
          </Link>
        </Breadcrumb.Item>
      );
    } else {
      return <></>;
    }
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link style={{ fontFamily: "Poppins", color: "#B1B5C5" }} to="/">
        Pages
      </Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <Breadcrumb
      className="doctor-header-breadcrumb"
      style={{ display: "flex", alignItems: "center", backgroundColor: 'transparent' }}
    >
      {breadcrumbItems}
    </Breadcrumb>
  );
};

// Header 组件
const Header = (props) => {
  return (
    <Layout.Header
      style={{ backgroundColor: "inherit", padding: "0", marginBottom: "2rem" }}
    >
      <Space
        style={{
          marginTop: "1rem",
          marginLeft: "1rem",
          display: "flex",
          justifyContent: "space-between", // 左右分布，Breadcrumb在左，通知图标在右
          alignItems: "center",
          width: "100%"  // 占据整个宽度
        }}
      >
        <HeaderBreadcrumb />  {/* 面包屑导航 */}
        <div style={{ marginLeft: "auto" }}> {/* 确保通知图标靠右 */}
          <Notifications /> {/* 引用通知图标组件 */}
        </div>
      </Space>
    </Layout.Header>
  );
};

export default Header;
