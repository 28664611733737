import React, { useState } from 'react';
import './PaymentMethodModal.css'; // CSS for styling

function PaymentMethodModal({ isOpen, onClose }) {
    const [selectedMethod, setSelectedMethod] = useState('Mastercard'); // Default selected method

    const handleMethodSelect = (method) => {
        setSelectedMethod(method);
    };

    // Conditional rendering AFTER hooks are called
    if (!isOpen) return null;

    return (
        <div className="e-pharmacy-paymentModalOverlay">
            <div className="e-pharmacy-paymentModalContent">
                <h2>Select a Payment Method</h2>
                <div className="e-pharmacy-payment-options">
                    {['VISA', 'Mastercard', 'Paypal', 'AMEX'].map((method) => (
                        <div
                            key={method}
                            className={`option ${selectedMethod === method ? 'e-pharmacy-selected' : ''}`}
                            onClick={() => handleMethodSelect(method)} // Allows selection of any method
                        >
                            <img
                                src={`/images/specialities/${method.toLowerCase()}.png`} // Correct image path
                                alt={method}
                                className="payment-method-image" // Added class for styling
                            />
                            <span>{method}</span> {/* This should render only once */}
                        </div>
                    ))}
                </div>
                <button onClick={onClose}>Proceed</button>
            </div>
        </div>
    );
}

export default PaymentMethodModal;
