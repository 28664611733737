// HealthOverviewComponent.jsx
import React, { useState,useEffect } from "react";
import ReactECharts from "echarts-for-react"; 
import axios from 'axios'
import { BASE_URL } from "../../../constants.js";
import { PATIENT_DISEASE_ANALYTICS_URL } from "../../../constants.js";
import { Typography, Box, Tabs, Tab, Select, MenuItem, FormControl, Grid,InputLabel } from "@mui/material";
import {getPatientPeriodicMeasurements} from "../../../api/user.js"

// Main HealthOverview Component
const HealthOverview = ({ patientId, doctorId, profileData }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [chartData, setChartData] = useState({});
  const [selectedMeasurement, setSelectedMeasurement] = useState("");
  const [imageSrc, setImageSrc] = useState(null);  //for bloodSugarLevel

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };


  
  const transformPatientData = (lineData) => {
    const result = {};
  
    lineData.forEach(entry => {
      const measurementType = entry.measurement_type;
      const value = entry.value;
      const unit = entry.unit;
  
      // 获取所有的月份
      const frequency = Object.keys(value);
  
      // 初始化每个数据类型对应的空数组
      if (!result[measurementType]) {
        result[measurementType] = {
          frequency: frequency,
          data: [],  // 存储拆分后的数据
          unit: unit
        };
      }
  
      // 处理数据：检查是否包含 '/' 并拆分
      const data = Object.values(value).map(item => {
        if (typeof item === 'string' && item.includes('/')) {
          return item.split('/').map(val => parseFloat(val));  // 拆分并转换为浮动类型
        }
        return [parseFloat(item)];  // 如果没有 '/', 直接转换为数组
      });
  
      // 将拆分后的数据平行存储到 result[measurementType].data 中
      if (result[measurementType].data.length === 0) {
        // 初始化 data 数组的每个部分
        result[measurementType].data = Array(data[0].length).fill([]).map(() => []);
      }
  
      // 将每个月的数据拆分并放到相应的数组中
      data.forEach((monthData, index) => {
        monthData.forEach((val, subIndex) => {
          result[measurementType].data[subIndex].push(val);
        });
      });
    });
  
    return result;
  };
  
 // 生成图表配置的函数
const generateChartOption = (measurementType, data, frequency, unit) => {
  const series = data.map((lineData, index) => ({
    name: `${measurementType} ${index + 1}`, // 线条名称
    type: "line", // 使用折线图
    data: lineData, // 数据
    itemStyle: { color: index === 0 ? "#5B8FF9" : "#5AD8A6" }, // 线条颜色
    areaStyle: {
      color: index === 0 ? "rgba(91, 143, 249, 0.3)" : "rgba(90, 240, 120, 0.3)", // 区域填充颜色
    },
    label: {
      show: true,
      position: "top", // 标签位置
      formatter: (params) => `${params.value} ${unit}`, // 标签内容（值 + 单位）
    },
    lineStyle: {
      width: 2, // 设置线条宽度
      type: "solid", // 使用实线
    },
    symbol: "circle", // 数据点使用圆形标记
    symbolSize: 6, // 数据点大小
  }));

  return {
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: series.map(s => s.name),
    },
    xAxis: {
      type: "category",
      boundaryGap: 0.1,
      data: frequency, // 横轴数据为月份
    },
    yAxis: {
      type: "value",
      name: unit, // Y轴单位
    },
    series: series, // 系列数据
  };
}; 

const bloodSugarReport = async () => {
  try {
      const response = await axios.get(`${PATIENT_DISEASE_ANALYTICS_URL}/patientSugarLevel/patient/${patientId}/monthlySugarReport`, { responseType: 'blob' })
      // const response = await axios.get(`http://127.0.0.1:8000/patientSugarLevel/patient/${patientId}/monthlySugarReport`, { responseType: 'blob' })
      if (response.status === 200) {
        const imageURL = URL.createObjectURL(response.data);
        setImageSrc(imageURL);
      }
  } catch (error) {
    console.error("Error fetching blood sugar report:", error)
  }
}

  useEffect(() => {
    const fetchData  = async () => {
      try {
        const PatientPeriodicMeasurements = await getPatientPeriodicMeasurements({ patientId });
        const transformedData = transformPatientData(PatientPeriodicMeasurements);
        setChartData(transformedData);
        if (Object.keys(transformedData).length > 0) {
          setSelectedMeasurement(Object.keys(transformedData)[0]); // 默认选择第一个测量类型
        }
      } catch (error) {
        console.error('Error fetching Patient Periodic Measurements data:', error);
      }
    };
    fetchData ();
  }, []);

  useEffect(() => {
    if (selectedMeasurement === "Blood Sugar Level") {
      bloodSugarReport();
    } else {
      setImageSrc(null); // Reset the image if other measurements are selected
    }
  }, [selectedMeasurement]);

  const handleMeasurementChange = (event) => {
    setSelectedMeasurement(event.target.value);
  };

  const measurement = chartData[selectedMeasurement];
  const chartOption = measurement
    ? generateChartOption(selectedMeasurement, measurement.data, measurement.frequency, measurement.unit)
    : {};
    
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: "1em" }}>
        <Typography sx={{ fontWeight: "500", fontSize: "1.5em", color: "#4F4E4E" }}>Health Overview</Typography>
      </Box>

      <Box sx={{ mb: 1 }}>
        <Grid container spacing={3} sx={{ color: "#85A5C2" }}>
          <Grid item xs={3}>
            <Typography variant="subtitle1" fontWeight="bold">Pathology:</Typography>
            <Typography variant="body2">{profileData?.pathology?.pathology}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1" fontWeight="bold">Surgeries:</Typography>
            <Typography variant="body2">{profileData?.pathology?.surgeries}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1" fontWeight="bold">Pregnancies:</Typography>
            <Typography variant="body2">
              {profileData?.pathology?.pregnancies === 0
                ? "No"
                : profileData?.pathology?.pregnancies === 1
                ? "Yes"
                : profileData?.pathology?.pregnancies}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle1" fontWeight="bold">Prior Medication:</Typography>
            <Typography variant="body2">{profileData?.pathology?.prior_medication}</Typography>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mb: 1 }}>
        <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
          {profileData?.treatments?.map((_, index) => (
            <Tab label={`TREATMENT ${index + 1}`} key={index} sx={{ alignItems: "flex-start" }} />
          ))}
        </Tabs>
        {profileData?.treatments?.map((treatment, index) => (
          <Box key={index} sx={{ display: selectedTab === index ? "block" : "none", color: "#6C757D", paddingTop: 1 }}>
            <Typography variant="body2">
              <strong>Doctor:</strong> {treatment.doctor_name} | <strong>Date:</strong> {new Date(treatment.RecordDate).toLocaleDateString()}
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              <strong>Treatment:</strong> {treatment.treatment}
            </Typography>
          </Box>
        ))}
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <Typography sx={{ fontWeight: "500", fontSize: "0.9rem", color: "#4F4E4E", mr: "1em" }}>
          Choose a type of data to display the trend:
        </Typography>
        <FormControl sx={{ minWidth: 135 }} size="small">
        <InputLabel id="measurement-select-label">Measurement</InputLabel>
        <Select
          labelId="measurement-select-label"
          value={selectedMeasurement}
          onChange={handleMeasurementChange}
          label="Measurement"
        >
          <MenuItem value="Blood Sugar Level">Blood Sugar Level</MenuItem>

          {Object.keys(chartData).map((measurementType) => (
            <MenuItem key={measurementType} value={measurementType}>
              {measurementType}
            </MenuItem>
          ))}
        </Select>
        </FormControl>
      </Box>

      {/* Use selectedMeasurement as buttonOnClick */}
      <Box >
        {selectedMeasurement === "Blood Sugar Level" && imageSrc ? (  
          <img src={imageSrc} alt={`Monthly Sugar Report for Patient ${patientId}`} style={{ height: "400px", width: "100%" }} />
        ) : (
          <ReactECharts
            option={chartOption}
            style={{ height: "400px", width: "100%" }}
            key={selectedMeasurement}
          />
        )}
      </Box>
    </>
  );
};

export default HealthOverview;
