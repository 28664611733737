import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const SkinCancerUpload = () => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      alert("Please select an image file.");
      return;
    }

    const formData = new FormData();
    formData.append("pic", selectedFile);

    try {
      setIsLoading(true); // 开始加载

      const response = await axios.post(
        "https://skincancerdetection-e62b1d5bb204.herokuapp.com/showresult",
        formData
        // {
        //   headers: {
        //     "Content-Type": "multipart/form-data",
        //   },
        // }
      );


      if (response.status === 200) {
        const data = response.data;
        console.log("Response data:", data);
        navigate("/skin-cancer-result", {
          state: { result: data.Result, info: data.Info },
        });
      } else {
        alert("Failed to get the result.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while processing the image.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "black",
        color: "white",
        textAlign: "center",
        minHeight: "100vh",
        paddingTop: "100px",
      }}
    >
      <h1 style={{ margin: "0", color: "white", marginBottom: "20px" }}>
        Skin Cancer Detection
      </h1>
      <h1 style={{ margin: "0", color: "white" }}>
        using Convolutional Neural Network
      </h1>
      <br />
      <br />
      <h3>Select Skin image here</h3>
      <br />
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <input
            type="file"
            name="pic"
            accept="image/*"
            onChange={handleFileChange}
            style={{
              marginBottom: "20px",
              padding: "10px",
              backgroundColor: "#333",
              color: "white",
              border: "none",
            }}
          />
          {/* {previewImage && (
            <img
              src={previewImage}
              alt="Preview"
              style={{ maxWidth: "300px", marginBottom: "20px" }}
            />
          )} */}
          <button
            type="submit"
            style={{
              padding: "10px 20px",
              backgroundColor: "green",
              color: "white",
              border: "none",
              cursor: "pointer",
            }}
            disabled={isLoading}
          >
            {isLoading ? "Processing..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SkinCancerUpload;
