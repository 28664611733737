import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";

const cartLocalData = localStorage.getItem("cartitems");
const arrayCart = JSON.parse(cartLocalData) || [];

const cartSlice = createSlice({
  name: "cart",
  initialState: arrayCart,
  reducers: {
    addToCart(state, action) {
      const findProduct = state.find((product) => product.id === action.payload.id);
      if (findProduct) {
        findProduct.quantity++;
        toast.success("Quantity increased!", {
          duration: 2000,
          className: "custom-class-toast",
        });
      } else {
        const newProduct = { ...action.payload, quantity: 1 };
        state.push(newProduct);
        toast.success("Product added to cart!", {
          duration: 2000,
          className: "custom-class-toast",
        });
      }
      localStorage.setItem("cartitems", JSON.stringify(state)); // Persist cart data
    },
    decreaseFromCart(state, action) {
      const findProduct = state.find((product) => product.id === action.payload.id);
      if (findProduct && findProduct.quantity > 1) {
        findProduct.quantity--;
      } else {
        const index = state.findIndex((product) => product.id === action.payload.id);
        state.splice(index, 1);
        toast.error("Product removed from cart!", {
          duration: 2000,
          className: "custom-class-toast",
        });
      }
      localStorage.setItem("cartitems", JSON.stringify(state)); // Persist cart data
    },
    removeFromCart(state, action) {
      const index = state.findIndex((product) => product.id === action.payload.id);
      state.splice(index, 1);
      toast.error("Product removed from cart!", {
        duration: 2000,
        className: "custom-class-toast",
      });
      localStorage.setItem("cartitems", JSON.stringify(state)); // Persist cart data
    },
  },
});

export const { addToCart, decreaseFromCart, removeFromCart } = cartSlice.actions;
export default cartSlice.reducer;
