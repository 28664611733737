import React, { useState, useEffect } from "react";
import axios from "axios";
import { AppBar, Tabs, Tab, CircularProgress } from "@mui/material";
import { PATIENT_DISEASE_ANALYTICS_URL } from "../../constants.js";

const DiseaseAnalytics = () => {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState({
    general: {},
    lifestyle: {},
    personalized: {},
    lungCancer: {},
  });

  const imageEndpoints = {
    general: [
      `${PATIENT_DISEASE_ANALYTICS_URL}/heartDisease/countDiseases`,
      `${PATIENT_DISEASE_ANALYTICS_URL}/heartDisease/correlationHeatmap`,
      `${PATIENT_DISEASE_ANALYTICS_URL}/heartDisease/diabeticHeart`,
      `${PATIENT_DISEASE_ANALYTICS_URL}/heartDisease/strokeHeart`,

      // 'http://127.0.0.1:8000/heartDisease/countDiseases',
      // 'http://127.0.0.1:8000/heartDisease/correlationHeatmap',
      // 'http://127.0.0.1:8000/heartDisease/diabeticHeart',
      // 'http://127.0.0.1:8000/heartDisease/strokeHeart'
    ],
    lifestyle: [
      `${PATIENT_DISEASE_ANALYTICS_URL}/factorsOfHeartDiseases/bmi-Vs-Heart`,
      `${PATIENT_DISEASE_ANALYTICS_URL}/factorsOfHeartDiseases/smokingHeart`,
      `${PATIENT_DISEASE_ANALYTICS_URL}/factorsOfHeartDiseases/alcoholHeart`,
      `${PATIENT_DISEASE_ANALYTICS_URL}/factorsOfHeartDiseases/physicalActivity-Sleep-HealthyHeart`,
      `${PATIENT_DISEASE_ANALYTICS_URL}/factorsOfHeartDiseases/sleepVsHeart-modified`,
      `${PATIENT_DISEASE_ANALYTICS_URL}/factorsOfHeartDiseases/generalHealth-Heart`,
      `${PATIENT_DISEASE_ANALYTICS_URL}/factorsOfHeartDiseases/physicalActivity-HeartDiseases`,

      // 'http://127.0.0.1:8000/factorsOfHeartDiseases/bmi-Vs-Heart',
      // 'http://127.0.0.1:8000/factorsOfHeartDiseases/smokingHeart',
      // 'http://127.0.0.1:8000/factorsOfHeartDiseases/alcoholHeart',
      // 'http://127.0.0.1:8000/factorsOfHeartDiseases/physicalActivity-Sleep-HealthyHeart',
      // 'http://127.0.0.1:8000/factorsOfHeartDiseases/sleepVsHeart-modified',
      // 'http://127.0.0.1:8000/factorsOfHeartDiseases/generalHealth-Heart',
      // 'http://127.0.0.1:8000/factorsOfHeartDiseases/physicalActivity-HeartDiseases',
      // 'http://127.0.0.1:8000/factorsOfHeartDiseases/getHeart_disease_analysis'
    ],
    personalized: [
      `${PATIENT_DISEASE_ANALYTICS_URL}/factorsOfHeartDiseases/ageVsDisease`,
      `${PATIENT_DISEASE_ANALYTICS_URL}/factorsOfHeartDiseases/bmiVsHeart`,
      `${PATIENT_DISEASE_ANALYTICS_URL}/factorsOfHeartDiseases/sexVsHeart`,

      // 'http://127.0.0.1:8000/factorsOfHeartDiseases/ageVsDisease',
      // 'http://127.0.0.1:8000/factorsOfHeartDiseases/bmiVsHeart',
      // 'http://127.0.0.1:8000/factorsOfHeartDiseases/sexVsHeart'
    ],
    lungCancer: [
      `${PATIENT_DISEASE_ANALYTICS_URL}/lungCancer/ChronicDiseaseAndAllergyWithAndWithoutLungCancer`,
      `${PATIENT_DISEASE_ANALYTICS_URL}/lungCancer/Correlation_Between_Symptoms_And_Lung_Cancer_Diagnosis`,
      `${PATIENT_DISEASE_ANALYTICS_URL}/lungCancer/Lung_Cancer_Gender_Distribution`,
      `${PATIENT_DISEASE_ANALYTICS_URL}/lungCancer/smoking_non_smoking_gender_age`,
      `${PATIENT_DISEASE_ANALYTICS_URL}/lungCancer/lung_cancer_diagnosis_smoking_status`,
      `${PATIENT_DISEASE_ANALYTICS_URL}/lungCancer/Prevalence_Rates_Symptoms_Lung_Cancer_Patients`,

      // 'http://127.0.0.1:8000/lungCancer/ChronicDiseaseAndAllergyWithAndWithoutLungCancer',
      // 'http://127.0.0.1:8000/lungCancer/Correlation_Between_Symptoms_And_Lung_Cancer_Diagnosis',
      // 'http://127.0.0.1:8000/lungCancer/Lung_Cancer_Gender_Distribution',
      // 'http://127.0.0.1:8000/lungCancer/smoking_non_smoking_gender_age',
      // 'http://127.0.0.1:8000/lungCancer/lung_cancer_diagnosis_smoking_status',
      // 'http://127.0.0.1:8000/lungCancer/Prevalence_Rates_Symptoms_Lung_Cancer_Patients'
    ],
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const loadImages = async () => {
      setLoading(true);
      const currentTab = ["general", "lifestyle", "personalized", "lungCancer"][
        value
      ]; // Active tab

      if (Object.keys(links[currentTab]).length > 0) {
        setLoading(false);
        return;
      }

      const newLinks = { ...links }; // Create a copy of links state to update
      const currentEndpoints = imageEndpoints[currentTab];

      for (const endpoint of currentEndpoints) {
        try {
          const response = await axios.get(endpoint, { responseType: "blob" });
          const blob = response.data;
          const imageURL = URL.createObjectURL(blob);
          newLinks[currentTab][endpoint.split("/").pop()] = imageURL; // Store image in state
        } catch (error) {
          console.error(`Error loading image from ${endpoint}:`, error);
        }
      }

      setLinks(newLinks);
      setLoading(false);
    };

    loadImages();
  }, [value]);

  return (
    <div>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange}>
          <Tab label="General" />
          <Tab label="Lifestyle" />
          <Tab label="Personalized" />
          <Tab label="LungCancer" />
        </Tabs>
      </AppBar>

      <div className="container-fluid">
        {loading ? (
          <CircularProgress />
        ) : (
          <div className="d-flex flex-wrap gap-3">
            {Object.entries(
              links[
                ["general", "lifestyle", "personalized", "lungCancer"][value]
              ]
            ).map(([key, url]) => (
              <img
                key={key}
                src={url}
                alt={key}
                style={{ maxWidth: "40%", height: "auto", margin: "10px" }}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DiseaseAnalytics;