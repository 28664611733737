import React, { useState } from 'react'; // Import React and useState
import { useDispatch } from 'react-redux'; // Import useDispatch from react-redux
import { Link, useNavigate } from 'react-router-dom'; // Import Link and useNavigate
import { logout } from '../../redux-store/AuthSlice'; // Import logout action
import ImgUser from "../../assets/images/admin.png"; // Import your image
import { AiOutlineUser, AiOutlineHeart, AiOutlineUnlock, AiOutlineQuestion, AiOutlineLogin } from "react-icons/ai"; // Import icons
import "./header.css"; // Import your CSS

function User() {
    const [openprofile, setopenprofile] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Initialize the useNavigate hook
    const [user, setuser] = useState(true);

    const closeprofile = () => {
        setopenprofile(false);
    };

    const Logout = () => {
        dispatch(logout()); // Dispatch the logout action (clearing Redux store or user state)
        navigate('/'); // Redirect to the homepage (or main page) after logging out
    };

    // Fetch user data (optional, depending on your project setup)
    let nameOfUser = JSON.parse(localStorage.getItem("userRegister")) || {};

    // Check if name exists before logging it
    console.log(nameOfUser.name ? nameOfUser.name : "No user found");

    return (
        <div className="e-pharmacy-user">
            <div className="e-pharmacy-profile">
                {user ? (
                    <>
                        <div className="e-pharmacy-img" onClick={() => setopenprofile(!openprofile)}>
                            <img
                                src={ImgUser}
                                alt="User Profile"
                                style={{ width: "40px", cursor: "pointer" }}
                            />
                        </div>
                        {openprofile && (
                            <div className="e-pharmacy-box__profile" onClick={() => closeprofile()}>
                                <Link to="/myaccount">
                                    <div className="e-pharmacy-box-1 d-flex column-gap-3 mb-4">
                                        <img
                                            src={ImgUser}
                                            alt="User"
                                            style={{ width: "40px", cursor: "pointer" }}
                                        />
                                        <div className="e-pharmacy-who__user d-flex flex-column">
                                            <div>{nameOfUser.name ? nameOfUser.name : "Guest"}</div>
                                            <div>egypt</div>
                                        </div>
                                    </div>
                                </Link>
                                <div className="e-pharmacy-box-2">
                                    <div className="e-pharmacy-myaccount">
                                        <Link to="myaccount">
                                            <AiOutlineUser className="e-pharmacy-myaccount__icon" />
                                            <span className="e-pharmacy-myaccount__title ps-4">
                                                My Account
                                            </span>
                                        </Link>
                                    </div>
                                    <div className="e-pharmacy-myorder">
                                        <a href="">
                                            <AiOutlineUnlock className="e-pharmacy-myaccount__icon" />
                                            <span className="e-pharmacy-myaccount__title ps-4">My Order</span>
                                        </a>
                                    </div>
                                    <div className="e-pharmacy-mywishlist">
                                        <a href="">
                                            <AiOutlineHeart className="e-pharmacy-myaccount__icon" />
                                            <span className="e-pharmacy-myaccount__title ps-4">Wishlist</span>
                                        </a>
                                    </div>
                                    <div className="e-pharmacy-myhelp">
                                        <a href="">
                                            <AiOutlineQuestion className="e-pharmacy-myaccount__icon" />
                                            <span className="e-pharmacy-myaccount__title ps-4">Help</span>
                                        </a>
                                    </div>
                                    <div className="e-pharmacy-mylogout">
                                        <span>
                                            <AiOutlineLogin
                                                className="e-pharmacy-myaccount__icon"
                                                onClick={Logout} // Trigger logout and redirection
                                            />
                                            <span
                                                className="e-pharmacy-myaccount__title ps-4"
                                                onClick={Logout} // Trigger logout and redirection
                                            >
                                                Log Out
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
}

export default User;
