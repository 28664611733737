import React, { useState } from "react";
import "./CommonCard.css";
import { Header } from "./Header";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton, Collapse } from "@mui/material";

const CommonCard = ({
  children,
  collapsible = false,
  title,
  isDividingLine = true,
}) => {
  const [collapsed, setCollapsed] = useState(collapsible ? true : false);

  const handlePress = () => {
    if (collapsible) {
      setCollapsed(!collapsed);
    }
  };

  return (
    <div className="common-card">
      {title && (
        <div
          onClick={handlePress}
          className="header-container"
          style={{ cursor: collapsible ? "pointer" : "default" }}
        >
          <Header title={title} />
          {collapsible && (
            <IconButton
              size="small"
              style={{
                transform: collapsed ? "rotate(0deg)" : "rotate(180deg)",
                transition: "transform 0.3s",
              }}
            >
              <ExpandMoreIcon style={{ fontSize: 30, color: "#000000" }} />{" "}
            </IconButton>
          )}
        </div>
      )}
      <Collapse in={!collapsed} timeout="auto" unmountOnExit>
        <div className="common-card-content">{children}</div>
      </Collapse>
      {/* {isDividingLine && <div className="dividing-line" />} */}
    </div>
  );
};

export default CommonCard;
