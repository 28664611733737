import React, { useState } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  Row,
  Col,
  Divider,
  TimePicker,
  DatePicker,
  message,
} from "antd";
import { SaveOutlined, CloseOutlined } from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";

const { Title } = Typography;
const { TextArea } = Input;

const VisitForm = ({ closeModal, patientId, doctorId }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    visitDate: dayjs(),
    startTime: null,
    endTime: null,
    reasonForVisit: "",
    notes: "",
  };

  const saveVisit = async (values) => {
    const dataToSend = {
      patientId: patientId,
      doctorId: doctorId,
      visitDate: values.visitDate.format("YYYY-MM-DD"),
      startTime: values.startTime ? values.startTime.format("HH:mm") : null,
      endTime: values.endTime ? values.endTime.format("HH:mm") : null,
      reasonForVisit: values.reasonForVisit,
      notes: values.notes,
    };

    setLoading(true);
    try {
      const response = await axios.post(
        "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/saveVisit",
        dataToSend
      );
      message.success("Visit logged successfully!");
      form.resetFields();
      if (closeModal) closeModal();
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error saving visit:", error);
      message.error("Error saving visit");
    } finally {
      setLoading(false);
    }
  };

  // 重点在这里！解决弹出层和 ResizeObserver 问题
  const handleGetPopupContainer = (triggerNode) => {
    // 尝试向上查找一个有足够定位上下文的容器
    let current = triggerNode;
    while (current && current !== document.body) {
      const position = window.getComputedStyle(current).position;
      if (position === "relative" || position === "absolute") {
        return current;
      }
      current = current.parentElement;
    }
    return document.body;
  };

  return (
    <Card
      className="antd-style-form"
      style={{
        border: "0",
        maxWidth: 900,
        minWidth: 550,
        margin: "0 auto",
        position: "relative", // 重要！提供定位上下文
      }}
    >
      <Button
        className="antd-style-form-close-button"
        type="text"
        icon={<CloseOutlined className="antd-style-form-close-icon" />}
        onClick={closeModal}
      />
      <Title
        className="antd-style-form-title"
        level={3}
        style={{ textAlign: "center" }}
      >
        Log Visit
      </Title>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={saveVisit}
      >
        {/* Visit Details */}
        <Title level={4} className="antd-style-form-subtitle">
          Visit Details
        </Title>
        <Divider className="antd-style-form-divider" />
        <Form.Item
          label="Date of Visit"
          name="visitDate"
          rules={[{ required: true, message: "Please select the visit date!" }]}
        >
          <DatePicker
            className="antd-style-form-input"
            style={{ width: "100%" }}
            placeholder="Select visit date"
            getPopupContainer={handleGetPopupContainer}
          />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Start Time"
              name="startTime"
              rules={[
                { required: true, message: "Please select the start time!" },
              ]}
            >
              <TimePicker
                className="antd-style-form-input"
                format="HH:mm"
                style={{ width: "100%" }}
                placeholder="Start time"
                getPopupContainer={handleGetPopupContainer}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="End Time"
              name="endTime"
              rules={[
                { required: true, message: "Please select the end time!" },
              ]}
            >
              <TimePicker
                className="antd-style-form-input"
                format="HH:mm"
                style={{ width: "100%" }}
                placeholder="End time"
                getPopupContainer={handleGetPopupContainer}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Reason for Visit"
          name="reasonForVisit"
          rules={[
            {
              required: true,
              message: "Please input the reason for the visit!",
            },
          ]}
        >
          <TextArea className="antd-style-form-input" rows={1} />
        </Form.Item>
        <Form.Item label="Observations" name="notes">
          <TextArea className="antd-style-form-input" rows={2} />
        </Form.Item>

        <Divider className="antd-style-form-divider" />

        {/* Action Buttons */}
        <Form.Item className="antd-style-form-button-row">
          <Row justify="end" gutter={16}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
                loading={loading}
                className="antd-style-form-button"
              >
                Save Visit
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default VisitForm;
