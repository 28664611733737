import {
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
  Grid,
  Card,
  CardContent,
  CardActions,
  Modal,
  Divider,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "./Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import PharmacySidebar from "../global/PharmacySidebar.jsx"; // Import the Sidebar component
import Topbar from "../global/Topbar"; // Import the Topbar component
import { mockTransactions } from "../../data/mockData";
import { toast } from "react-toastify";
import { ThemeProvider } from "@mui/material/styles"; // Import ThemeProvider
import { tokens } from "../../theme";
import { ColorModeContext } from "../../theme";
import { readLoginData } from "../../../../loginData.js";
import BASE_URLS from "../../../DoctorNew/patient-details/constants.jsx";

const Pharmacydashboard = (props) => {
  const { setMode } = useContext(ColorModeContext); // Use the context to get setMode
  const mode = "light"; // Set mode (light or dark)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const userInfo = readLoginData();

  const [isCollapsed, setIsCollapsed] = useState(false); // Manage sidebar collapse state
  const [prescriptions, setPrescriptions] = useState([]); // State for prescriptions
  const [isModalOpen, setIsModalOpen] = useState(false); // State for Modal

  // Function to fetch prescriptions
  const fetchPrescriptions = async () => {
    try {
      const response = await axios.post(
        `${BASE_URLS.PRODUCTION}/getPrescriptionsAsPharmacist`,
        { pharmacyId: userInfo.id }
      );
      if (response.data) {
        setPrescriptions(response.data); // Set prescriptions
      } else {
        toast.error("No prescriptions available.");
      }
    } catch (error) {
      toast.error("Error fetching prescriptions.");
      console.error(error);
    }
  };

  // Handle View Prescriptions button click
  const handleViewPrescriptions = () => {
    fetchPrescriptions(); // Fetch prescriptions when button is clicked
    setIsModalOpen(true); // Open the modal
  };

  useEffect(() => {
    // Optionally, you can call fetchPrescriptions on mount if needed
    // fetchPrescriptions();
  }, []);

  return (
    <ColorModeContext.Provider value={{ mode, setMode }}>
      {" "}
      {/* Provide the color mode context */}
      <ThemeProvider theme={theme}>
        {" "}
        {/* Apply theme inside the provider */}
        <Box display="flex" height="100vh">
          {/* SIDEBAR */}
          <PharmacySidebar
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
            clearUser={props.clearUser}
          />{" "}
          {/* Pass state to Sidebar */}
          {/* MAIN CONTENT */}
          <Box m="20px" flex="1" display="flex" flexDirection="column">
            {/* TOPBAR */}
            <Topbar /> {/* Include the Topbar */}
            {/* HEADER */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt="20px"
            >
              <Header
                title="e-Hospital"
                subtitle="Smart Digital Medicine Solutions"
              />
              <Box>
                <Button
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                  }}
                  onClick={handleViewPrescriptions} // Handle button click
                >
                  <DownloadOutlinedIcon sx={{ mr: "10px" }} />
                  View Prescriptions
                </Button>
              </Box>
            </Box>
            {/* MODAL */}
            <Modal
              open={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              aria-labelledby="prescription-modal-title"
              aria-describedby="prescription-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "80%",
                  maxHeight: "80%",
                  overflowY: "auto",
                  bgcolor: colors.primary[800],
                  boxShadow: 24,
                  borderRadius: 4,
                  padding: "20px",
                }}
              >
                <Typography
                  id="prescription-modal-title"
                  variant="h5"
                  component="h2"
                  mb={3}
                  fontWeight="bold"
                  color={colors.blueAccent[500]}
                >
                  Prescription List
                </Typography>
                <Divider sx={{ marginBottom: 2 }} />
                <Grid container spacing={3}>
                  {prescriptions.length === 0 ? (
                    <Typography>No prescriptions available.</Typography>
                  ) : (
                    prescriptions.map((prescription, index) => {
                      const formattedDate = new Date(prescription.timestamp);
                      const displayDate =
                        formattedDate instanceof Date && !isNaN(formattedDate)
                          ? formattedDate.toLocaleString()
                          : "Invalid Date";

                      return (
                        <Grid item xs={12} md={6} key={index}>
                          <Card
                            sx={{
                              backgroundColor: "white",
                              borderRadius: 2,
                              boxShadow: 5,
                              "&:hover": {
                                transform: "scale(1.05)",
                                transition: "all 0.3s ease-in-out",
                                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                              },
                            }}
                          >
                            <CardContent>
                              <Typography
                                variant="h6"
                                fontWeight="bold"
                                color={colors.blueAccent[500]}
                              >
                                Prescription {index + 1}
                              </Typography>
                              <Typography
                                sx={{ mt: 2, color: colors.grey[300] }}
                              >
                                Received on:{" "}
                                {new Date(
                                  prescription.prescription_creation_time
                                ).toLocaleString()}
                              </Typography>

                              {/* Doctor's Details */}
                              {prescription.doctor_FName &&
                                prescription.doctor_LName && (
                                  <Box sx={{ mt: 2 }}>
                                    <Typography
                                      variant="body1"
                                      color={colors.grey[200]}
                                    >
                                      Doctor: {prescription.doctor_FName}{" "}
                                      {prescription.doctor_LName}
                                    </Typography>
                                    {prescription.doctor_phone && (
                                      <Typography
                                        variant="body2"
                                        color={colors.grey[400]}
                                      >
                                        Phone: {prescription.doctor_phone}
                                      </Typography>
                                    )}
                                  </Box>
                                )}

                              {/* Patient's Details */}
                              {prescription.patient_FName &&
                                prescription.patient_LName && (
                                  <Box sx={{ mt: 2 }}>
                                    <Typography
                                      variant="body1"
                                      color={colors.grey[200]}
                                    >
                                      Patient: {prescription.patient_FName}{" "}
                                      {prescription.patient_LName}
                                    </Typography>
                                    {prescription.patient_phone && (
                                      <Typography
                                        variant="body2"
                                        color={colors.grey[400]}
                                      >
                                        Phone: {prescription.patient_phone}
                                      </Typography>
                                    )}
                                  </Box>
                                )}

                              {/* Medication Details */}
                              {prescription.medicine_name && (
                                <Typography
                                  sx={{ mt: 2, fontWeight: "bold" }}
                                  color={colors.blueAccent[500]}
                                >
                                  Medication: {prescription.medicine_name}
                                </Typography>
                              )}
                              {prescription.dose && (
                                <Typography sx={{ mt: 1 }}>
                                  Dose: {prescription.dose}{" "}
                                  {prescription.dose_unit}
                                </Typography>
                              )}
                              {prescription.frequency && (
                                <Typography sx={{ mt: 1 }}>
                                  Frequency: {prescription.frequency}
                                </Typography>
                              )}
                              {prescription.route && (
                                <Typography sx={{ mt: 1 }}>
                                  Route: {prescription.route}
                                </Typography>
                              )}
                              {prescription.duration && (
                                <Typography sx={{ mt: 1 }}>
                                  Duration: {prescription.duration}
                                </Typography>
                              )}
                              {prescription.quantity && (
                                <Typography sx={{ mt: 1 }}>
                                  Quantity: {prescription.quantity}{" "}
                                  {prescription.quantity_unit}
                                </Typography>
                              )}
                              {prescription.refill !== undefined && (
                                <Typography sx={{ mt: 1 }}>
                                  Refill: {prescription.refill}
                                </Typography>
                              )}
                              {prescription.prescription_description && (
                                <Typography sx={{ mt: 1 }}>
                                  Description:{" "}
                                  {prescription.prescription_description}
                                </Typography>
                              )}
                            </CardContent>
                            <CardActions sx={{ justifyContent: "flex-end" }}>
                              <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                  borderRadius: 2,
                                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                  "&:hover": {
                                    boxShadow: "0 6px 10px rgba(0, 0, 0, 0.2)",
                                  },
                                }}
                              >
                                Mark as Completed
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>
                      );
                    })
                  )}
                </Grid>
              </Box>
            </Modal>
            {/* GRID & CHARTS */}
            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gridAutoRows="140px"
              gap="20px"
              mt="20px"
            >
              {/* ROW 1 */}
              <Box
                gridColumn="span 3"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <StatBox
                  title="12,361"
                  subtitle="Alerts & Mails"
                  progress="0.75"
                  increase="+14%"
                  icon={
                    <EmailIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
              <Box
                gridColumn="span 3"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <StatBox
                  title="431,225"
                  subtitle="Sales Obtained"
                  progress="0.50"
                  increase="+21%"
                  icon={
                    <PointOfSaleIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
              <Box
                gridColumn="span 3"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <StatBox
                  title="3,244"
                  subtitle="New Patients"
                  progress="0.30"
                  increase="+5%"
                  icon={
                    <PersonAddIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
              <Box
                gridColumn="span 3"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <StatBox
                  title="35"
                  subtitle="Pending Prescriptions"
                  progress="0.80"
                  increase="+43%"
                  icon={
                    <TrafficIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>

              {/* ROW 2 */}
              <Box
                gridColumn="span 8"
                gridRow="span 2"
                backgroundColor={colors.primary[400]}
              >
                <Box
                  mt="25px"
                  p="0 30px"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      color={colors.grey[100]}
                    >
                      Revenue Generated
                    </Typography>
                    <Typography
                      variant="h3"
                      fontWeight="bold"
                      color={colors.greenAccent[500]}
                    >
                      $59,342.32
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton>
                      <DownloadOutlinedIcon
                        sx={{
                          fontSize: "26px",
                          color: colors.greenAccent[500],
                        }}
                      />
                    </IconButton>
                  </Box>
                </Box>
                <Box height="250px" m="-20px 0 0 0">
                  <LineChart isDashboard={true} />
                </Box>
              </Box>
              <Box
                gridColumn="span 4"
                gridRow="span 2"
                backgroundColor={colors.primary[400]}
                overflow="auto"
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  p="15px"
                >
                  <Typography
                    color={colors.grey[100]}
                    variant="h5"
                    fontWeight="600"
                  >
                    Recent Transactions
                  </Typography>
                </Box>
                {mockTransactions.map((transaction, i) => (
                  <Box
                    key={`${transaction.txId}-${i}`}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    borderBottom={`4px solid ${colors.primary[500]}`}
                    p="15px"
                  >
                    <Box>
                      <Typography
                        color={colors.greenAccent[500]}
                        variant="h5"
                        fontWeight="600"
                      >
                        {transaction.txId}
                      </Typography>
                      <Typography color={colors.grey[100]}>
                        {transaction.user}
                      </Typography>
                    </Box>
                    <Box color={colors.grey[100]}>{transaction.date}</Box>
                    <Box
                      backgroundColor={colors.greenAccent[500]}
                      p="5px 10px"
                      borderRadius="4px"
                    >
                      ${transaction.cost}
                    </Box>
                  </Box>
                ))}
              </Box>

              {/* ROW 3 */}
              <Box
                gridColumn="span 4"
                gridRow="span 2"
                backgroundColor={colors.primary[400]}
                p="30px"
              >
                <Typography variant="h5" fontWeight="600">
                  Campaign
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  mt="25px"
                >
                  <ProgressCircle size="125" />
                  <Typography
                    variant="h5"
                    color={colors.greenAccent[500]}
                    sx={{ mt: "15px" }}
                  >
                    $48,352 revenue generated
                  </Typography>
                  <Typography>
                    Includes extra misc expenditures and costs
                  </Typography>
                </Box>
              </Box>
              <Box
                gridColumn="span 4"
                gridRow="span 2"
                backgroundColor={colors.primary[400]}
              >
                <Typography
                  variant="h5"
                  fontWeight="600"
                  sx={{ padding: "30px 30px 0 30px" }}
                >
                  Sales Quantity
                </Typography>
                <Box height="250px" mt="-20px">
                  <BarChart isDashboard={true} />
                </Box>
              </Box>
              <Box
                gridColumn="span 4"
                gridRow="span 2"
                backgroundColor={colors.primary[400]}
                padding="30px"
              >
                <Typography
                  variant="h5"
                  fontWeight="600"
                  sx={{ marginBottom: "15px" }}
                >
                  Geography Based Traffic
                </Typography>
                <Box height="200px">
                  <GeographyChart isDashboard={true} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default Pharmacydashboard;
