import "./header.css";
import Medicine from "../../assets/images/Medicine.png";
import { AiOutlineSearch } from "react-icons/ai";
import Card from "./Card";
import User from "./User";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

function Header(props) {
  const navigate = useNavigate();
  // Adding scroll effect for the header
  window.addEventListener("scroll", () => {
    const header = document.getElementsByClassName("e-pharmacy-header")[0];
    if (header) {
      header.classList.toggle("active", window.scrollY > 200);
    }
  });

  return (
    <div className="e-pharmacy-header">
      <div className="e-pharmacy-containerr">
        <div className="e-pharmacy-header__content">
          {/* Logo Section */}
          <div className="e-pharmacy-logo">
            <Link to="/">
              <img src={Medicine} alt="Medicine Logo" />
            </Link>
          </div>

          {/* Search Bar */}
          <div className="e-pharmacy-search d-flex align-items-center">
            <label htmlFor="focus" className="e-pharmacy-searchIcon">
              <AiOutlineSearch />
            </label>
            <input type="text" id="focus" placeholder="Search..." />
          </div>

          {/* Icons Section */}
          <div className="e-pharmacy-icons d-flex align-items-center">
            <Card />
            <User />
            <Button
              type="text"
              className="e-pharmacy-logout"
              onClick={() => {
                props.clearUser();
                navigate("/LogIn");
              }}
            >
              Log out
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
