// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e-pharmacy-paymentModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.e-pharmacy-paymentModalContent {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    text-align: center;
}

.e-pharmacy-payment-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.option {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
}

.option:hover {
    background-color: #f0f0f0;
}

.e-pharmacy-selected {
    background-color: #d3d3d3;
}

.payment-method-image {
    width: 30px; /* You can adjust the size */
    height: auto;
}

.e-pharmacy-paymentModalOverlay button {
    margin-top: 20px;
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.e-pharmacy-paymentModalOverlay button:hover {
    background-color: #45a049;
}
`, "",{"version":3,"sources":["webpack://./src/screens/E-Pharmacy/components/header/PaymentMethodModal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,eAAe;IACf,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,WAAW,EAAE,4BAA4B;IACzC,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".e-pharmacy-paymentModalOverlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n}\n\n.e-pharmacy-paymentModalContent {\n    background-color: white;\n    padding: 20px;\n    border-radius: 10px;\n    width: 300px;\n    text-align: center;\n}\n\n.e-pharmacy-payment-options {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    margin-top: 20px;\n}\n\n.option {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    cursor: pointer;\n    padding: 5px;\n    border-radius: 5px;\n}\n\n.option:hover {\n    background-color: #f0f0f0;\n}\n\n.e-pharmacy-selected {\n    background-color: #d3d3d3;\n}\n\n.payment-method-image {\n    width: 30px; /* You can adjust the size */\n    height: auto;\n}\n\n.e-pharmacy-paymentModalOverlay button {\n    margin-top: 20px;\n    padding: 10px;\n    background-color: #4caf50;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n}\n\n.e-pharmacy-paymentModalOverlay button:hover {\n    background-color: #45a049;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
