// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appointment-request-card .ant-btn {
    background-color: #0984e3 !important;
    color: white !important;
    border: none !important;
}

.time-segment-dialog .ant-btn {
    background-color: black;
    color: white;
    border: none !important;
}

.time-segment-dialog .ant-btn:hover {
    background-color: #333 !important;
    color: white !important;
    border: none !important;
}`, "",{"version":3,"sources":["webpack://./src/screens/DoctorNew/TimeSegmentDialog.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,uBAAuB;IACvB,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,iCAAiC;IACjC,uBAAuB;IACvB,uBAAuB;AAC3B","sourcesContent":[".appointment-request-card .ant-btn {\n    background-color: #0984e3 !important;\n    color: white !important;\n    border: none !important;\n}\n\n.time-segment-dialog .ant-btn {\n    background-color: black;\n    color: white;\n    border: none !important;\n}\n\n.time-segment-dialog .ant-btn:hover {\n    background-color: #333 !important;\n    color: white !important;\n    border: none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
