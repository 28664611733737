import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../styles/screens/Search.css";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import "./searchresult.css";

function Searchresult() {
  const navigate = useNavigate();
  const location = useLocation();
  let dataskin = {};
  dataskin = location.state;
  console.log(dataskin);
  const goCovidPred = () => navigate("/CovidPred", { state: dataskin });

  const goGlaucomaDetection = () =>
    navigate("/GlaucomaDetection", { state: dataskin });

  const goTumorDetection = () => navigate("/Tumor", { state: dataskin });
  const goHeartDiseaseTwo = () =>
    navigate("/heartDisease", { state: dataskin });

  const goToCoronaryArteryPrediction = () =>
    navigate("/CoronaryArteryPrediction", { state: dataskin });

  const goPneumoniaPrediction = () =>
    navigate("/pneumoniaPrediction", { state: dataskin });

  const goToSecondBrainStroke = () =>
    navigate("/BrainStrokePred", { state: dataskin });

  const goBoneFracture = () => navigate("/BoneFracture", { state: dataskin });

  const goChronicKidney = () => navigate("/chronicKidney", { state: dataskin });

  const goSecondPcos = () => navigate("/secondpcos", { state: dataskin });

  const goRheumatoidArthritis = () =>
    navigate("/rheumatoidArthritis", { state: dataskin });
  const goDementiaDetection = () =>
    navigate("/dementiaDetection", { state: dataskin });
  const goCoronaryArteryDisease = () =>
    navigate("/coronaryArteryDisease", { state: dataskin });
  const goPcos = () => navigate("/pcos", { state: dataskin });
  const goToskinml = () => navigate("/Skincancerml", { state: dataskin });
  const goTokidneystoneml = () =>
    navigate("/kidneystoneml", { state: dataskin });
  const goToCkdML = () => navigate("/chronickidneyml", { state: dataskin });
  const goToBreastCancerML = () =>
    navigate("/breastcancerml", { state: dataskin });
  const goToBreastCancerPredictionML = () =>
    navigate("/breastcancerpredictionml", { state: dataskin });
  const goToThyroid_Disease_ML = () =>
    navigate("/thyroidDiseaseml", { state: dataskin });
  const goToliverdiseaseML = () =>
    navigate("/liverdiseaseML", { state: dataskin });
  const goToPneumoniaML = () => navigate("/Pneumoniaml", { state: dataskin });
  const goToBonecancerml = () => navigate("/Bonecancerml", { state: dataskin });
  const goTohepatitisML = () => navigate("/hepatitis", { state: dataskin });
  const goToHeartStroke = () => navigate("/heartstroke", { state: dataskin });
  const goToheartdisease = () =>
    navigate("/Heartdiseaseml", { state: dataskin });
  const goTohPsychologyPrediction = () =>
    navigate("/PsychologyPrediction", { state: dataskin });
  const goToDiabeticDetection = () =>
    navigate("/DiabeticML", { state: dataskin });
  const goToHeartFail = () => navigate("/heartfailure", { state: dataskin });
  const goToBrainStroke = () => navigate("/brainstroke", { state: dataskin });
  const goToBreastDisease = () =>
    navigate("/BreastDisease", { state: dataskin });

  const goToKidneyFailure = () =>
    navigate("/KidneyFailure", { state: dataskin });
  const goToSclerosisDiagnosis = () =>
    navigate("/sclerosisDiagnosis", { state: dataskin });

  const goDementia = () => navigate("/Dementia", { state: dataskin });
  const goOsteoporosis = () => navigate("/Osteoporosis", { state: dataskin });
  const goLungCancer = () => navigate("/LungCancer", { state: dataskin });
  const goDieasePrognosis = () =>
    navigate("/diease-prognosis", { state: dataskin });
  const goHeartDisease = () => navigate("/heart-disease", { state: dataskin });
  const goInsomnia = () => navigate("/insomnia", { state: dataskin });
  const goDementia_second = () =>
    navigate("/dementiaDetection", { state: dataskin });
  const goFundunsEye = () => navigate("/fundunsEye", { state: dataskin });
  const goAtrialFibrillation = () =>
    navigate("/atrialFibrillation", { state: dataskin });
  const goStrokePrediction = () =>
    navigate("/strokePrediction", { state: dataskin });
  const goDiabetesPrognosis = () =>
    navigate("/diabetes-prognosis", { state: dataskin });
  const goCoronaryDisease = () =>
    navigate("/coronaryDisease", { state: dataskin });
  const goHeartAnomalies = () =>
    navigate("/heartAnomalies", { state: dataskin });
  const goBrainAnomalies = () =>
    navigate("/brainAnomalies", { state: dataskin });
  const goEpilepticSeizure = () =>
    navigate("/epilepticSeizure", { state: dataskin });
  const goRheumatoidArthritisTwo = () =>
    navigate("/rheumatoidArthritisTwo", { state: dataskin });
  const goThyroidPrediction = () =>
    navigate("/thyroidPrediction", { state: dataskin });

  const goPulmonaryDiseaseDetection = () =>
    navigate("/pulmonaryDiseaseML", { state: dataskin });

  const goAlzheimerAnalysis = () => navigate("/alzheimer", { state: dataskin });

  const goCervicalCancerPrediction = () =>
    navigate("/cervical-cancer-prediction", { state: dataskin });

  const goMultiModelScene = () =>
    navigate("/multi-model-scene", { state: dataskin });
  const goSkinCancer = () =>
    navigate("/skin-cancer-upload", { state: dataskin });

  const goBoneCancer = () => navigate("/bone-cancer", { state: dataskin });

  const goIcuAdmission = () =>
    navigate("/icu-admission-prediction", { state: dataskin });
  const goOralCancerPre = () =>
    navigate("/oral-cancer-prediction", { state: dataskin });

  return (
    <div style={{ background: "#F3F8FC" }}>
      <br></br>
      <h2>Patient Basic Information details</h2>
      <div class="center">
        <center>
          <table class="search-result-table">
            <tr>
              <td>ID</td>
              <td>{location.state.id}</td>
            </tr>
            <tr>
              <td>Name</td>
              <td>
                {location.state.FName} {location.state.LName}
              </td>
            </tr>
            <tr>
              <td>Blood Group</td>
              <td>{location.state.BloodGroup}</td>
            </tr>
            <tr>
              <td>Age</td>
              <td>{location.state.Age}</td>
            </tr>
            <tr>
              <td>Phone</td>
              <td>{location.state.MobileNumber}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{location.state.EmailId}</td>
            </tr>
            <tr>
              <td>Address</td>
              <td>{location.state.Address}</td>
            </tr>
          </table>
        </center>
      </div>

      <head>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <link rel="stylesheet" href="style.css" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://cdn.jsdelivr.net/npm/remixicon@3.2.0/fonts/remixicon.css"
          rel="stylesheet"
        />
      </head>

      <section class="container">
        <h2>Patient Specific Services</h2>
        <div className="row">
          <div className="service">
            <i class="ri-macbook-line"></i>
            <h3>Skin Cancer Dignostic </h3>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            <button className="button button5" onClick={goToskinml}>
              {" "}
              go
            </button>
          </div>
          <div className="service">
            <i class="ri-store-3-line"></i>
            <h3>Brain Dignostic</h3>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            <button className="button button5">Go</button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Kidney Stone Diagnosis</h3>
            <p>96% accuracy ~ Convolutional Neural Networks</p>
            <button className="button button5" onClick={goTokidneystoneml}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Chronic Kidney Disease Diagnosis</h3>
            <p>95% accuracy~ Decision Tree</p>
            <button className="button button5" onClick={goToCkdML}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>PCOS detection</h3>
            <p></p>
            <button className="button button5" onClick={goPcos}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Second Brain Stroke Prediction</h3>
            <p></p>
            <button className="button button5" onClick={goToSecondBrainStroke}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Covid Prediction</h3>
            <p></p>
            <button className="button button5" onClick={goCovidPred}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Coronary Artery Prediction</h3>
            <p></p>
            <button
              className="button button5"
              onClick={goToCoronaryArteryPrediction}
            >
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>PCOS prediction</h3>
            <p></p>
            <button className="button button5" onClick={goSecondPcos}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Pneumonia Prediction</h3>
            <p></p>
            <button className="button button5" onClick={goPneumoniaPrediction}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Bone Fracture</h3>
            <p></p>
            <button className="button button5" onClick={goBoneFracture}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Glaucoma Detection </h3>
            <button className="button button5" onClick={goGlaucomaDetection}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Tumor Detection </h3>
            <button className="button button5" onClick={goTumorDetection}>
              Go
            </button>
          </div>
          {/*
          <div class="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Coronary Artery Disease</h3>
            <p></p>
            <button class="button button5" onClick={goCoronaryArteryDisease}>
              Go
            </button>
          </div>
          */}
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Chronic Kidney</h3>
            <p></p>
            <button className="button button5" onClick={goChronicKidney}>
              Go
            </button>
          </div>
          <div className="service">
            <i class="ri-macbook-line"></i>
            <h3>Breast Disease Diagnosis</h3>
            <p>98% accuracy ~ Random Forest Classifier</p>
            <button className="button button5" onClick={goToBreastDisease}>
              Go
            </button>
          </div>
          <div className="service">
            <i class="ri-macbook-line"></i>
            <h3>Breast Cancer Diagnosis</h3>
            <p>97% accuracy ~ Linear Logistic Regression</p>
            <button className="button button5" onClick={goToBreastCancerML}>
              Go
            </button>
          </div>
          <div className="service">
            <i class="ri-macbook-line"></i>
            <h3>Breast Cancer Diagnosis</h3>
            <p>95% accuracy ~ Logistic Regression</p>
            <button
              className="button button5"
              onClick={goToBreastCancerPredictionML}
            >
              Go
            </button>
          </div>
          <div className="service">
            <i class="ri-store-3-line"></i>
            <h3>Liver Disease Diagnosis</h3>
            <p>ML model 99.55% accuracy ~ Random Forest</p>
            <button className="button button5" onClick={goToliverdiseaseML}>
              Go
            </button>
          </div>
          <div className="service">
            <i class="ri-macbook-line"></i>
            <h3>Thyroid Disease Detection</h3>
            <p>96% accuracy ~ Random Forest Model</p>
            <button className="button button5" onClick={goToThyroid_Disease_ML}>
              Go
            </button>
          </div>
          <div className="service">
            <i class="ri-macbook-line"></i>
            <h3>Skin Cancer Diagnosis</h3>
            <p>Detecting skin cancer type using ML</p>
            <button
              className="button button5"
              onClick={() => navigate("/skinCancerMLPage")}
            >
              Go
            </button>
          </div>
          <div className="service">
            <i class="ri-macbook-line"></i>
            <h3>Skin Diseases Diagnosis</h3>
            <p>Detecting skin diseases using ML</p>
            <button
              className="button button5"
              onClick={() => navigate("/skinCancerDiseasesPage")}
            >
              Go
            </button>
          </div>
          <div className="service">
            <i class="ri-macbook-line"></i>
            <h3>Heart Failure Prediction</h3>
            <p>Heart failure DL model with 94% accuracy</p>
            <button className="button button5" onClick={goToHeartFail}>
              {" "}
              go
            </button>
          </div>
          <div className="service">
            <i class="ri-macbook-line"></i>
            <h3>Brain Stroke Prediction</h3>
            <p>Brain Stroke DL Model with 97% accuracy</p>
            <button className="button button5" onClick={goToBrainStroke}>
              {" "}
              go
            </button>
          </div>
          <div className="service">
            <i class="ri-macbook-line"></i>
            <h3>Pneumonia Disease Detection</h3>
            <p>94% using VGG19</p>
            <button className="button button5" onClick={goToPneumoniaML}>
              Go
            </button>
          </div>
          <div className="service">
            <i class="ri-macbook-line"></i>
            <h3>Heart Stroke</h3>
            <p>Heart stroke prediction with 93% accuracy</p>
            <button className="button button5" onClick={goToHeartStroke}>
              {" "}
              go
            </button>
          </div>
          {/* Add Heart Disease Diagnostic Service */}
          <div className="service">
            <i className="ri-heart-line"></i>
            <h3>Heart Diagnostic</h3>
            <p>Get heart disease results.</p>
            <button className="button button5" onClick={goToheartdisease}>
              Go
            </button>
          </div>
          <div className="service">
            <i class="ri-store-3-line"></i>
            <h3>Hepatitis Prediction Model</h3>
            <p>Detection using SVM </p>
            <button className="button button5" onClick={goTohepatitisML}>
              Go
            </button>
          </div>
          <div className="service">
            <i class="ri-macbook-line"></i>
            <h3> Bone Cancer Detection</h3>
            <p>Bone Cancer prediction model using CNN </p>
            <button className="button button5" onClick={goToBonecancerml}>
              Go
            </button>
          </div>
          {/* Add Psychology Diagnostic Service */}
          <div className="service">
            <i class="ri-macbook-line"></i>
            <h3> Psychology Detection</h3>
            <p>Get Psychology disease results</p>
            <button
              className="button button5"
              onClick={goTohPsychologyPrediction}
            >
              Go
            </button>
          </div>
          <div className="service">
            <i class="ri-macbook-line"></i>
            <h3> Diabetics Detection</h3>
            <p>Get Diabetic results</p>
            <button className="button button5" onClick={goToDiabeticDetection}>
              Go
            </button>
          </div>
          <div className="service">
            <i class="ri-macbook-line"></i>
            <h3> Dementia Detection</h3>
            <p>Get Dementia results</p>
            <button className="button button5" onClick={goDementia}>
              Go
            </button>
          </div>
          <div className="service">
            <i class="ri-macbook-line"></i>
            <h3> Second Dementia Detection</h3>
            <p>Get Dementia results</p>
            <button className="button button5" onClick={goDementia_second}>
              Go
            </button>
          </div>
          <div className="service">
            <i class="ri-macbook-line"></i>
            <h3> Osteoporosis Detection</h3>
            <p>Get Osteoporosis results</p>
            <button className="button button5" onClick={goOsteoporosis}>
              Go
            </button>
          </div>{" "}
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Sclerosis Diagnosis</h3>{" "}
            <p>Diagnose Sclerosis using ML Model</p>{" "}
            <button className="button button5" onClick={goToSclerosisDiagnosis}>
              Go{" "}
            </button>{" "}
          </div>{" "}
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Kidney Failure Predication</h3>
            <p>Predicate the Kidney Failure in One Year</p>
            <button className="button button5" onClick={goToKidneyFailure}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Lung Cancer Predication</h3>
            <p>Get Lung Cancer results</p>
            <button className="button button5" onClick={goLungCancer}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Disease Prognosis</h3>
            <p>Predicate Disease Prognosis</p>
            <button className="button button5" onClick={goDieasePrognosis}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Heart Disease(Arrhythmia)</h3>
            <p>Heart Disease Predication</p>
            <button className="button button5" onClick={goHeartDisease}>
              Go
            </button>
          </div>
          {/* goInsomnia */}
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Insomnia Predication</h3>
            <p>Predicate Insomnia Condition</p>
            <button className="button button5" onClick={goInsomnia}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Funduns Predication</h3>
            <p>Predicate Funduns Condition</p>
            <button className="button button5" onClick={goFundunsEye}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>AtrialFibrillation Predication</h3>
            <p>Predicate AtrialFibrillation Condition</p>
            <button className="button button5" onClick={goAtrialFibrillation}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Stroke Predication</h3>
            <p>Predicate Stroke Condition</p>
            <button className="button button5" onClick={goStrokePrediction}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Heart Disease(CAD) Predication </h3>
            <button className="button button5" onClick={goHeartDiseaseTwo}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Diabetes Prognosis </h3>
            <button className="button button5" onClick={goDiabetesPrognosis}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Coronary Artery Disease </h3>
            <button className="button button5" onClick={goCoronaryDisease}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Heart Anomalies</h3>
            <button className="button button5" onClick={goHeartAnomalies}>
              GO
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Epileptic Seizure Detection </h3>
            <button className="button button5" onClick={goEpilepticSeizure}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Brain Anomalies</h3>
            <button className="button button5" onClick={goBrainAnomalies}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Rheumatoid Arthritis</h3>
            <button
              className="button button5"
              onClick={goRheumatoidArthritisTwo}
            >
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Thyroid Prediction</h3>
            <button className="button button5" onClick={goThyroidPrediction}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Pulmonary Disease Detection</h3>
            <button
              className="button button5"
              onClick={goPulmonaryDiseaseDetection}
            >
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Alzheimer's Disease Risk Assessment</h3>
            <button className="button button5" onClick={goAlzheimerAnalysis}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Cervical Cancer Prediction</h3>
            <button
              className="button button5"
              onClick={goCervicalCancerPrediction}
            >
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Multi Model Scene</h3>
            <button className="button button5" onClick={goMultiModelScene}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Skin Cancer</h3>
            <button className="button button5" onClick={goSkinCancer}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Bone Cancer</h3>
            <button className="button button5" onClick={goBoneCancer}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>ICU Admission Prediction</h3>
            <button className="button button5" onClick={goIcuAdmission}>
              Go
            </button>
          </div>
          <div className="service">
            <MedicalServicesOutlinedIcon sx={{ fontSize: 50 }} />
            <h3>Oral Cancer Prediction</h3>
            <button className="button button5" onClick={goOralCancerPre}>
              Go
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Searchresult;
