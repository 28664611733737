// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer__section {
    padding: 20px 0;
  }
  
  .footer__container {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between; /* Aligns the content to the left and right */
    align-items: center;
  }
  
  .footer__left {
    font-size: 18px;
    font-weight: bold;
  }
  
  .footer-social .social__container {
    display: flex;
    gap: 15px; /* Space between icons */
    align-items: center;
  }
  
  .footer-social .social__container a .footer-icon {
    font-size: 24px;
    color: #000; /* Default icon color */
    text-decoration: none;
  }
  
  .footer-social .social__container a .footer-icon:hover {
    color: #007bff; /* Hover effect for the icons */
  }
  
  @media screen and (max-width: 768px) {
    .footer__container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .footer-social .social__container {
      margin-top: 10px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/screens/E-Pharmacy/components/footer/footer.css"],"names":[],"mappings":"AAAA;IACI,eAAe;EACjB;;EAEA;IACE,UAAU;IACV,YAAY;IACZ,aAAa;IACb,8BAA8B,EAAE,6CAA6C;IAC7E,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,SAAS,EAAE,wBAAwB;IACnC,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,WAAW,EAAE,uBAAuB;IACpC,qBAAqB;EACvB;;EAEA;IACE,cAAc,EAAE,+BAA+B;EACjD;;EAEA;IACE;MACE,sBAAsB;MACtB,uBAAuB;IACzB;;IAEA;MACE,gBAAgB;IAClB;EACF","sourcesContent":[".footer__section {\n    padding: 20px 0;\n  }\n  \n  .footer__container {\n    width: 90%;\n    margin: auto;\n    display: flex;\n    justify-content: space-between; /* Aligns the content to the left and right */\n    align-items: center;\n  }\n  \n  .footer__left {\n    font-size: 18px;\n    font-weight: bold;\n  }\n  \n  .footer-social .social__container {\n    display: flex;\n    gap: 15px; /* Space between icons */\n    align-items: center;\n  }\n  \n  .footer-social .social__container a .footer-icon {\n    font-size: 24px;\n    color: #000; /* Default icon color */\n    text-decoration: none;\n  }\n  \n  .footer-social .social__container a .footer-icon:hover {\n    color: #007bff; /* Hover effect for the icons */\n  }\n  \n  @media screen and (max-width: 768px) {\n    .footer__container {\n      flex-direction: column;\n      align-items: flex-start;\n    }\n  \n    .footer-social .social__container {\n      margin-top: 10px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
