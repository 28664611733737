import React from 'react';
import "./slider.css";
import { slide } from '../../assets/data/data';

function Slider() {
  return (
    <div className="e-pharmacy-slider">
      <div className="e-pharmacy-slider__container">
        {slide.map((item, index) => {
          return (
            <div className="e-pharmacy-slider__box" key={index + 1}>
              <div className="e-pharmacy-box__img">
                <img src={item.image} alt="" />
              </div>
            </div>
          );
        })}
      </div>

      {/* Left Arrow */}
      <button className="e-pharmacy-slider__arrow e-pharmacy-slider__arrow-left">
        &lt;
      </button>

      {/* Right Arrow */}
      <button className="e-pharmacy-slider__arrow e-pharmacy-slider__arrow-right">
        &gt;
      </button>
    </div>
  );
}

export default Slider;
