import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import OperationBox from "./OperationBox.jsx";
import AllNotesBox from "./AllNotesBox.jsx";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import DownloadUploadForm from "../../../components/DoctorComponents/DownloadUploadForm.jsx";
import { MedicalTestRequisitionForm } from "../MedicalTestRequisitionForm.jsx";
import CreateReferralForm from "./Modals/ReferralForm.jsx";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import addPatientInfoAction from "../../../redux/actions/addPatientInfoAction";
import { BASE_URL } from "../../../constants";
import PrescriptionForm from "./Modals/PrescriptionForm.jsx";
import AITreatmentPopup from "./Modals/DoctorAITreatmentPlan.jsx";
import VisitForm from "./Modals/VisitForm.jsx";
import {
  Typography,
  TextField,
  Grid,
  Snackbar,
  Button,
  Divider,
  Box,
  Modal,
  Card,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import BASE_URLS from "./constants.jsx";

const DoctorOperations = ({ patientId, doctorId }) => {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [patientData, setPatientData] = React.useState({});
  const [doctorDetails, setDoctorDetails] = useState({});
  const [showCreateReferralModal, setShowCreateReferralModal] = useState(false);
  const [treatments, setTreatments] = React.useState([]);
  const [profileLoading, setProfileLoading] = useState(true);
  const [showVisitModal, setShowVisitModal] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showCreatePrescriptionModal, setShowCreatePrescriptionModal] =
    useState(false);
  const [showCreateAiTreantmentPlanModal, setShowCreateAiTreantmentPlanModal] =
    useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const [showRequestMedicalTestModal, setShowRequestMedicalTestModal] =
    useState(false);

  const [prescriptions, setPrescriptions] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPrescriptions = async () => {
      try {
        const response = await axios.post(
          `${BASE_URLS.PRODUCTION}/getPrescriptionsByPatientId`,
          { patientId }
        );
        const data = response.data;

        // Ensure data is sorted by date (descending)
        data.sort(
          (a, b) =>
            new Date(b.prescription_creation_time) -
            new Date(a.prescription_creation_time)
        );

        setPrescriptions(
          data.map((item) => ({
            date: new Date(
              item.prescription_creation_time
            ).toLocaleDateString(),
            specialty: item.medicine_name || "No Medicine",
          }))
        );
      } catch (error) {
        console.error("Error fetching prescriptions:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchPrescriptions();
  }, [patientId]);

  useEffect(() => {
    const fetchReferrals = async () => {
      try {
        const response = await axios.post(
          `${BASE_URLS.PRODUCTION}/getReferralByPatientID`,
          { patientId }
        );
        const data = response.data;

        console.log("Referrals:", data);
        data.sort((a, b) => new Date(b.record_time) - new Date(a.record_time));

        setReferrals(
          data.map((item) => ({
            date: new Date(item.record_time).toLocaleDateString(),
            specialty: `${item.referred_doctor_FName || "No Name"} ${
              item.referred_doctor_LName || ""
            }`,
          }))
        );
      } catch (error) {
        console.error("Error fetching referrals:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchReferrals();
  }, [patientId]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.post(
          `${BASE_URLS.PRODUCTION}/searchPatientById`,
          {
            patientId,
          }
        );
        const { data } = response;
        if (data.error) {
          alert(JSON.stringify(data.error));
          console.log("error ");
        } else {
          setPatientData(data);
        }
      } catch (error) {
        console.log(
          `Error With request getting auth  recent : ${error.message}`
        );
      } finally {
        setProfileLoading(false);
      }
    };
    getData();
  }, [patientId]);

  //LocalTime
  const toLocalISOString = (date) => {
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
    return adjustedDate.toISOString().split("T")[0];
  };

  const [visitDate, setVisitDate] = useState(toLocalISOString(new Date()));

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [reasonForVisit, setReasonForVisit] = useState("");
  // Function to handle saving the visit
  const styleRequestMedical = {
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    minHeight: "50%",
    maxHeight: "100%",
    pt: 2,
    px: 4,
    pb: 3,
    overflowY: "auto",
  };

  const CardUnit = ({ children, position, isThreeFold }) => {
    return (
      <Card
        sx={{
          boxShadow: "none",
          position: "relative",
          borderRadius: "0",
          width: `calc(100%)`,
          padding: "0.8rem 2rem 0.2rem 1rem",
          border: "1px solid #D4E3F0",
          borderTop: "none",
          borderRight: "none",
          ...(position === "bottom" ? { borderBottom: "none" } : {}),
          overflow: "auto",
          height: isThreeFold ? "9.2rem" : "13.9rem",
        }}
      >
        {children}
      </Card>
    );
  };

  const AllNotesCardUnit = ({ children }) => {
    return (
      <Card
        sx={{
          boxShadow: "none",
          position: "relative",
          borderRadius: "0",
          width: `calc(100%)`,
          padding: "0.8rem 2rem 0.2rem 1rem",
          border: "none",
          overflow: "auto",
          height: "100%",
        }}
      >
        {children}
      </Card>
    );
  };

  const CustomModal = ({ open, onClose, children }) => {
    return (
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Card
            sx={{
              width: "fit-content",
              borderRadius: "0.5rem",
              maxHeight: "98vh",
              overflow: "auto",
            }}
          >
            <CardContent
              sx={{
                padding: "1rem",
                margin: 0,
                width: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {children}
            </CardContent>
          </Card>
        </Box>
      </Modal>
    );
  };

  const allNotes = [
    { type: "Monthly Check", date: "09:20AM - 11:30 2023/3/11" },
    { type: "Observation", date: "09:20AM - 11:30 2023/2/11" },
    { type: "Notes", date: "09:20AM - 11:30 2023/2/11" },
  ];

  const diagnosisData = [
    { specialty: "Migraine", date: "2023/3/11" },
    { specialty: "Coronary Artery Disease", date: "2023/2/11" },
    { specialty: "Skin Cancer", date: "2023/3/15" },
    { specialty: "Thyroid", date: "2023/4/20" },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDiagnosisClick = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}/searchPatientById`, {
        patientId: patientId,
      });
      const variable = response.data;
      dispatch(addPatientInfoAction(variable));
      console.log("variable", variable);
      navigate("/searchresult", { state: variable });
    } catch (err) {
      console.error(err);
    }
  };

  const medicalRecords = [
    { name: "Dr. William James", specialty: "Cardiologist", date: "2023/3/11" },
    {
      name: "Dr. William James",
      specialty: "General Practitioner",
      date: "2023/2/11",
    },
    { name: "Covid Vacine", specialty: "Neurologist", date: "2023/3/15" },
    { name: "Blood Test", specialty: "Dermatologist", date: "2023/4/20" },
    { name: "Blood Test", specialty: "Pediatrician", date: "2023/5/18" },
    { name: "Covid", specialty: "Oncologist", date: "2023/6/22" },
    { name: "Covid", specialty: "Surgeon", date: "2023/7/30" },
    { name: "Covid", specialty: "Psychiatrist", date: "2023/8/5" },
  ];

  return (
    <Grid item xs={12} container>
      <Grid item xs={4}>
        <Card
          sx={{
            boxShadow: "none",
            padding: "0",
            position: "relative",
          }}
        >
          <div
            style={{
              color: "#59748A",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "5rem",
              fontSize: "1.2rem",
              gap: "2rem",
            }}
          >
            2023/4/1 - 2023/4/30 {<CalendarMonthOutlinedIcon />}
          </div>
          <Divider sx={{ borderBottomWidth: 1, borderColor: "#D4E3F0" }} />
          <AllNotesCardUnit>
            <AllNotesBox
              title={"All Notes/Past Visits"}
              buttonText={"Add Notes"}
              listData={allNotes}
              onClick={() => setShowVisitModal(true)}
            />
          </AllNotesCardUnit>
          <Grid item xs={12}>
            <Card style={{ boxShadow: "none", position: "relative" }}>
              <CustomModal
                open={showVisitModal}
                onClose={() => setShowVisitModal(false)}
              >
                <VisitForm
                  closeModal={() => setShowVisitModal(false)}
                  patientId={patientId}
                  doctorId={doctorId}
                />
              </CustomModal>
            </Card>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Grid item xs={12}>
          <CardUnit>
            <OperationBox
              title={"Diagnosis"}
              buttonText={"Add Diagnosis"}
              listData={diagnosisData}
              onClick={handleDiagnosisClick}
            />
          </CardUnit>
        </Grid>
        <Grid item xs={12}>
          <Card style={{ boxShadow: "none", position: "relative" }}>
            <CustomModal
              open={showCreateReferralModal}
              onClose={() => setShowCreateReferralModal(false)}
            >
              <CreateReferralForm
                closeModal={() => setShowCreateReferralModal(false)}
                patientId={patientData.id}
                doctorId={doctorId}
              />
            </CustomModal>
          </Card>
          <CardUnit position={"bottom"}>
            <OperationBox
              title={"Referrals"}
              buttonText={"Add Referrals"}
              listData={referrals}
              onClick={() => setShowCreateReferralModal(true)}
            />
          </CardUnit>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid item xs={12}>
          <CardUnit isThreeFold={true}>
            <OperationBox
              title={"Prescription"}
              buttonText={"Create Prescription"}
              listData={prescriptions}
              onClick={() => setShowCreatePrescriptionModal(true)}
            />
          </CardUnit>
          <CustomModal
            open={showCreatePrescriptionModal}
            onClose={() => setShowCreatePrescriptionModal(false)}
          >
            <PrescriptionForm
              patientId={patientId}
              doctorId={doctorId}
              patientData={patientData}
              closeModal={() => setShowCreatePrescriptionModal(false)}
            />
          </CustomModal>
        </Grid>
        <Grid item xs={12}>
          <CardUnit isThreeFold={true}>
            <OperationBox
              title={"Al Treatment Plan"}
              buttonText={"Create Al Treatment Plan"}
              listData={prescriptions}
              onClick={() => setShowCreateAiTreantmentPlanModal(true)}
            />
          </CardUnit>
          <CustomModal
            open={showCreateAiTreantmentPlanModal}
            onClose={() => setShowCreateAiTreantmentPlanModal(false)}
          >
            <AITreatmentPopup
              open={showCreateAiTreantmentPlanModal}
              patientId={patientId}
              doctorId={doctorId}
              patientData={patientData}
              closeModal={() => setShowCreateAiTreantmentPlanModal(false)}
            />
          </CustomModal>
        </Grid>
        <Grid item xs={12}>
          <CardUnit position={"bottom"} isThreeFold={true}>
            <OperationBox
              title={"Medical Records"}
              buttonText={"Request Medical Test"}
              listData={medicalRecords}
              onClick={() => setShowRequestMedicalTestModal(true)}
            />
          </CardUnit>

          <Modal
            open={showRequestMedicalTestModal}
            onClose={() => setShowRequestMedicalTestModal(false)}
          >
            <Box>
              <Card>
                <Typography variant="outlined">
                  {" "}
                  (Scroll down and up to navigate through our web form)
                </Typography>
              </Card>
              <Card sx={styleRequestMedical}>
                <MedicalTestRequisitionForm
                  patientId={patientId}
                  doctorId={doctorId}
                  patientData={patientData}
                  doctorDetails={doctorDetails}
                />
              </Card>
              <Card sx={{ position: "relative" }}>
                <DownloadUploadForm />
                <Button
                  style={{
                    width: "200px",
                    color: "white",
                    backgroundColor: "#1A4FBA",
                    border: "none",
                    fontFamily: "Poppins",
                  }}
                  onClick={() => setShowRequestMedicalTestModal(false)}
                >
                  Exit
                </Button>
              </Card>
            </Box>
          </Modal>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DoctorOperations;
