import React, { useState } from "react";
import imagev from "../../assets/images/admin.png"; // Default preview image
import "./account.css";

const Account = () => {
  const [selectedImage, setSelectedImage] = useState(null); // State for storing selected image

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result); // Set the image preview
      };
      reader.readAsDataURL(file);
    }
  };

  // Mock data for display
  const mockData = {
    username: "JohnDoe",
    email: "johndoe@example.com",
    password: "**********", // Masked for display purposes
  };

  return (
    <section className="account-section">
      <div className="account-container">
        <h1>Account Information</h1>
        <div className="account-content">
          <div className="account-left">
            <div className="account-img-container">
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange} // Handle image change
                id="file-input"
                className="file-input"
              />
              {/* Display preview if image is selected */}
              <img
                src={selectedImage || imagev} // Use the selected image or default if none
                alt="Account"
                className="account-image-preview"
              />
              <label htmlFor="file-input" className="image-upload-btn">
                Change Profile Picture
              </label>
            </div>
          </div>
          <div className="account-right">
            <form className="account-form">
              <div className="form-group">
                <label htmlFor="name">Username</label>
                <p>{mockData.username}</p> {/* Display static username */}
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <p>{mockData.email}</p> {/* Display static email */}
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <p>{mockData.password}</p> {/* Display static password (masked) */}
              </div>
              {/* No button necessary as it's just a display */}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Account;
