import React, { useState } from "react";
import "./SurgicalPlanner.css";

function SurgicalPlanner() {
  const [patientName, setPatientName] = useState("");
  const [patientAge, setPatientAge] = useState("");
  const [gender, setGender] = useState("");
  const [medicalHistory, setMedicalHistory] = useState("");
  const [diagnostics, setDiagnostics] = useState("");
  const [diagnosticFiles, setDiagnosticFiles] = useState([]);
  const [procedure, setProcedure] = useState("");
  const [procedureName, setProcedureName] = useState("");
  const [surgicalPlan, setSurgicalPlan] = useState("");

  const handleGeneratePlan = () => {
    let diagnosticFilesList = diagnosticFiles
      .map((file) => file.name)
      .join(", ");
    const plan = `
      Patient Name: ${patientName} 
      Patient Age: ${patientAge} 
      Patient Gender: ${gender} 
      Medical History: ${medicalHistory}
      Diagnostics: ${diagnostics}
      Diagnostics Files: ${diagnosticFilesList}
      Surgery Type: ${procedure}
      Procedure Name: ${procedureName}
      .................................................
      Surgical Plan:

      - Pre-operative preparations based on medical history.
      1. Get patient admitted a day before the surgery
      2. Perform final tests and imaging
      3. Prepare for the surgery 
      4. Put the patient under general anasthesia, intubate, and sterile the surgical area
      
      - Imaging analysis (CT/MRI) to identify important landmarks for ${procedure}.
      
      - Follow standard operating procedures for ${procedure}.
      1. Make an incision for open heart surgery
      2. Open the rib cage and connect to a heart-lung machine
      3. Remove a section of healthy blood vessel either from inside the chest wall or the lower leg
      4. Attach the graft below the blocked heart artery
      5. Take the patient off of the heart-lung machine
      6. Close and send the patient to recovery
      
      - Post-operative care: Monitor vitals, blood work, and provide appropriate follow-up.
      1. The breathing tube is taken out once the patient wakes up
      2. Patient stays in the hospital for 1-2 days (barring any complications)
      3. Tests and checks are perfomed to ensure the surgery went well and the patient is recovering well
      4. Pain medication is perscribed
      5. Once discharged, a list of symtoms are shared to ensure no complications are arising
      6. Typical recovery time range from 6-12 weeks
    `;
    setSurgicalPlan(plan);
  };

  return (
    <div className="surgical-planner-container">
      <h1>Surgical Planner</h1>
      <form>
        <label>Patient Name:</label>
        <input
          type="text"
          value={patientName}
          onChange={(e) => setPatientName(e.target.value)}
        />

        <label>Patient Age:</label>
        <input
          type="text"
          value={patientAge}
          onChange={(e) => setPatientAge(e.target.value)}
        />

        <label>Gender:</label>
        <select value={gender} onChange={(e) => setGender(e.target.value)}>
          <option value="">Choose Gender</option>
          <option value="female">Female</option>
          <option value="male">Male</option>
          <option value="non-binary">Non-Binary</option>
          <option value="prefer-not-to-say">Prefer Not to Say</option>
        </select>

        <label>Medical History:</label>
        <textarea
          value={medicalHistory}
          onChange={(e) => setMedicalHistory(e.target.value)}
        ></textarea>

        <label>Diagnostics:</label>
        <textarea
          value={diagnostics}
          onChange={(e) => setDiagnostics(e.target.value)}
        ></textarea>

        <label>Upload Diagnostic Files:</label>
        <input
          type="file"
          multiple
          onChange={(e) => setDiagnosticFiles(e.target.files)}
        />

        <label>Surgery Type:</label>
        <select
          value={procedure}
          onChange={(e) => setProcedure(e.target.value)}
        >
          <option value="">Choose the Surgery Type</option>
          <option value="cardiothoracic">Cardiothoracic Surgery</option>
          <option value="neurosurgery">Neurosurgery</option>
          <option value="orthopedic">Orthopedic Surgery</option>
          <option value="plastic">Plastic Surgery</option>
        </select>

        <label>Procedure Name:</label>
        <input
          type="text"
          value={procedureName}
          onChange={(e) => setProcedureName(e.target.value)}
        />

        <button type="button" onClick={handleGeneratePlan}>
          Generate Plan
        </button>
      </form>

      <div id="surgicalPlan">
        <h2>Surgical Plan</h2>
        <pre style={{ whiteSpace: "pre-line" }}>{surgicalPlan}</pre>
      </div>
    </div>
  );
}

export default SurgicalPlanner;
