import React from "react";
import CartItems from "../header/CartItems";
import Slider from "../heroslide/slider";
import Order from "../heroslide/Order";
import Category from "../category/Category";
import ProductCart from "../product/ProductCart";
import Header from "../header/PharmacyHeader";
import Pharmacyfooter from "../footer/Pharmacyfooter";
const Home = (props) => {
  let st = true;
  return (
    <>
      <Header clearUser={props.clearUser} />
      <div
        className="e-pharmacy-container"
        style={{ paddingTop: "3rem", maxWidth: "1320px", margin:"0 auto" }}
      >
        <Slider />
        <Order />
        <Category />
        <ProductCart />
        <Pharmacyfooter />
      </div>
    </>
  );
};

export default Home;
