import moment from "moment";
import { readLoginData } from "../../../loginData";
import { doctorGetCalendar } from "../../../api/calendar";
import React, { useEffect, useState, useMemo } from "react";
import { Select } from "antd";
import MUIDataTable from "mui-datatables";
import { MenuItem, FormControl } from "@mui/material";
import ViewModal from "../patient-details/ViewModal";
import getTableColumns from "./tableColumns";
import { useNavigate } from "react-router-dom";
import "./PatientInfoTable.css";

// const getTimePeriod = (value) => {
//   switch (value) {
//     case "today":
//       return [moment().startOf("day"), moment().endOf("day")];
//     case "week":
//       return [moment().startOf("week"), moment().endOf("week")];
//     case "month":
//       return [moment().startOf("month"), moment().endOf("month")];
//     default:
//       return [moment(value).startOf("day"), moment(value).endOf("day")];
//   }
// };

const PatientInfoTable = (props) => {
  const [timeScope, setTimeScope] = useState("today");
  const [users, setUsers] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [viewModal, setViewModal] = useState(false);
  const navigate = useNavigate()

  const handleTimeScopeChange = (value) => {
    setTimeScope(value);
  };

  const TableTitle = () => (
    <div className="doctor-patients-info-table-title flex justify-around">
      <button>Total Patients ({props.data.length})</button>
      <button
        onClick={() => navigate("/doctor/DiseaseAnalytics")}
        className="custom-button"
      >
        Dashboards
      </button>
    </div>
  );

  const loginData = useMemo(() => readLoginData(), []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const patientIdFromUrl = urlParams.get("patientId");
    console.log("patientIdFromUrl", patientIdFromUrl);

    if (patientIdFromUrl) {
      setSelectedRowData({
        ...selectedRowData,
        id: patientIdFromUrl,
      });
      setViewModal(true);
    }
    const handlePopState = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const patientIdFromUrl = urlParams.get("patientId");

      if (patientIdFromUrl) {
        setViewModal(true);
        setSelectedRowData({
          ...selectedRowData,
          id: patientIdFromUrl,
        });
      } else {
        setViewModal(false);
        setSelectedRowData({
          ...selectedRowData,
          id: null,
        });
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const HeaderElements = ({ currentScope, onTimeScopeChange }) => {
    return (
      <div style={{ width: "130px" }}>
        <FormControl size="small" sx={{ width: "auto", border: "none" }}>
          <Select
            labelId="time-scope-select-label"
            id="time-scope-select"
            value={currentScope}
            onChange={onTimeScopeChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="today">Today</MenuItem>
            <MenuItem value="week">Week</MenuItem>
            <MenuItem value="month">Month</MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  };
  const handleOpenViewModal = (rowData) => {
    console.log("rowData", rowData);
    const patientId = rowData.id;
    const newUrl = new URL(window.location);
    newUrl.searchParams.set("patientId", patientId);
    window.history.pushState({}, "", newUrl);
    setViewModal(true);
    setSelectedRowData(rowData);
    setViewModal(true);
  };

  const handleCloseViewModal = () => {
    setViewModal(false);
    const newUrl = new URL(window.location);
    newUrl.searchParams.delete("patientId");
    window.history.pushState({}, "", newUrl);
  };

  const columns = getTableColumns(handleOpenViewModal, props.data);

  const options = {
    filterType: "dropdown",
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    download: false,
    viewColumns: false,
    print: false,
    elevation: 0,
    // customToolbar: () => (
    //   <HeaderElements
    //     onTimeScopeChange={handleTimeScopeChange}
    //     currentScope={timeScope}
    //   />
    // ),
    // onRowClick: (rowData, rowMeta) => {
    //   const dataIndex = rowMeta.dataIndex;
    //   const user = users[dataIndex];
    //   handleOpenViewModal(user);
    // },
  };

  return (
    <div className="doctor-patients-info-table">
      <MUIDataTable
        title={<TableTitle />}
        data={props.data}
        columns={columns}
        options={options}
      />
      {viewModal ? (
        <ViewModal
          visible={viewModal}
          onClose={handleCloseViewModal}
          patientId={selectedRowData.id}
          doctorId={loginData.id}
          clearUser={props.clearUser}
        />
      ) : null}
    </div>
  );
};

export default PatientInfoTable;
