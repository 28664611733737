import moment from "moment";
import React, { useState } from "react";
import { Button, Card, Divider, Empty, Flex, Modal, Spin } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";

import { doctorApproveRequest, getTimeSegmentDetail } from "../../api/calendar";
import { readLoginData } from "../../loginData";
import { useNavigate } from "react-router-dom";
import "./TimeSegmentDialog.css";

const dateFormat = "YYYY-MM-DD HH:mm:ss";

const getTimeSegmentState = (status) => {
  if (status < 0) {
    return "AlreadyBooked";
  } else if (status > 0) {
    return "PendingApproval";
  } else {
    return "NotBooked";
  }
};

const getAppointmentState = (status) => {
  if (status < 0) {
    return "Cancelled";
  } else if (status === 1) {
    return "Approved";
  } else {
    return "PendingApproval";
  }
};

const getCategoryString = (category) => {
  if (category === 1) {
    return "Task";
  } else if (category === 2) {
    return "Available Slot";
  } else if (category === 3) {
    return "Surgery";
  } else if (category === 4) {
    return "General Consultation";
  } else if (category === 5) {
    return "Lab Testing";
  }
};

const AppointmentRequest = (props) => {
  console.log("AppointmentRequestprops", props);
  const handleClick = () => {
    props.onApprove(props);
  };
  return (
    <Card
      size="small"
      title={props.patient.name}
      extra={
        props.status === 0 ? (
          <Button onClick={handleClick}>Approve</Button>
        ) : undefined
      }
      style={{ width: "100%" }}
      className="appointment-request-card"
    >
      <div style={{ display: "flex" }}>
        <div style={{ width: "100px", color: "#666666" }}>Status: </div>
        <div style={{ fontWeight: "500" }}>
          {getAppointmentState(props.status)}
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ width: "100px", color: "#666666" }}>Category: </div>
        <div style={{ fontWeight: "500" }}>
          {getCategoryString(props.category)}
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ width: "100px", color: "#666666" }}>Description: </div>
        <div style={{ fontWeight: "500" }}>{props.description}</div>
      </div>
    </Card>
  );
};

const AppointmentRequestList = (props) => {
  return (
    <>
      <Flex wrap="wrap" gap="small">
        {props.data.length ? (
          props.data.map((item) => (
            <AppointmentRequest {...item} onApprove={props.onApprove} />
          ))
        ) : (
          <Empty />
        )}
      </Flex>
    </>
  );
};

const TimeSegmentDialog = (props) => {
  const navigate = useNavigate();
  const segmentId = props.id;

  const loginData = readLoginData();
  let [needLoad, setNeedLoad] = useState(true);
  let [loading, setLoading] = useState(true);
  let [data, setData] = useState({
    id: 0,
    doctor: {
      id: loginData.id,
      name: loginData.name,
    },
    category: 0,
    status: -1,
    start: 0,
    end: 1,
    description: "Loading...",
    requests: [],
  });

  const fetchData = async () => {
    setLoading(true);
    console.log("id", segmentId);
    const response = await getTimeSegmentDetail(loginData, segmentId);
    console.log("response", response);
    setData(response);
    setLoading(false);
  };

  if (needLoad) {
    setNeedLoad(false);
    fetchData();
  }

  const startString = moment(data.start).format(dateFormat);
  const endString = moment(data.end).format(dateFormat);

  const handleApprove = (event) => {
    Modal.confirm({
      title: "Do you confirm your approval of this appointment request?",
      icon: <ExclamationCircleFilled />,
      content: (
        <>
          Category: {getCategoryString(event)}
          <br />
          Patient: {event.patient.name}
          <br />
          From: {startString}
          <br />
          To: {endString}
          <br />
        </>
      ),
      onOk: async () => {
        await doctorApproveRequest(loginData, event.id);
        props.onApprove();
        props.onClose();
      },
      onCancel: () => {},
    });
  };

  return (
    <Modal
      open={true}
      onOk={props.onClose}
      onCancel={props.onClose}
      // cancelButtonProps={{ style: { display: "none" } }}
      style={{ textAlign: "initial" }}
      className="time-segment-dialog"
    >
      <div
        style={{
          fontSize: "1.5rem",
          textAlign: "center",
          fontWeight: "600",
          marginBottom: "2rem",
        }}
      >
        Request Approval
      </div>
      <div style={{ fontSize: "1.2rem", textAlign: "left", fontWeight: "600" }}>
        Time Segment Details
      </div>
      <Divider style={{ marginTop: "0.5rem", backgroundColor: "#EEEEEE" }} />
      <Spin spinning={loading}>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100px", color: "#666666" }}>Category: </div>
          <div style={{ fontWeight: "500" }}>
            {getCategoryString(data.category)}
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100px", color: "#666666" }}>From: </div>
          <div style={{ fontWeight: "500" }}>{startString}</div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100px", color: "#666666" }}>To: </div>
          <div style={{ fontWeight: "500" }}>{endString}</div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100px", color: "#666666" }}>Description:</div>{" "}
          <div style={{ fontWeight: "500" }}> {data.description}</div>
        </div>
        {data.category !== 1 ? (
          <>
            <div
              style={{
                fontSize: "1.2rem",
                textAlign: "left",
                fontWeight: "600",
                marginTop: "3rem",
              }}
            >
              Requests
            </div>
            <Divider
              style={{ marginTop: "0.5rem", backgroundColor: "#EEEEEE" }}
            />
            <AppointmentRequestList
              data={data.requests}
              onApprove={handleApprove}
            />
            <br />
          </>
        ) : null}
      </Spin>
    </Modal>
  );
};

export default TimeSegmentDialog;
