// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add a background color and some padding around the form */

.contact-admin-container {
  min-height: 400px;
  height: fit-content;
  width: fit-content;
  margin-top: 0px;
}

.contact-admin-container h1 {
  font-size: 36px;
  margin: 10px;  
  color:  #0288d1;
  font-family: 'Poppins', sans-serif!important;
}

.contact-admin-container h4 {
  margin: 10px;
  color:  rgb(29, 31, 30);
  font-family: 'Poppins', sans-serif!important;
}


.contact-admin-container th {
  background-color: #d5eefc;
  margin: 10px;
  color:rgb(44, 44, 44);
  width: fit-content;
  }

.contact-admin-container tr {
  background-color: white;
  color: rgb(44, 44, 44);
  border-top:1px solid rgb(44, 44, 44);
  border-bottom:1px solid rgb(44, 44, 44);
  width: fit-content;
  }

  

  .contact-admin-container table{
    border: 0px;
  }


`, "",{"version":3,"sources":["webpack://./src/styles/screens/ContactAdmin.css"],"names":[],"mappings":"AAAA,4DAA4D;;AAE5D;EACE,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,eAAe;EACf,4CAA4C;AAC9C;;AAEA;EACE,YAAY;EACZ,uBAAuB;EACvB,4CAA4C;AAC9C;;;AAGA;EACE,yBAAyB;EACzB,YAAY;EACZ,qBAAqB;EACrB,kBAAkB;EAClB;;AAEF;EACE,uBAAuB;EACvB,sBAAsB;EACtB,oCAAoC;EACpC,uCAAuC;EACvC,kBAAkB;EAClB;;;;EAIA;IACE,WAAW;EACb","sourcesContent":["/* Add a background color and some padding around the form */\n\n.contact-admin-container {\n  min-height: 400px;\n  height: fit-content;\n  width: fit-content;\n  margin-top: 0px;\n}\n\n.contact-admin-container h1 {\n  font-size: 36px;\n  margin: 10px;  \n  color:  #0288d1;\n  font-family: 'Poppins', sans-serif!important;\n}\n\n.contact-admin-container h4 {\n  margin: 10px;\n  color:  rgb(29, 31, 30);\n  font-family: 'Poppins', sans-serif!important;\n}\n\n\n.contact-admin-container th {\n  background-color: #d5eefc;\n  margin: 10px;\n  color:rgb(44, 44, 44);\n  width: fit-content;\n  }\n\n.contact-admin-container tr {\n  background-color: white;\n  color: rgb(44, 44, 44);\n  border-top:1px solid rgb(44, 44, 44);\n  border-bottom:1px solid rgb(44, 44, 44);\n  width: fit-content;\n  }\n\n  \n\n  .contact-admin-container table{\n    border: 0px;\n  }\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
