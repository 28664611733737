import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  Row,
  Col,
  Divider,
  message,
  Select,
  InputNumber,
} from "antd";
import {
  SaveOutlined,
  SendOutlined,
  PrinterOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import "./AntdForm.css";
import BASE_URLS from "../constants";

const { Title } = Typography;
const { Option } = Select;

const PrescriptionForm = ({ patientId, doctorId, closeModal }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [medicines, setMedicines] = useState([]);
  const [diagnoses, setDiagnoses] = useState([]);
  const [phamarcies, setPharmacies] = useState([]);

  const initialValues = {
    diagnosis_id: null,
    doctor_id: doctorId,
    dose: "",
    dose_unit: "",
    duration_value: 0,
    duration_unit: "days",
    frequency: "",
    medicine_id: null,
    pharmacist_permission: null,
    prescription_description: "",
    quantity: 0,
    quantity_unit: "",
    refill: "",
    route: "",
  };

  useEffect(() => {
    const fetchDiagnoses = async () => {
      if (!patientId || !doctorId) {
        message.error("Missing patient ID or doctor ID.");
        return;
      }

      setLoading(true);
      try {
        const response = await axios.post(
          `${BASE_URLS.PRODUCTION}/getDiagnosisByPatientIdAndDoctorId`,
          { patientId, doctorId }
        );
        console.log("Diagnoses fetched:", response.data);
        setDiagnoses(response.data);
      } catch (error) {
        console.error("Error fetching diagnoses:", error);
        message.error("Error fetching diagnoses.");
      } finally {
        setLoading(false);
      }
    };

    fetchDiagnoses();
  }, [patientId, doctorId]);

  useEffect(() => {
    const fetchMedicines = async () => {
      try {
        const response = await axios.get(
          `${BASE_URLS.PRODUCTION}/getMedicines`
        );
        console.log("Medicines:", response.data);
        setMedicines(response.data);
      } catch (error) {
        console.error("Error fetching medicines:", error);
        message.error("Error fetching medicines");
      }
    };

    fetchMedicines();
  }, []);

  useEffect(() => {
    const fetchPharmacies = async () => {
      try {
        const response = await axios.get(
          `${BASE_URLS.PRODUCTION}/getPharmacies`
        );
        console.log("Pharmacies:", response.data);
        setPharmacies(response.data);
      } catch (error) {
        console.error("Error fetching Pharmacies:", error);
        message.error("Error fetching Pharmacies");
      }
    };

    fetchPharmacies();
  }, []);

  const savePrescription = async (values) => {
    const { duration_value, duration_unit, ...rest } = values;

    const data = {
      ...rest,
      duration: `${duration_value} ${duration_unit}`,
      patient_id: patientId,
      doctor_id: doctorId,
      prescription_creation_time: moment().format("YYYY-MM-DDTHH:mm"),
    };

    console.log("Prescription data:", data);

    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URLS.PRODUCTION}/savePrescription`,
        data
      );
      message.success("Prescription saved successfully");
      if (closeModal) closeModal();
    } catch (error) {
      console.error("Error saving prescription:", error);
      message.error("Error saving prescription");
    } finally {
      setLoading(false);
    }
  };

  const sendToPharmacist = async () => {
    const values = await form.validateFields();
    console.log("Sending data to pharmacist:", values);
    savePrescription(values);
  };

  const printForm = () => {
    window.print();
  };

  return (
    <Card
      className="antd-style-form"
      style={{ border: "0", maxWidth: 900, minWidth: 600, margin: "0 0" }}
    >
      <Button
        className="antd-style-form-close-button"
        type="text"
        icon={<CloseOutlined className="antd-style-form-close-icon" />}
        onClick={closeModal}
      />
      <Title
        className="antd-style-form-title"
        level={3}
        style={{ textAlign: "center" }}
      >
        Prescription Form
      </Title>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={savePrescription}
      >
        {/* Diagnosis Section */}
        <Title level={4} className="antd-style-form-subtitle">
          Diagnosis Information
        </Title>
        <Divider className="antd-style-form-divider" />
        <Row gutter={16} className="antd-style-form-row">
          <Col span={12}>
            <Form.Item
              label="Diagnosis"
              name="diagnosis_id"
              rules={[
                { required: true, message: "Please select a diagnosis!" },
              ]}
            >
              <Select
                placeholder="Select a diagnosis"
                className="antd-style-form-input"
                allowClear
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {diagnoses.map((diagnosis) => (
                  <Option key={diagnosis.id} value={diagnosis.id}>
                    {diagnosis.description}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Medicine"
              name="medicine_id"
              rules={[{ required: true, message: "Please select a medicine!" }]}
            >
              <Select
                placeholder="Select a medicine"
                className="antd-style-form-input"
                allowClear
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {medicines.map((medicine) => (
                  <Option
                    key={medicine.medicine_id}
                    value={medicine.medicine_id}
                  >
                    {medicine.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Medication Details Section */}
        <Title level={4} className="antd-style-form-subtitle">
          Medication Details
        </Title>
        <Divider className="antd-style-form-divider" />
        {/* Dose and Unit Row */}
        <Row gutter={16} className="antd-style-form-row">
          <Col span={12}>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  label="Dose"
                  name="dose"
                  className="antd-style-form-item"
                >
                  <InputNumber
                    min={0}
                    style={{ width: "100%" }}
                    className="antd-style-form-input"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Unit"
                  name="dose_unit"
                  className="antd-style-form-item"
                >
                  <Select
                    className="antd-style-form-input"
                    allowClear
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  >
                    <Option value="mg">mg</Option>
                    <Option value="g">g</Option>
                    <Option value="mcg">mcg</Option>
                    <Option value="mL">mL</Option>
                    <Option value="IU">IU</Option>
                    <Option value="mg_per_kg">mg/kg</Option>
                    <Option value="mg_per_m2">mg/m²</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Route"
              name="route"
              className="antd-style-form-item"
              // rules={[{ required: true, message: "Please select a route!" }]}
            >
              <Select
                placeholder="Select a route"
                className="antd-style-form-input"
                allowClear
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                <Option value="Oral">Oral</Option>
                <Option value="Intravenous">Intravenous (IV)</Option>
                <Option value="Intramuscular">Intramuscular (IM)</Option>
                <Option value="Subcutaneous">Subcutaneous (SC)</Option>
                <Option value="Inhalation">Inhalation</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Frequency and Duration Row */}
        <Row gutter={16} className="antd-style-form-row">
          <Col span={12}>
            <Form.Item
              label="Frequency"
              name="frequency"
              className="antd-style-form-item"
            >
              <Input className="antd-style-form-input" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  label="Duration"
                  name="duration_value"
                  className="antd-style-form-item"
                >
                  <InputNumber
                    min={0}
                    style={{ width: "100%" }}
                    className="antd-style-form-input"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Unit"
                  name="duration_unit"
                  className="antd-style-form-item"
                >
                  <Select
                    className="antd-style-form-input"
                    allowClear
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  >
                    <Option value="days">Days</Option>
                    <Option value="hours">Hours</Option>
                    <Option value="weeks">Weeks</Option>
                    <Option value="months">Months</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Quantity Row */}
        <Row gutter={16} className="antd-style-form-row">
          <Col span={12}>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  label="Quantity"
                  name="quantity"
                  className="antd-style-form-item"
                >
                  <InputNumber
                    min={0}
                    style={{ width: "100%" }}
                    className="antd-style-form-input"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Unit"
                  name="quantity_unit"
                  className="antd-style-form-item"
                >
                  <Select
                    className="antd-style-form-input"
                    allowClear
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  >
                    <Option value="tablets">Tablets</Option>
                    <Option value="capsules">Capsules</Option>
                    <Option value="ampoules">Ampoules</Option>
                    <Option value="vials">Vials</Option>
                    <Option value="drops">Drops</Option>
                    <Option value="puffs">Puffs</Option>
                    <Option value="tubes">Tubes</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Refill"
              name="refill"
              className="antd-style-form-item"
            >
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                className="antd-style-form-input"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Pharmacy"
              name="pharmacist_permission"
              className="antd-style-form-item"
            >
              <Select
                className="antd-style-form-input"
                allowClear
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {phamarcies.map((pharmacy) => (
                  <Option key={pharmacy.id} value={pharmacy.id}>
                    {pharmacy.fName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Description Field */}
        <Form.Item label="Description" name="prescription_description">
          <Input.TextArea rows={2} className="antd-style-form-input" />
        </Form.Item>

        <Divider className="antd-style-form-divider" />

        {/* Time Section */}
        <Form.Item label="Creation Time">
          <Input
            type="text"
            className="antd-style-form-input-readonly"
            value={moment().format("YYYY-MM-DD HH:mm")}
            readOnly
            disabled
          />
        </Form.Item>

        {/* Action Buttons */}
        <Form.Item className="antd-style-form-button-row">
          <Row justify="end" gutter={16}>
            <Col>
              <Button
                className="antd-style-form-button"
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
                loading={loading}
              >
                Save
              </Button>
            </Col>
            <Col>
              <Button
                className="antd-style-form-button"
                type="primary"
                icon={<SendOutlined />}
                onClick={sendToPharmacist}
                loading={loading}
              >
                Send to Pharmacist
              </Button>
            </Col>
            <Col>
              <Button
                className="antd-style-form-button-white"
                icon={<PrinterOutlined />}
                onClick={printForm}
              >
                Print
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default PrescriptionForm;
