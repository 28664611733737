// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.common-card {
    background-color: #F3F3F3;
    padding: 1rem;
    border-radius: 12px;
    margin: 10px 10px;
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.common-card-content {
    /* margin-top: 10px; */
}

/* .dividing-line {
    border: 1px solid #E5E5E5;
    margin: 10px 0;
} */`, "",{"version":3,"sources":["webpack://./src/screens/DoctorNew/patient-details/CommonCard.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;;;GAGG","sourcesContent":[".common-card {\n    background-color: #F3F3F3;\n    padding: 1rem;\n    border-radius: 12px;\n    margin: 10px 10px;\n}\n\n.header-container {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.common-card-content {\n    /* margin-top: 10px; */\n}\n\n/* .dividing-line {\n    border: 1px solid #E5E5E5;\n    margin: 10px 0;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
