import React, { useState } from "react";
import axios from "axios";

const OralCancerPrediction = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [predictionResult, setPredictionResult] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setPredictionResult(null);

    if (!selectedFile) {
      alert("Please select an image file.");
      return;
    }

    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      setIsLoading(true); // Start loading

      const response = await axios.post(
        "https://oral-cancer-detection-model-f3287fe29612.herokuapp.com/predict",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        const data = response.data;
        setPredictionResult(data.prediction); // Adjust based on your API response
        console.log("Prediction Result:", data);
      } else {
        setErrorMessage("Failed to get the result.");
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(
        error.response?.data?.error ||
          "An error occurred while processing the image."
      );
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#121212",
        color: "#ffffff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        margin: "0",
        padding: "20px",
      }}
    >
      <div
        className="oral-cancer-prediction-header"
        style={{
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        <h1
          style={{
            fontSize: "2.5em",
            margin: "0",
            color: "#ffffff",
          }}
        >
          Oral Cancer Detection
        </h1>
        <p
          style={{
            fontSize: "1.2em",
            color: "#bbbbbb",
            marginTop: "10px",
          }}
        >
          Upload an image to detect oral cancer
        </p>
      </div>

      <form
        className="oral-cancer-prediction-upload-form"
        id="upload-form"
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        style={{
          backgroundColor: "#1e1e1e",
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          width: "100%",
          maxWidth: "400px",
        }}
      >
        <h2
          style={{
            marginTop: "0",
            marginBottom: "15px",
            color: "#ffffff",
          }}
        >
          Upload Image
        </h2>
        <div
          className="oral-cancer-prediction-upload-area"
          style={{
            textAlign: "center",
            border: "2px dashed #ccc",
            borderRadius: "10px",
            padding: "40px",
            backgroundColor: "#f1f7ff",
            marginBottom: "20px",
            position: "relative",
          }}
        >
          <div
            className="oral-cancer-prediction-icon"
            style={{
              marginBottom: "20px",
            }}
          >
            {/* You can replace this with an actual icon if desired */}
            📷
          </div>
          <p
            style={{
              fontSize: "16px",
              color: "#333",
              marginBottom: "10px",
            }}
          >
            Drag and drop an image here or click to browse
          </p>
          <input
            type="file"
            id="file-upload"
            name="file-upload"
            accept="image/*"
            required
            onChange={handleFileChange}
            style={{
              display: "none",
            }}
          />
          <label
            htmlFor="file-upload"
            style={{
              backgroundColor: "#4c8dff",
              color: "white",
              padding: "10px 20px",
              borderRadius: "25px",
              border: "none",
              cursor: "pointer",
              display: "inline-block",
              marginTop: "10px",
            }}
          >
            Browse
          </label>
          <div
            className="oral-cancer-prediction-supported-files"
            style={{
              marginTop: "10px",
              fontSize: "12px",
              color: "#888",
            }}
          >
            Supported formats: JPG, PNG
          </div>
        </div>

        {previewImage && (
          <div
            className="oral-cancer-prediction-preview"
            style={{
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={previewImage}
              alt="Preview"
              style={{
                maxWidth: "100%",
                maxHeight: "200px",
                borderRadius: "8px",
              }}
            />
          </div>
        )}

        <button
          type="submit"
          style={{
            width: "100%",
            padding: "10px 20px",
            backgroundColor: "#007bff",
            color: "#ffffff",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            fontSize: "16px",
          }}
          disabled={isLoading}
        >
          {isLoading ? "Processing..." : "Check"}
        </button>
      </form>

      {predictionResult && (
        <div
          className="oral-cancer-prediction-result"
          style={{
            marginTop: "20px",
            fontSize: "1.5em",
            color: "#00ff00",
            textAlign: "center",
          }}
        >
          Prediction: {predictionResult}
        </div>
      )}

      {errorMessage && (
        <div
          className="oral-cancer-prediction-error"
          style={{
            marginTop: "20px",
            fontSize: "1em",
            color: "#ff4d4f",
            textAlign: "center",
          }}
        >
          Error: {errorMessage}
        </div>
      )}
    </div>
  );
};

export default OralCancerPrediction;
