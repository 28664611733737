import React from "react";

const PrivacyPolicy = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
        marginLeft: "400px",
        marginRight: "400px",
      }}
    >
      <h1 style={{ textAlign: "left", marginBottom: "20px" }}>
        Privacy Policy for Intelligent e-Hospital
      </h1>
      <p style={{ textAlign: "left", marginBottom: "20px" }}>
        <strong>Effective Date:</strong> 2024 Nov 28
      </p>
      <p style={{ textAlign: "left", marginBottom: "20px" }}>
        Thank you for using Intelligent e-Hospital. Your privacy is important to
        us. This privacy policy explains how we handle your data when you use
        this application.
      </p>
      <h2 style={{ textAlign: "left", marginBottom: "20px" }}>
        1. Data Collection
      </h2>
      <p style={{ textAlign: "left", marginBottom: "20px" }}>
        Intelligent e-Hospital app does not collect, process, store, or share
        any personal or sensitive user data. This app is a mobile interface to
        the e-Hospital Platform, for the user to access limited features of the
        e-Hospital Platform that would not involved any personal or sensitive
        information.
      </p>
      <h2 style={{ textAlign: "left", marginBottom: "20px" }}>2. Data Usage</h2>
      <p style={{ textAlign: "left", marginBottom: "20px" }}>
        Since we do not collect any data, there is no user data to process,
        analyze, or utilize in any way.
      </p>
      <h2 style={{ textAlign: "left", marginBottom: "20px" }}>
        3. Third-Party Services
      </h2>
      <p style={{ textAlign: "left", marginBottom: "20px" }}>
        This application does not integrate with or share data with any
        third-party services.
      </p>
      <h2 style={{ textAlign: "left", marginBottom: "20px" }}>
        4. Changes to This Policy
      </h2>
      <p style={{ textAlign: "left", marginBottom: "20px" }}>
        This privacy policy may be updated in the future to reflect changes in
        the application's functionality. We recommend reviewing this policy
        periodically.
      </p>
      <h2 style={{ textAlign: "left", marginBottom: "20px" }}>
        5. Contact Information
      </h2>
      <p style={{ textAlign: "left", marginBottom: "20px" }}>
        If you have any questions or concerns about this privacy policy, please
        contact us at:
        <a
          href="mailto:aabbas@uottawa.ca"
          style={{ color: "#0066cc", textDecoration: "none" }}
        >
          {" "}
          aabbas@uottawa.ca
        </a>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
