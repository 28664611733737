// PharmaceuticalsManagement.jsx
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  TextField,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  Grid,
  IconButton,
  Stack,
  styled,
  useTheme,
  Card,
  CircularProgress,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle"; // 单独导入 DialogTitle
import MUIDataTable from "mui-datatables";
import {
  KeyboardDoubleArrowRight,
  TrendingUp, // 使用 TrendingUp 替代 ArrowUpward
  ArrowBack,
} from "@mui/icons-material";
import { readLoginData } from "../../../loginData";
import { ClinicalTrialsStatus } from "../Dashboard/DashUtils";
import { saveCurrentPath } from "../LocalNavigate";
import {
  getPharmaceuticals_DetailedActions,
  updateResponseReadStatus,
  updateRequestReadStatus,
  updateActionResponse,
} from "../../../api/user";

// 辅助函数：根据完成状态获取颜色
const getColorForCompleteStatus = (status) => {
  return status === "Yes" ? "success" : "error";
};

// 辅助函数：根据响应状态获取颜色
const getColorForResponseStatus = (status) => {
  switch (status) {
    case "Approved":
    case "Agreed":
      return "success";
    case "Rejected":
      return "error";
    case "Pending":
      return "warning";
    case "Read":
      return "success";
    case "Unread":
      return "error";
    case "Not yet received webstaff response":
    case "Response not yet received":
    case "You have not responded yet":
      return "info";
    case "Unprocessed":
      return "error"; // 红色
    default:
      return "default";
  }
};

// 样式定制：自定义 Select 组件
const CustomSelect = styled(Select)(({ theme }) => ({
  minWidth: 100,
  borderRadius: 8,
  "& .MuiSelect-select": {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    height: 22,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: theme.palette.grey[300],
    "&.Mui-focused": {
      backgroundColor: theme.palette.action.focus,
    },
  },
  "&.MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[500_32],
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

// 样式定制：自定义 MenuItem 组件
const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.grey[800],
  "&.Mui-selected": {
    backgroundColor: theme.palette.action.selected,
  },
  "&.Mui-selected:hover": {
    backgroundColor: theme.palette.action.selected,
  },
}));

// 样式定制：自定义 DialogTitle 组件
const CustomDialogTitleStyled = styled(DialogTitle)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: theme.typography.pxToRem(20),
  textAlign: "center",
  paddingTop: theme.spacing(3),
}));

// 样式定制：自定义 DialogActions 组件
const CustomDialogActionsStyled = styled(DialogActions)(({ theme }) => ({
  justifyContent: "center",
  paddingBottom: theme.spacing(3),
}));

// 样式定制：自定义 Button 组件
const CustomDialogButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  fontWeight: theme.typography.fontWeightBold,
  padding: theme.spacing(1, 3),
}));

// DashboardSummary 组件：显示摘要卡片
const DashboardSummary = ({ title, total, count, countLabel, onClick }) => {
  const theme = useTheme();

  return (
    <Grid item xs={12} md={4}>
      <Card
        sx={{
          position: "relative",
          p: 3,
          borderRadius: "19px",
          cursor: "default", // 移除卡片的点击手势
          "&:hover": {
            boxShadow: 6,
          },
        }}
      >
        {/* 标题和点击区域 */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="subtitle2">{title}</Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="caption"
              sx={{
                color: theme.palette.primary.main,
                mr: 1,
              }}
            >
              Click to view details
            </Typography>
            <IconButton
              sx={{
                color: theme.palette.primary.main, // 确保图标颜色为蓝色
              }}
              onClick={onClick} // 仅点击图标触发跳转
            >
              <KeyboardDoubleArrowRight />
            </IconButton>
          </Box>
        </Box>

        {/* 总动作数 */}
        <Typography variant="h3" sx={{ my: 2 }}>
          {total.toLocaleString()}
        </Typography>

        {/* 计数和图标 */}
        <Stack direction="row" alignItems="center" spacing={1}>
          {/* 仅设置图标颜色，不添加背景 */}
          <TrendingUp
            sx={{
              color:
                countLabel === "Unprocessed"
                  ? theme.palette.error.main // 红色
                  : count > 0
                  ? theme.palette.error.main // 红色
                  : theme.palette.success.main, // 绿色
            }}
          />
          <Typography component="span" variant="subtitle2">
            {count} {countLabel}
          </Typography>
        </Stack>
      </Card>
    </Grid>
  );
};

const PharmaceuticalsManagement = () => {
  const companyInfo = readLoginData();
  const navigate = useNavigate();
  const location = useLocation();
  const [actions, setActions] = useState({
    auditActions: [],
    inviteActions: [],
    applyActions: [],
  });
  const [viewMode, setViewMode] = useState("overall"); // "overall" 或 "details"
  const [tabIndex, setTabIndex] = useState(0); // 0: Audit, 1: Invitations, 2: Applications
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState("");
  const [dialogNote, setDialogNote] = useState("");
  const [selectedAction, setSelectedAction] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [overallData, setOverallData] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [inviteData, setInviteData] = useState([]);
  const [applyData, setApplyData] = useState([]);
  const [incompleteCounts, setIncompleteCounts] = useState({
    audit: 0,
    invite: 0,
    apply: 0,
  });
  const [dashboardSummary, setDashboardSummary] = useState([]);
  const [loading, setLoading] = useState(true); // 添加加载状态

  // 保存当前路径
  saveCurrentPath();

  useEffect(() => {
    const fetchActions = async () => {
      try {
        const actionsList = await getPharmaceuticals_DetailedActions(companyInfo.id);
        setActions(actionsList);
        processActions(actionsList);
      } catch (error) {
        console.error("获取临床试验数据时出错：", error);
      } finally {
        setLoading(false); // 数据加载完成后关闭加载状态
      }
    };
    fetchActions();
  }, [companyInfo.id]);

  // 处理获取到的动作数据
  const processActions = (actionsList) => {
    const overallDataTemp = [];
    const auditDataTemp = [];
    const inviteDataTemp = [];
    const applyDataTemp = [];

    let unreadAudit = 0;
    let unreadInvite = 0;
    let unprocessedApply = 0; // 未处理数

    // 处理审核动作（Audit）
    actionsList.auditActions.forEach((action) => {
      const response = action.responses
        ? action.responses.find((res) => res.ResponseUserType === 0)
        : null;
      const auditStatus = response
        ? response.ResponseStatus === 0
          ? "Approved"
          : "Rejected"
        : "Pending";
      const reviewer = response
        ? { name: response.fullName || response.responderName }
        : { name: "Not assigned" };
      const responseDate = response ? response.Timestamp : "Waiting for response";
      const webstaffReadStatus =
        action.requests[0].ReadStatus === 1 ? "Read" : "Unread";
      const yourReadStatus = response
        ? response.ReadStatus === 1
          ? "Read"
          : "Unread"
        : "Not yet received webstaff response";
      const companyNote = action.requests[0].Note || "No notes";
      const webstaffNote = response ? response.Note || "No notes" : "No notes";

      const auditDataItem = {
        ActionID: action.action.ActionID,
        trialName: action.trialName,
        submissionDate: action.action.Timestamp,
        reviewer: reviewer,
        webstaffReadStatus: webstaffReadStatus,
        auditStatus: auditStatus,
        auditDate: responseDate,
        yourReadStatus: yourReadStatus,
        companyNote: companyNote,
        webstaffNote: webstaffNote,
        ActionType: action.action.ActionType,
        IsCompleted: action.action.IsCompleted,
        Timestamp: action.action.Timestamp,
      };

      if (yourReadStatus === "Unread") {
        unreadAudit += 1;
      }

      auditDataTemp.push(auditDataItem);
      overallDataTemp.push(auditDataItem);
    });

    // 处理邀请动作（Invitations）
    actionsList.inviteActions.forEach((action) => {
      action.requests.forEach((request) => {
        const response = action.responses
          ? action.responses.find(
              (res) =>
                res.ResponseUserType === request.ReceivedUserType &&
                res.ResponseUserID === request.ReceivedUserID
            )
          : null;
        const responseStatus = response
          ? response.ResponseStatus === 0
            ? "Agreed"
            : "Rejected"
          : "Pending";
        const responseDate = response ? response.Timestamp : "Waiting for response";
        const recipientReadStatus =
          request.ReadStatus === 1 ? "Read" : "Unread";
        const yourReadStatus = response
          ? response.ReadStatus === 1
            ? "Read"
            : "Unread"
          : "Response not yet received";
        const companyNote = request.Note || "No notes";
        const recipientNote = response ? response.Note || "No notes" : "No notes";

        const recipientType = ["Web Staff", "Company", "Doctor", "Patient"][
          request.ReceivedUserType
        ];

        const inviteDataItem = {
          ActionID: action.action.ActionID,
          recipient: {
            id: request.ReceivedUserID,
            name: request.fullName,
            type: recipientType,
          },
          trialName: action.trialName,
          invitationDate: action.action.Timestamp,
          recipientReadStatus: recipientReadStatus,
          responseStatus: responseStatus,
          responseDate: responseDate,
          yourReadStatus: yourReadStatus,
          companyNote: companyNote,
          recipientNote: recipientNote,
          ActionType: action.action.ActionType,
          IsCompleted: action.action.IsCompleted,
          Timestamp: action.action.Timestamp,
        };

        if (yourReadStatus === "Unread") {
          unreadInvite += 1;
        }

        inviteDataTemp.push(inviteDataItem);
        overallDataTemp.push(inviteDataItem);
      });
    });

    // 处理申请动作（Applications）
    actionsList.applyActions.forEach((action) => {
      const request = action.requests.find((req) => req.IsPrimaryRequest);
      const response = action.responses
        ? action.responses.find(
            (res) =>
              res.ResponseUserType === 1 && res.ResponseUserID === companyInfo.id
          )
        : null;
      const yourResponse = response
        ? response.ResponseStatus === 0
          ? "Agreed"
          : "Rejected"
        : "Pending";
      const yourResponseDate = response
        ? response.Timestamp
        : "Will update after your response";
      const yourReadStatus =
        request.ReadStatus === 1 ? "Read" : "Unread";
      const applicantReadStatus = response
        ? response.ReadStatus === 1
          ? "Read"
          : "Unread"
        : "You have not responded yet";
      const applicantNote = request.Note || "No notes";
      const companyNote = response ? response.Note || "No notes" : "No notes";

      const applicantType = ["Web Staff", "Company", "Doctor", "Patient"][
        action.action.InitiatorType
      ];

      const applyDataItem = {
        ActionID: action.action.ActionID,
        trialName: action.trialName,
        applicant: {
          id: action.action.InitiatorID,
          name: action.action.initiatorName,
          type: applicantType,
        },
        applicationDate: action.action.Timestamp,
        yourReadStatus: yourReadStatus,
        yourResponse: yourResponse,
        yourResponseDate: yourResponseDate,
        applicantReadStatus: applicantReadStatus,
        applicantNote: applicantNote,
        companyNote: companyNote,
        ActionType: action.action.ActionType,
        IsCompleted: action.action.IsCompleted,
        Timestamp: action.action.Timestamp,
      };

      if (yourResponse === "Pending") {
        unprocessedApply += 1; // 未处理数
      }

      applyDataTemp.push(applyDataItem);
      overallDataTemp.push(applyDataItem);
    });

    setOverallData(overallDataTemp);
    setAuditData(auditDataTemp);
    setInviteData(inviteDataTemp);
    setApplyData(applyDataTemp);

    setDashboardSummary([
      {
        title: "Audit",
        total: auditDataTemp.length, // 总动作数
        count: unreadAudit, // 未读数
        countLabel: "Unread",
        type: "audit",
      },
      {
        title: "Invitations",
        total: inviteDataTemp.length, // 总动作数
        count: unreadInvite, // 未读数
        countLabel: "Unread",
        type: "invite",
      },
      {
        title: "Applications",
        total: applyDataTemp.length, // 总动作数
        count: unprocessedApply, // 未处理数
        countLabel: "Unprocessed",
        type: "apply",
      },
    ]);

    setIncompleteCounts({
      audit: unreadAudit,
      invite: unreadInvite,
      apply: unprocessedApply, // 未处理数
    });
  };

  // 关闭对话框
  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedAction(null);
    setDialogNote("");
    setSnackbarOpen(false);
  };

  // 确认对话框操作
  const handleDialogConfirm = async () => {
    if (selectedAction) {
      const timestamp = new Date().toISOString();

      try {
        const result = await updateActionResponse({
          actionId: selectedAction.ActionID,
          responseUserType: 1, // Company
          responseUserId: companyInfo.id,
          readStatus: false,
          responseStatus: dialogAction === "Agreed" ? 0 : 1,
          note: dialogNote,
          timestamp,
        });

        if (result === true) {
          if (viewMode === "details") {
            if (tabIndex === 2) {
              // Applications
              setApplyData((prev) =>
                prev.map((item) =>
                  item.ActionID === selectedAction.ActionID
                    ? {
                        ...item,
                        yourResponse: dialogAction,
                        yourResponseDate: timestamp,
                        applicantReadStatus: "Unread",
                        companyNote: dialogNote || "No notes",
                      }
                    : item
                )
              );
            }
          } else {
            // 总体视图
            setOverallData((prev) =>
              prev.map((item) =>
                item.ActionID === selectedAction.ActionID
                  ? {
                      ...item,
                      yourResponse: dialogAction,
                      yourResponseDate: timestamp,
                      applicantReadStatus: "Unread",
                      companyNote: dialogNote || "No notes",
                    }
                  : item
              )
            );
          }

          setSnackbarMessage(
            `Response to ${
              selectedAction.applicant
                ? selectedAction.applicant.name
                : selectedAction.reviewer.name
            } successfully`
          );
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
        } else {
          setSnackbarMessage(
            `Failed to update response to ${
              selectedAction.applicant
                ? selectedAction.applicant.name
                : selectedAction.reviewer.name
            }`
          );
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      } catch (error) {
        setSnackbarMessage(
          `Failed to update response to ${
            selectedAction.applicant
              ? selectedAction.applicant.name
              : selectedAction.reviewer.name
          }`
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }

      setDialogOpen(false);
      setSelectedAction(null);
    }
  };

  // 取消对话框操作
  const handleDialogCancel = () => {
    setDialogOpen(false);
    setSelectedAction(null);
    setSnackbarMessage("Action cancelled");
    setSnackbarSeverity("info");
    setSnackbarOpen(true);
  };

  // 处理响应变更
  const handleResponseChange = (action, newValue) => {
    setSelectedAction(action);
    setDialogAction(newValue);
    setDialogOpen(true);
  };

  // 处理标签页变更
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // 处理摘要卡片点击
  const handleSummaryClick = (type) => {
    const index = actionTypes.findIndex((action) => action.type === type);
    if (index !== -1) {
      setViewMode("details");
      setTabIndex(index); // 0: Audit, 1: Invitations, 2: Applications
    }
  };

  // 返回总体视图
  const handleBackToOverall = () => {
    setViewMode("overall");
    setTabIndex(0);
  };

  // 更新阅读状态
  const updateReadStatus = async (action) => {
    if (viewMode === "details") {
      if (tabIndex === 0) {
        // Audit
        if (action.yourReadStatus === "Unread") {
          const result = await updateResponseReadStatus(
            action.ActionID,
            1,
            companyInfo.id
          );
          if (result === true) {
            setAuditData((prev) =>
              prev.map((item) =>
                item.ActionID === action.ActionID
                  ? { ...item, yourReadStatus: "Read" }
                  : item
              )
            );
            setSnackbarMessage("Your read status has been updated!");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
          }
        }
      } else if (tabIndex === 1) {
        // Invitations
        if (action.yourReadStatus === "Unread") {
          const result = await updateResponseReadStatus(
            action.ActionID,
            1,
            companyInfo.id
          );
          if (result === true) {
            setInviteData((prev) =>
              prev.map((item) =>
                item.ActionID === action.ActionID
                  ? { ...item, yourReadStatus: "Read" }
                  : item
              )
            );
            setSnackbarMessage("Your read status has been updated!");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
          }
        }
      } else if (tabIndex === 2) {
        // Applications
        if (action.yourReadStatus === "Unread") {
          const result = await updateRequestReadStatus(
            action.ActionID,
            1,
            companyInfo.id
          );
          if (result === true) {
            setAuditData((prev) =>
              prev.map((item) =>
                item.ActionID === action.ActionID
                  ? { ...item, yourReadStatus: "Read" }
                  : item
              )
            );
            setSnackbarMessage("Your read status has been updated!");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
          }
        }
      }
    }
  };

  // 表格列定义
  const columnsOverall = [
    {
      name: "trialName",
      label: "Trial Name",
    },
    {
      name: "ActionType",
      label: "Action Type",
      options: {
        customBodyRender: (value, tableMeta) => (
          <Typography
            variant="body2"
            color="primary"
            sx={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => {
              const type = value; // 0: Audit, 1: Invitations, 2: Applications
              setViewMode("details");
              setTabIndex(type);
            }}
          >
            {["Audit", "Invitations", "Applications"][value]}
          </Typography>
        ),
      },
    },
    {
      name: "IsCompleted",
      label: "Completion Status",
      options: {
        customBodyRender: (value) => (
          <ClinicalTrialsStatus
            color={getColorForCompleteStatus(value ? "Yes" : "No")}
            status={value ? "Completed" : "In Progress"}
          />
        ),
      },
    },
    {
      name: "Timestamp",
      label: "Time",
      options: {
        customBodyRender: (value) => new Date(value).toLocaleString(),
      },
    },
  ];

  const columnsAudit = [
    {
      name: "trialName",
      label: "Trial Name",
    },
    {
      name: "submissionDate",
      label: "Submission Date",
      options: {
        customBodyRender: (value) => new Date(value).toLocaleString(),
      },
    },
    {
      name: "reviewer",
      label: (
        <div>
          Reviewer
          <div style={{ fontSize: "small", color: "gray" }}>Web Staff</div>
        </div>
      ),
      options: {
        customBodyRender: (value) => <div>{value.name}</div>,
      },
    },
    {
      name: "webstaffReadStatus",
      label: "Webstaff Read Status",
      options: {
        customBodyRender: (value) => (
          <ClinicalTrialsStatus
            color={value === "Read" ? "success" : "error"}
            status={value}
          />
        ),
      },
    },
    {
      name: "auditStatus",
      label: "Audit Status",
      options: {
        customBodyRender: (value) => (
          <ClinicalTrialsStatus
            color={getColorForResponseStatus(value)}
            status={value}
          />
        ),
      },
    },
    {
      name: "auditDate",
      label: "Audit Date",
      options: {
        customBodyRender: (value) =>
          value !== "Waiting for response"
            ? new Date(value).toLocaleString()
            : "Waiting for response",
      },
    },
    {
      name: "yourReadStatus",
      label: (
        <div>
          Your Read Status
          <div style={{ fontSize: "small", color: "gray" }}>
            to the audit result
          </div>
        </div>
      ),
      options: {
        customBodyRender: (value) =>
          value !== "Not yet received webstaff response" ? (
            <ClinicalTrialsStatus
              color={getColorForResponseStatus(value)}
              status={value}
            />
          ) : (
            "Not yet received webstaff response"
          ),
      },
    },
  ];

  const columnsInvite = [
    {
      name: "trialName",
      label: "Trial Name",
    },
    {
      name: "recipient",
      label: "Recipient Name",
      options: {
        customBodyRender: (value) => (
          <div>
            <div>{value.name}</div>
            <div style={{ color: "gray", fontSize: "small" }}>{value.type}</div>
          </div>
        ),
      },
    },
    {
      name: "invitationDate",
      label: "Invitation Date",
      options: {
        customBodyRender: (value) => new Date(value).toLocaleString(),
      },
    },
    {
      name: "recipientReadStatus",
      label: (
        <div>
          Recipient Read Status
          <div style={{ fontSize: "small", color: "gray" }}>
            to your invitation
          </div>
        </div>
      ),
      options: {
        customBodyRender: (value) => (
          <ClinicalTrialsStatus
            color={value === "Read" ? "success" : "error"}
            status={value}
          />
        ),
      },
    },
    {
      name: "responseStatus",
      label: (
        <div>
          Response
          <div style={{ fontSize: "small", color: "gray" }}>
            from recipient
          </div>
        </div>
      ),
      options: {
        customBodyRender: (value) => (
          <ClinicalTrialsStatus
            color={getColorForResponseStatus(value)}
            status={value}
          />
        ),
      },
    },
    {
      name: "responseDate",
      label: "Response Date",
      options: {
        customBodyRender: (value) =>
          value !== "Waiting for response"
            ? new Date(value).toLocaleString()
            : "Waiting for response",
      },
    },
    {
      name: "yourReadStatus",
      label: (
        <div>
          Your Read Status
          <div style={{ fontSize: "small", color: "gray" }}>
            to the response
          </div>
        </div>
      ),
      options: {
        customBodyRender: (value) =>
          value !== "Response not yet received" ? (
            <ClinicalTrialsStatus
              color={getColorForResponseStatus(value)}
              status={value}
            />
          ) : (
            "Response not yet received"
          ),
      },
    },
  ];

  const columnsApply = [
    {
      name: "trialName",
      label: "Trial Name",
    },
    {
      name: "applicant",
      label: "Applicant Name",
      options: {
        customBodyRender: (value) => (
          <div>
            <div>{value.name}</div>
            <div style={{ color: "gray", fontSize: "small" }}>{value.type}</div>
          </div>
        ),
      },
    },
    {
      name: "applicationDate",
      label: "Application Date",
      options: {
        customBodyRender: (value) => new Date(value).toLocaleString(),
      },
    },
    {
      name: "yourReadStatus",
      label: (
        <div>
          Your Read Status
          <div style={{ fontSize: "small", color: "gray" }}>
            to the application
          </div>
        </div>
      ),
      options: {
        customBodyRender: (value) => (
          <ClinicalTrialsStatus
            color={value === "Read" ? "success" : "error"}
            status={value}
          />
        ),
      },
    },
    {
      name: "yourResponse",
      label: "Your Response",
      options: {
        customBodyRender: (value, tableMeta) =>
          value === "Pending" ? (
            <CustomSelect
              value={value}
              onChange={(event) =>
                handleResponseChange(
                  applyData[tableMeta.rowIndex],
                  event.target.value
                )
              }
              displayEmpty
            >
              <CustomMenuItem value="Pending">Select your response</CustomMenuItem>
              <CustomMenuItem value="Agreed">Agreed</CustomMenuItem>
              <CustomMenuItem value="Rejected">Rejected</CustomMenuItem>
            </CustomSelect>
          ) : (
            <ClinicalTrialsStatus
              color={getColorForResponseStatus(value)}
              status={value}
            />
          ),
      },
    },
    {
      name: "yourResponseDate",
      label: "Your Response Date",
      options: {
        customBodyRender: (value) =>
          value !== "Will update after your response"
            ? new Date(value).toLocaleString()
            : "Will update after your response",
      },
    },
    {
      name: "applicantReadStatus",
      label: (
        <div>
          Applicant's Read Status
          <div style={{ fontSize: "small", color: "gray" }}>
            to your response
          </div>
        </div>
      ),
      options: {
        customBodyRender: (value) =>
          value !== "You have not responded yet" ? (
            <ClinicalTrialsStatus
              color={getColorForResponseStatus(value)}
              status={value}
            />
          ) : (
            "You have not responded yet"
          ),
      },
    },
  ];

  // 动作类型定义
  const actionTypes = [
    { label: "Audit", type: "audit" },
    { label: "Invitations", type: "invite" },
    { label: "Applications", type: "apply" },
  ];

  // 根据标签页索引获取数据
  const getDataByTabIndex = () => {
    switch (tabIndex) {
      case 0:
        return auditData;
      case 1:
        return inviteData;
      case 2:
        return applyData;
      default:
        return [];
    }
  };

  // 根据标签页索引获取列定义
  const getColumnsByTabIndex = () => {
    switch (tabIndex) {
      case 0:
        return columnsAudit;
      case 1:
        return columnsInvite;
      case 2:
        return columnsApply;
      default:
        return [];
    }
  };

  const data = getDataByTabIndex();
  const columns = getColumnsByTabIndex();

  // 表格选项配置
  const options = {
    filter: false,
    responsive: "standard",
    selectableRows: "none",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    rowsPerPage: 10,
    pagination: true,
    customToolbarSelect: () => {},
    setRowProps: (row, dataIndex) => ({
      style: {
        backgroundColor: "#FFFFFF",
      },
    }),
    setCellProps: () => ({
      style: {
        padding: "10px 0",
        textAlign: "center",
        borderBottom: "none",
      },
    }),
    elevation: 0,
    customTableBodyFooterRender: () => null,
    customHeadRender: (columnMeta) => (
      <th
        style={{
          padding: "0",
          height: "0",
          borderBottom: "none",
          textAlign: "center",
        }}
      >
        {columnMeta.label}
      </th>
    ),
    expandableRows: viewMode === "details",
    expandableRowsHeader: false,
    onRowClick: (rowData, rowMeta) => {
      const action = data[rowMeta.dataIndex];
      updateReadStatus(action);
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const rowIndex = rowMeta.dataIndex;
      const action = data[rowIndex];
      if (viewMode === "overall") {
        // 总体视图：不显示展开内容
        return null;
      } else if (viewMode === "details") {
        return (
          <tr style={{ backgroundColor: "#FFFFFF", border: "none" }}>
            <td colSpan={rowData.length} style={{ border: "none" }}>
              <Box sx={{ padding: 2 }}>
                {tabIndex === 0 && (
                  <>
                    {/* Audit */}
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                      <Typography variant="body2" sx={{ textAlign: "left" }}>
                        <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
                          Your Note:
                        </span>{" "}
                        {action.companyNote}
                      </Typography>
                      <Typography variant="body2" sx={{ textAlign: "left" }}>
                        <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
                          Webstaff Note:
                        </span>{" "}
                        {action.webstaffNote}
                      </Typography>
                    </Box>
                  </>
                )}
                {tabIndex === 1 && (
                  <>
                    {/* Invitations */}
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                      <Typography variant="body2" sx={{ textAlign: "left" }}>
                        <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
                          Your Note:
                        </span>{" "}
                        {action.companyNote}
                      </Typography>
                      <Typography variant="body2" sx={{ textAlign: "left" }}>
                        <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
                          Recipient's Note:
                        </span>{" "}
                        {action.recipientNote}
                      </Typography>
                    </Box>
                  </>
                )}
                {tabIndex === 2 && (
                  <>
                    {/* Applications */}
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                      <Typography variant="body2" sx={{ textAlign: "left" }}>
                        <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
                          Applicant's Note:
                        </span>{" "}
                        {action.applicantNote}
                      </Typography>
                      <Typography variant="body2" sx={{ textAlign: "left" }}>
                        <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
                          Your Note:
                        </span>{" "}
                        {action.companyNote}
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </td>
          </tr>
        );
      }
    },
  };

  return (
    <Card
      sx={{
        position: "relative",
        borderRadius: "19px",
        height: "100%",
        width: "100%",
        backgroundColor: "#FFFFFF",
        boxShadow: 3,
        p: 3,
      }}
      bordered={false}
    >
      {viewMode === "overall" && (
        <>
          {/* 摘要卡片和总体表格在同一个 Card 内 */}
          <Grid container spacing={2} sx={{ mb: 4 }}>
            {loading ? (
              // 显示加载指示器
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 100,
                }}
              >
                <CircularProgress />
              </Grid>
            ) : dashboardSummary.length > 0 ? (
              // 显示摘要卡片
              dashboardSummary.map((summary, index) => (
                <DashboardSummary
                  key={index}
                  title={summary.title}
                  total={summary.total}
                  count={summary.count}
                  countLabel={summary.countLabel}
                  onClick={() => handleSummaryClick(summary.type)}
                />
              ))
            ) : (
              // 无数据时显示提示
              <Typography
                variant="h6"
                align="center"
                color="textSecondary"
                sx={{ width: "100%", mt: 3 }}
              >
                No data available
              </Typography>
            )}
          </Grid>
          {!loading && dashboardSummary.length > 0 && (
            // 显示总体表格
            <MUIDataTable
              title={""}
              data={overallData}
              columns={columnsOverall}
              options={{
                ...options,
                expandableRows: false, // 禁用展开行
              }}
              components={{
                TableCell: (props) => (
                  <td
                    {...props}
                    style={{
                      ...props.style,
                      borderBottom: "none",
                      textAlign: "center",
                      padding: "10px 0",
                    }}
                  />
                ),
                TableRow: (props) => (
                  <tr {...props} style={{ borderBottom: "none" }} />
                ),
              }}
            />
          )}
        </>
      )}

      {viewMode === "details" && (
        <>
          {/* 返回按钮 */}
          <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
            <IconButton onClick={handleBackToOverall}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">Back to overall</Typography>
          </Box>

          {/* 标签页 */}
          <Box sx={{ width: "100%", bgcolor: "background.paper", mb: 3 }}>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="pharmaceuticals management tabs"
            >
              {actionTypes.map((action, index) => (
                <Tab
                  key={action.type}
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="body2" sx={{ marginRight: 1 }}>
                        {action.label}
                      </Typography>
                      <ClinicalTrialsStatus
                        color={
                          incompleteCounts[action.type] > 0
                            ? "error"
                            : "primary"
                        }
                        status={incompleteCounts[action.type]}
                      />
                    </Box>
                  }
                />
              ))}
            </Tabs>
          </Box>

          {/* 表格 */}
          {loading ? (
            // 显示加载指示器
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 100,
              }}
            >
              <CircularProgress />
            </Box>
          ) : data.length > 0 ? (
            // 显示详细表格
            <MUIDataTable
              key={tabIndex}
              title={""}
              data={data}
              columns={columns}
              options={options}
              components={{
                TableCell: (props) => (
                  <td
                    {...props}
                    style={{
                      ...props.style,
                      borderBottom: "none",
                      textAlign: "center",
                      padding: "10px 0",
                      backgroundColor: "#FFFFFF", // 确保单元格背景为白色
                    }}
                  />
                ),
                TableRow: (props) => (
                  <tr
                    {...props}
                    style={{
                      borderBottom: "none",
                      backgroundColor: "#FFFFFF", // 确保行背景为白色
                    }}
                  />
                ),
              }}
            />
          ) : (
            // 无数据时显示提示
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              sx={{ width: "100%", mt: 3 }}
            >
              No data available
            </Typography>
          )}
        </>
      )}

      {/* 对话框 */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        PaperProps={{
          style: {
            borderRadius: 20,
          },
        }}
      >
        <CustomDialogTitleStyled>Confirm Action</CustomDialogTitleStyled>
        <DialogContent>
          <DialogContentText
            sx={{ textAlign: "center", fontSize: 16, fontWeight: 400 }}
          >
            Are you sure you want to {dialogAction} this request?
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="note"
            label="Note"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            value={dialogNote}
            onChange={(e) => setDialogNote(e.target.value)}
          />
        </DialogContent>
        <CustomDialogActionsStyled>
          <CustomDialogButton
            variant="contained"
            color="primary"
            onClick={handleDialogConfirm}
          >
            Confirm
          </CustomDialogButton>
          <CustomDialogButton
            variant="outlined"
            color="inherit"
            onClick={handleDialogCancel}
          >
            Cancel
          </CustomDialogButton>
        </CustomDialogActionsStyled>
      </Dialog>

      {/* Snackbar 通知 */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default PharmaceuticalsManagement;
