// userActions.js

import { writeLoginData, clearLoginData, readLoginData } from "../loginData";

export function loadUser(setState, data) {
    const userInfo = {
        type: data.type,
        id: data.id,
        name: data.name,
        email: data.email,
        startInPage: data.startInPage,
    };
    writeLoginData(userInfo, true);
    setState({
        user: userInfo,
    });
}

export function loadTempUser(setState, data) {
    const userInfo = {
        type: data.type,
        id: data.id,
        name: data.name,
        email: data.email,
        startInPage: data.startInPage,
    };
    writeLoginData(userInfo, false);
    setState({
        user: userInfo,
    });
}

export function clearUser(setState) {
    const loginData = readLoginData();
    if (loginData.type === "Patient") {
        fetch(
            "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/api/users/inactiveUser",
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: loginData.email,
                }),
            }
        )
            .then((res) => {
                if (res.status === 200) {
                    clearLoginData();
                } else {
                    throw new Error("Error in processing request");
                }
            })
            .catch((error) => {
                console.error("There was an error during the fetch:", error);
            });
        setState({
            user: {
                type: "NotLoggedIn",
                id: -1,
                name: "",
                email: "",
                startInPage: "",
            },
        });
    } else {
        clearLoginData();
        setState({
            user: {
                type: "NotLoggedIn",
                id: -1,
                name: "",
                email: "",
                startInPage: "",
            },
        });
    }
}