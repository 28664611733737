import React, { useState } from "react";
import "./DoctorDashboard.css";
import { Button, Card, Col, Flex, Row } from "antd";
import { useNavigate } from "react-router-dom";
import RecentPatientList from "./RecentPatientList/RecentPatientList";
import DoctorMiniCalendar from "./DoctorMiniCalendar";
import DoctorUpcomingTasks from "./DoctorUpcomingTasks";
import DoctorAppointmentListMobile from "./DoctorAppointmentListMobile";
const LeftColumn = ({ selectedDate, setSelectedDate }) => {
  let width = window.innerWidth;
  if (width > 605) {
    return (
      <>
        <Card
          bordered={false}
          style={{
            width: "100%",
            backgroundColor: "#1a4fba",
          }}
        >
          <div
            style={{
              color: "#FFFFFF",
              fontSize: "1.65rem",
              textAlign: "left",
            }}
          >
            Hello <span style={{ color: "white" }}>Dr.Smith,</span>
          </div>
          <p
            style={{
              color: "#d4e3ef",
              marginTop: "1rem",
              fontSize: "1rem",
              textAlign: "left",
            }}
          >
            Have a good day at work.
          </p>
        </Card>
        <div>
          <RecentPatientList
            selectedDate={selectedDate}
            onSelectedDate={(d) => setSelectedDate(d)}
          />
        </div>
      </>
    );
  } else {
    return (
      <div className="doctor-dashboard-mobile-appointment-list">
        <DoctorAppointmentListMobile selectedDate={selectedDate} />
      </div>
    );
  }
};
const RightColumn = ({
  navigate,
  dataRefresh,
  setDataRefresh,
  selectedDate,
  setSelectedDate,
}) => {
  const handleDataChange = () => {
    setDataRefresh(true);
    setTimeout(() => {
      setDataRefresh(false);
    });
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Button
            onClick={() => navigate("/searchpatient")}
            className="doctor-dashboard-quick-link-button"
          >
            Diagnose
          </Button>
        </Col>
        <Col span={12}>
          <Button
            onClick={() => navigate("/services")}
            className="doctor-dashboard-quick-link-button"
          >
            Geneal Services
          </Button>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Button
            onClick={() => navigate("/RadialHealthGraph")}
            className="doctor-dashboard-quick-link-button"
          >
            RadialHealthGraph
          </Button>
        </Col>
      </Row>
      <Card
        bordered={false}
        elevation={0}
        style={{ elevation: 0 }}
        className="doctor-dashboard-right-column-card"
      >
        {dataRefresh ? null : (
          <>
            <DoctorMiniCalendar
              date={selectedDate}
              onSelectDate={(date) => setSelectedDate(date)}
            />
            <DoctorUpcomingTasks
              date={selectedDate}
              onDataChange={handleDataChange}
            />
          </>
        )}
      </Card>
    </>
  );
};

const DoctorDashboard = () => {
  const navigate = useNavigate();
  const [dataRefresh, setDataRefresh] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const context = {
    navigate,
    dataRefresh,
    setDataRefresh,
    selectedDate,
    setSelectedDate,
  };

  return (
    <div
      class="doctor-dashboard-screen"
      style={{ height: "100%", gap: "1.5rem" }}
    >
      <div class="doctor-dashboard-column doctor-dashboard-left-column">
        {LeftColumn(context)}
      </div>
      <div
        class="doctor-dashboard-column doctor-dashboard-right-column"
        style={{
          minWidth: "10rem",
        }}
      >
        {RightColumn(context)}
      </div>
    </div>
  );
};

export default DoctorDashboard;
