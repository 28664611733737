import React, { useState, useEffect } from "react";
import axios from "axios";
import { Typography, IconButton, Divider, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CommonCard from "./CommonCard";
import BASE_URLS from "./constants";

const VisitHistory = ({ doctorId, patientId }) => {
  const [patientVisits, setPatientVisits] = useState([]);

  useEffect(() => {
    const fetchPatientVisits = async () => {
      try {
        const response = await axios.post(
          `${BASE_URLS.PRODUCTION}/patientVisits`,
          {
            doctorId: doctorId,
            patientId: patientId,
          }
        );
        let data = response.data;
        console.log("VisitHistory:", data);
        data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setPatientVisits(data);
      } catch (error) {
        console.error("Error fetching patient visits:", error);
      }
    };
    fetchPatientVisits();
  }, [patientId, doctorId]);

  return (
    <CommonCard collapsible={true} title="Visits">
      <div style={{ backgroundColor: "transparent" }}>
        {patientVisits.map((visit, index) => (
          <React.Fragment key={visit.visit_id}>
            <VisitItem index={index} visit={visit} />
            {index < patientVisits.length - 1 && (
              <Divider style={{ backgroundColor: "#efefef" }} />
            )}
          </React.Fragment>
        ))}
      </div>
    </CommonCard>
  );
};

const VisitItem = ({ visit, index }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  // Prepare the entries to display, filtering out null or undefined values
  const entries = [
    { label: "Doctor ID", value: visit.doctor_id },
    { label: "Start Time", value: visit.start_time },
    { label: "End Time", value: visit.end_time },
    { label: "Reason for Visit", value: visit.reason_for_visit },
    { label: "Observations", value: visit.observations },
  ].filter((entry) => entry.value !== null && entry.value !== undefined);

  return (
    <div style={{ textAlign: "left" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          padding: "8px 0",
          backgroundColor: "transparent",
        }}
        onClick={handleToggle}
      >
        <Typography variant="body1" style={{ flexGrow: 1 }}>
          {`${index + 1}. ${new Date(visit.date).toLocaleDateString()}`}
        </Typography>
        <IconButton
          size="small"
          style={{
            transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s",
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div style={{ paddingLeft: 16, paddingBottom: 8, color: "#555" }}>
          {entries.map((entry, idx) => (
            <React.Fragment key={entry.label}>
              <div style={{ display: "flex", marginBottom: 4 }}>
                <Typography
                  variant="body2"
                  style={{ width: "130px", fontWeight: "bold" }}
                >
                  {entry.label}:
                </Typography>
                <Typography variant="body2">
                  {entry.value || "Empty"}
                </Typography>
              </div>
              {idx < entries.length - 1 && (
                <Divider
                  style={{
                    backgroundColor: "#f0f0f0",
                    height: 1,
                    width: "50%",
                    marginLeft: 0,
                    marginTop: 4,
                    marginBottom: 4,
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export default VisitHistory;
