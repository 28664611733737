// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-content {
    display: flex;
    justify-content: space-between;
}

.footer {
    background-color: rgb(28, 100, 231);
    padding: 2rem 8rem 2rem 8rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.footer img {
    width: 14rem;
}

.footer-block {
    color: #ffffff;
    flex-basis: calc(30% - 20px);
    /* Adjusting for margin */
    text-align: left;
}

/* Media Queries */
@media (max-width: 768px) {
    .footer {
        background-color: rgb(28, 100, 231);
        padding: 2rem 100px 2rem 50px;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .footer-content {
        flex-direction: column;
        gap: 2rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/LandingPage/footer/footer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,mCAAmC;IACnC,4BAA4B;IAC5B,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,4BAA4B;IAC5B,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA,kBAAkB;AAClB;IACI;QACI,mCAAmC;QACnC,6BAA6B;QAC7B,aAAa;QACb,sBAAsB;QACtB,SAAS;IACb;;IAEA;QACI,sBAAsB;QACtB,SAAS;IACb;AACJ","sourcesContent":[".footer-content {\n    display: flex;\n    justify-content: space-between;\n}\n\n.footer {\n    background-color: rgb(28, 100, 231);\n    padding: 2rem 8rem 2rem 8rem;\n    display: flex;\n    flex-direction: column;\n    gap: 2rem;\n}\n\n.footer img {\n    width: 14rem;\n}\n\n.footer-block {\n    color: #ffffff;\n    flex-basis: calc(30% - 20px);\n    /* Adjusting for margin */\n    text-align: left;\n}\n\n/* Media Queries */\n@media (max-width: 768px) {\n    .footer {\n        background-color: rgb(28, 100, 231);\n        padding: 2rem 100px 2rem 50px;\n        display: flex;\n        flex-direction: column;\n        gap: 2rem;\n    }\n\n    .footer-content {\n        flex-direction: column;\n        gap: 2rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
