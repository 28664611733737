import React from "react";
import { AiFillTwitterCircle, AiFillLinkedin } from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";
import { RiInstagramFill } from "react-icons/ri";
import "./footer.css";

const Pharmacyfooter = () => {
  return (
    <footer className="footer__section boxItems mt-5 pt-4 pb-4 bg-white b-1">
      <div className="footer__container">
        <p className="footer__left">E-hospital Pharmacy</p>
        <div className="footer-social">
          <div className="social__container">
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsFacebook className="footer-icon" />
            </a>
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <RiInstagramFill className="footer-icon" />
            </a>
            <a
              href="https://twitter.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillTwitterCircle className="footer-icon" />
            </a>
            <a
              href="https://www.linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillLinkedin className="footer-icon" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Pharmacyfooter;
