import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  useTheme,
  Card
} from "@mui/material";
import { alpha, styled } from '@mui/material/styles';
import {
  updateRequestReadStatus,
  updateActionResponse,
  getWebStaffActions,
} from "../../api/user";
import { readLoginData } from "../../loginData";
import PageLayout from "./PageLayout";
// 辅助函数：根据完成状态获取颜色
const getColorForCompleteStatus = (status) => {
  return status === "Yes" ? "success" : "error";
};

// 辅助函数：根据响应状态获取颜色
const getColorForResponseStatus = (status) => {
  switch (status) {
    case "Approved":
    case "Agreed":
    case "Read":
      return "success"; // 绿色
    case "Rejected":
      return "error"; // 红色
    case "Unread":
      return "warning"; // 黄色
    case "Pending":
    case "Not yet received webstaff response":
    case "Response not yet received":
    case "You have not responded yet":
      return "info"; // 蓝色
    case "Unprocessed":
      return "warning"; // 黄色
    default:
      return "default";
  }
};

const StatusRootStyle = styled('span')(({ theme, ownerState }) => {
    const isLight = theme.palette.mode === 'light';
    const { color, variant } = ownerState;
  
    const styleFilled = (color) => ({
      color: theme.palette[color].contrastText,
      backgroundColor: theme.palette[color].main,
    });
  
    const styleOutlined = (color) => ({
      color: theme.palette[color].main,
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette[color].main}`,
    });
  
    const styleGhost = (color) => ({
      color: theme.palette[color][isLight ? 'dark' : 'light'],
      backgroundColor: alpha(theme.palette[color].main, 0.16),
    });
  
    return {
      height: 22,
      minWidth: 100, // 增加固定宽度
      lineHeight: 0,
      borderRadius: 8,
      cursor: 'default',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      display: 'inline-flex',
      justifyContent: 'center',
      padding: theme.spacing(0, 1),
      color: theme.palette.grey[800],
      fontSize: theme.typography.pxToRem(12),
      fontFamily: theme.typography.fontFamily,
      backgroundColor: theme.palette.grey[300],
      fontWeight: theme.typography.fontWeightBold,
  
      ...(color !== 'default'
        ? {
            ...(variant === 'filled' && { ...styleFilled(color) }),
            ...(variant === 'outlined' && { ...styleOutlined(color) }),
            ...(variant === 'ghost' && { ...styleGhost(color) }),
          }
        : {
            ...(variant === 'outlined' && {
              backgroundColor: 'transparent',
              color: theme.palette.text.primary,
              border: `1px solid ${theme.palette.grey[500_32]}`,
            }),
            ...(variant === 'ghost' && {
              color: isLight ? theme.palette.text.secondary : theme.palette.common.white,
              backgroundColor: theme.palette.grey[500_16],
            }),
          }),
    };
  });

const  ButtonStatus = ({ color, status }) => {
    const Label = ({ color = 'default', variant = 'ghost', children, ...other }) => {
      return (
        <StatusRootStyle ownerState={{ color, variant }} {...other}>
          {children}
        </StatusRootStyle>
      );
    };
    
    return (
      <Label variant="ghost" color={color}>
        {status}
      </Label>
    );
  };


// 样式定制：自定义 Select 组件
const CustomSelect = styled(Select)(({ theme }) => ({
  minWidth: 100,
  borderRadius: 8,
  "& .MuiSelect-select": {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    height: 22,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: theme.palette.grey[300],
    "&.Mui-focused": {
      backgroundColor: theme.palette.action.focus,
    },
  },
  "&.MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[500],
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

// 样式定制：自定义 MenuItem 组件
const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.grey[800],
  "&.Mui-selected": {
    backgroundColor: theme.palette.action.selected,
  },
  "&.Mui-selected:hover": {
    backgroundColor: theme.palette.action.selected,
  },
}));

// 样式定制：自定义 DialogTitle 组件
const CustomDialogTitleStyled = styled(DialogTitle)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: theme.typography.pxToRem(20),
  textAlign: "center",
  paddingTop: theme.spacing(3),
}));

// 样式定制：自定义 DialogActions 组件
const CustomDialogActionsStyled = styled(DialogActions)(({ theme }) => ({
  justifyContent: "center",
  paddingBottom: theme.spacing(3),
}));

// 样式定制：自定义 Button 组件
const CustomDialogButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  fontWeight: theme.typography.fontWeightBold,
  padding: theme.spacing(1, 3),
}));

const ClinicalStaffManagement = (props) => {
  const ClinicalStaffInfo = readLoginData();
  const theme = useTheme();
  const [actionsList, setActionsList] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(true);

  // 新增状态变量：管理对话框
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogActionId, setDialogActionId] = useState(null);
  const [dialogResponse, setDialogResponse] = useState(null);
  const [dialogNote, setDialogNote] = useState("");
  const [dialogAction, setDialogAction] = useState("");

  useEffect(() => {
    const fetchActions = async () => {
      try {
        const result = await getWebStaffActions(ClinicalStaffInfo.id);
        setActionsList(result.auditActions || []);
      } catch (error) {
        console.error("Error fetching actions:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchActions();
  }, [ClinicalStaffInfo.id]);

  const handleRowClick = async (action, index) => {
    if (action.requests[0].ReadStatus === 0) {
      // 更新为已读
      try {
        const result = await updateRequestReadStatus(
            action.action.ActionID,
            0,
            ClinicalStaffInfo.id
          );
          if (result === true) {
            setActionsList((prev) =>
              prev.map((item) => {
                // 检查 ActionID 是否匹配
                return item.action.ActionID === action.action.ActionID
                  ? {
                      ...item, // 保留原有 item 的其他属性
                      requests: [
                        {
                          ...item.requests[0], // 保留 requests[0] 的其他属性
                          ReadStatus: 1, // 更新 yourReadStatus 为 "Read"
                        },
                        ...item.requests.slice(1), // 保持 requests 中的其他项不变
                      ],
                    }
                  : item; // 如果 ActionID 不匹配，返回原 item
              })
            );
          
            setSnackbarMessage("Your read status has been updated!");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
          }
          
      } catch (error) {
        console.error("Failed to update read status:", error);
      }
    }
    
    // 展开或关闭行
    setExpandedRow(expandedRow === index ? null : index);
  };

  // 处理响应更改：打开对话框
  const handleResponseChange = (actionId, responseValue) => {
    setDialogActionId(actionId);
    setDialogResponse(responseValue);
    setDialogAction(responseValue === 0 ? "agree to" : "reject");
    setDialogNote("");
    setDialogOpen(true);
  };

  // 对话框确认操作
  const handleDialogConfirm = async () => {
    try {
        const timestamp = new Date().toISOString();
        const result = await updateActionResponse({
          actionId: dialogActionId,
          responseUserType: 0, 
          responseUserId: ClinicalStaffInfo.id,
          readStatus: false,
          responseStatus: dialogResponse,
          note: dialogNote,
          timestamp,
        });

        if (result === true) {
            setActionsList((prev) =>
                prev.map((item) =>
                  item.action.ActionID === dialogActionId
                    ? {
                        ...item,
                        responses: [
                          {
                            ResponseUserType: 0,
                            ResponseUserID: ClinicalStaffInfo.id,
                            ResponseStatus: dialogResponse,
                            ReadStatus: 0,
                            Timestamp: new Date().toISOString(),
                            Note: dialogNote,
                          },
                        ],
                      }
                    : item
                )
              );
              setSnackbarMessage("Response submitted successfully!");
              setSnackbarSeverity("success");
              setSnackbarOpen(true);
        }   
    } catch (error) {
      console.error("Error submitting response:", error);
      setSnackbarMessage("Failed to submit response.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setDialogOpen(false);
    }
  };

  // 对话框取消操作
  const handleDialogCancel = () => {
    setDialogOpen(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // 渲染状态按钮
  const renderStatusButton = (status) => {
    const color = getColorForResponseStatus(status);
    return (
      <ButtonStatus  color={color} status={status}>
        {status}
      </ButtonStatus>
    );
  };

  // 渲染申请人阅读状态
  const renderApplicantStatus = (responses) => {
    if (!responses || responses.length === 0) {
      const status = "You have not responded yet";
      const color = getColorForResponseStatus(status);
      return (
        <ButtonStatus  color={color} status={status}>
          {status}
        </ButtonStatus>
      );
    } else {
      const status = responses[0].ReadStatus === 1 ? "Read" : "Unread";
      const color = getColorForResponseStatus(status);
      return (
        <ButtonStatus  color={color} status={status}>
          {status}
        </ButtonStatus>
      );
    }
  };

  // 渲染响应选择框或按钮
  const renderResponseSelect = (action) => {
    const existingResponse = action.responses.find(
      (response) =>
        response.ResponseUserType === 0 && response.ResponseUserID === ClinicalStaffInfo.id
    );

    if (existingResponse) {
      const status = existingResponse.ResponseStatus === 0 ? "Agreed" : "Rejected";
      const color = getColorForResponseStatus(status);
      return (
        <ButtonStatus  color={color} status={status}>
          {status}
        </ButtonStatus>
      );
    } else {
      return (
        <CustomSelect
          value=""
          displayEmpty
          onChange={(e) =>
            handleResponseChange(action.action.ActionID, e.target.value)
          }
        >
          <CustomMenuItem value="" disabled>
            Select your response
          </CustomMenuItem>
          <CustomMenuItem value={0}>Agreed</CustomMenuItem>
          <CustomMenuItem value={1}>Rejected</CustomMenuItem>
        </CustomSelect>
      );
    }
  };

  return (
    <PageLayout clearUser={props.clearUser} className="clinicalstaff-management-page">
            <Card
      sx={{
        position: "relative",
        borderRadius: "19px",
        height: "100%",
        width: "100%",
        backgroundColor: "#FFFFFF",
        boxShadow: 3,
        p: 3,
      }}
      bordered={false}
    >
    <Box sx={{ p: 3 }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell sx={{ backgroundColor: "#FFFFFF", fontWeight: "bold" }}>Trial Name</TableCell>
              <TableCell sx={{ backgroundColor: "#FFFFFF", fontWeight: "bold" }}>Applicant Name</TableCell>
              <TableCell sx={{ backgroundColor: "#FFFFFF", fontWeight: "bold" }}>Application Date</TableCell>
              <TableCell sx={{ backgroundColor: "#FFFFFF", fontWeight: "bold" }}>Your Read Status</TableCell>
              <TableCell sx={{ backgroundColor: "#FFFFFF", fontWeight: "bold" }}>Your Response</TableCell>
              <TableCell sx={{ backgroundColor: "#FFFFFF", fontWeight: "bold" }}>Your Response Date</TableCell>
              <TableCell sx={{ backgroundColor: "#FFFFFF", fontWeight: "bold" }}>Applicant's Read Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {actionsList.map((action, index) => (
                <React.Fragment key={action.action.ActionID}>
                  <TableRow
                    onClick={() => handleRowClick(action, index)}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell>{action.trialName}</TableCell>
                    <TableCell>{action.action.initiatorName}</TableCell>
                    <TableCell>
                      {new Date(action.action.Timestamp).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      {renderStatusButton(
                        action.requests[0].ReadStatus === 1 ? "Read" : "Unread"
                      )}
                    </TableCell>
                    <TableCell>{renderResponseSelect(action)}</TableCell>
                    <TableCell>
                      {action.responses[0]?.Timestamp
                        ? new Date(action.responses[0].Timestamp).toLocaleString()
                        : "Will update after your response"}
                    </TableCell>
                    <TableCell>{renderApplicantStatus(action.responses)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={7} style={{ padding: 0 }}>
                      <Collapse in={expandedRow === index} timeout="auto" unmountOnExit>
                        <Box sx={{ p: 2 }}>
                          <Typography variant="subtitle1">Details</Typography>
                          <Typography variant="body2">
                            <strong>Applicant Note:</strong>{" "}
                            {action.requests[0]?.Note || "N/A"}
                          </Typography>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
      </Snackbar>
      {/* 对话框 */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        PaperProps={{
          style: {
            borderRadius: 20,
          },
        }}
      >
        <CustomDialogTitleStyled>Confirm Action</CustomDialogTitleStyled>
        <DialogContent>
          <DialogContentText
            sx={{ textAlign: "center", fontSize: 16, fontWeight: 400 }}
          >
            Are you sure you want to {dialogAction} this request?
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="note"
            label="Note"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            value={dialogNote}
            onChange={(e) => setDialogNote(e.target.value)}
          />
        </DialogContent>
        <CustomDialogActionsStyled>
          <CustomDialogButton
            variant="contained"
            color="primary"
            onClick={handleDialogConfirm}
          >
            Confirm
          </CustomDialogButton>
          <CustomDialogButton
            variant="outlined"
            color="inherit"
            onClick={handleDialogCancel}
          >
            Cancel
          </CustomDialogButton>
        </CustomDialogActionsStyled>
      </Dialog>
    </Box>
    </Card>
    </PageLayout>
  );
};

export default ClinicalStaffManagement;
