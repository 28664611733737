import React from "react";
import { List, ListItem, Typography, Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
function OperationBox(props) {

  

  const listItemStyle = {
    borderRadius: "10px",
    padding: "15px 20px",
    backgroundColor: "#E4EFFD",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  };
  const listStyle = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography
          variant="h6"
          style={{ color: "#59748A", marginLeft: "1.25rem" }}
        >
          {props.title}
        </Typography>
        <Button
          onClick={props.onClick}
          startIcon={<AddCircleIcon />}
          sx={{
            height: "100%",
            color: "#1A4EBA",
            fontWeight: "bold",
            textTransform: "none",
            paddingTop: "0",
            marginLeft: "1rem",
          }}
        >
          {props.buttonText}
        </Button>
        <List style={listStyle}>
          {props.listData.map((diagnosis, index) => (
            <ListItem key={index} style={listItemStyle}>
              <Typography
                variant="body1"
                sx={{ color: "#5F7488", fontWeight: "600" }}
              >
                {diagnosis.specialty}
              </Typography>
              <Typography
                variant="body2"
                style={{ textAlign: "right" }}
                color="textSecondary"
                sx={{ color: " #9E9E9E" }}
              >
                {diagnosis.date}
              </Typography>
            </ListItem>
          ))}
        </List>
      </div>
    </>
  );
}

export default OperationBox;
