import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux-store/CartSlice";
import { BiPlusCircle } from "react-icons/bi";
import { product } from "../../assets/data/data";
import "./product.css";

function ProductCart() {
  const dispatch = useDispatch();
  const [successMessage, setSuccessMessage] = useState(null); // Track the selected product for success message

  const handleImageClick = (item) => {
    dispatch(addToCart(item)); // Add the product to the cart
    setSuccessMessage(item.id); // Set the success message for the clicked product
    setTimeout(() => setSuccessMessage(null), 3000); // Clear the message after 3 seconds
  };

  return (
    <div
      className="e-pharmacy-section__products"
      style={{
        marginTop: "20px",
        display: "grid",
        gridTemplateColumns: "repeat(5, 1fr)", // Ensure 5 products per row
        gap: "20px",
        justifyItems: "center", // Center items in the grid
      }}
    >
      {product.map((item) => (
        <div
          key={item.id}
          className="e-pharmacy-box__product"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            border: "1px solid #ddd",
            padding: "10px",
            borderRadius: "8px",
            width: "180px", // Slightly smaller width for better spacing
            boxSizing: "border-box", // Ensure padding doesn't affect width
            position: "relative", // Ensure items aren't pushed down
          }}
        >
          {/* Product Image */}
          <div
            className="e-pharmacy-img"
            style={{ marginBottom: "10px", cursor: "pointer" }}
            onClick={() => handleImageClick(item)} // Trigger action on image click
          >
            <img
              src={item.img}
              alt={item.name}
              style={{
                width: "100%",
                height: "150px",
                objectFit: "cover",
                borderRadius: "8px",
              }}
            />
          </div>

          {/* Product Price */}
          <h3
            className="e-pharmacy-fs-5 text-black"
            style={{
              fontSize: "18px",
              color: "#333",
              marginBottom: "8px",
              fontWeight: "500",
            }}
          >
            ${item.price}
          </h3>

          {/* Product Name */}
          <p
            className="e-pharmacy-text-black-50"
            style={{
              fontSize: "14px",
              color: "rgba(0, 0, 0, 0.7)",
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            {item.name}
          </p>

          {/* Success Message */}
          {successMessage === item.id && (
            <div
              style={{
                backgroundColor: "#28a745",
                color: "white",
                padding: "8px",
                borderRadius: "4px",
                textAlign: "center",
                width: "100%",
                position: "absolute",
                bottom: "10px", // Position the message at the bottom of the card
              }}
            >
              Product added successfully!
            </div>
          )}

          {/* Add to Cart Button (No Text, just the icon) */}
          <button
            onClick={() => handleImageClick(item)} // Trigger action on button click
            style={{
              backgroundColor: "#007BFF",
              color: "white",
              border: "none",
              padding: "8px 12px",
              fontSize: "16px",
              borderRadius: "4px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
              position: "absolute", // Ensure button is inside the product card
              bottom: "10px", // Position button at the bottom of the card
              width: "100%",
              visibility: "hidden", // Hide the button
            }}
          >
            <BiPlusCircle style={{ fontSize: "20px" }} />
          </button>
        </div>
      ))}
    </div>
  );
}

export default ProductCart;
