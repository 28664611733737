import React, { useState } from "react";
import axios from "axios";
import "./tableUpdate.css";

function TableUpdate() {
  const [fetchTableName, setFetchTableName] = useState("");
  const [insertTableName, setInsertTableName] = useState("");
  const [insertData, setInsertData] = useState("");
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const apiUrl =
    "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/table";

  const fetchTableData = async (event) => {
    event.preventDefault();
    setError(null);
    setResponse(null);

    try {
      const res = await axios.get(`${apiUrl}/${fetchTableName}`);
      setResponse(res.data);
    } catch (err) {
      setError(err.response?.data?.error || "Error fetching data.");
    }
  };

  const insertTableData = async (event) => {
    event.preventDefault();
    setError(null);
    setResponse(null);

    let parsedData;
    try {
      parsedData = JSON.parse(insertData);
    } catch (err) {
      setError("Invalid JSON data.");
      return;
    }

    try {
      const res = await axios.post(`${apiUrl}/${insertTableName}`, parsedData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setResponse(res.data);
    } catch (err) {
      setError(err.response?.data?.error || "Error inserting data.");
    }
  };

  // Helper function to convert JSON data to CSV format
  const convertToCSV = (data) => {
    if (!Array.isArray(data) || data.length === 0) return "";
    const keys = Object.keys(data[0]);
    const csvRows = [keys.join(",")];
    data.forEach((row) => {
      csvRows.push(keys.map((key) => `"${row[key] || ""}"`).join(","));
    });
    return csvRows.join("\n");
  };

  const downloadCSV = async () => {
    setError(null);
    setResponse(null);

    if (!fetchTableName) {
      setError("Please enter a table name to fetch data.");
      return;
    }

    try {
      const res = await axios.get(`${apiUrl}/${fetchTableName}`);
      const data = res.data;

      if (Array.isArray(data)) {
        const csvData = convertToCSV(data);
        const blob = new Blob([csvData], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${fetchTableName}.csv`;
        a.click();
        URL.revokeObjectURL(url);
      } else {
        setError("Fetched data is not in expected format.");
      }
    } catch (err) {
      setError(err.response?.data?.error || "Error downloading CSV.");
    }
  };

  const downloadJSON = async () => {
    setError(null);
    setResponse(null);

    if (!fetchTableName) {
      setError("Please enter a table name to fetch data.");
      return;
    }

    try {
      const res = await axios.get(`${apiUrl}/${fetchTableName}`);
      const data = res.data;

      const blob = new Blob([JSON.stringify(data, null, 2)], {
        type: "application/json",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fetchTableName}.json`;
      a.click();
      URL.revokeObjectURL(url);
    } catch (err) {
      setError(err.response?.data?.error || "Error downloading JSON.");
    }
  };

  return (
    <div className="table-update-App">
      <h1>Welcome to e-Hospital</h1>
      <p>Interact with the database using the form below:</p>

      {/* Fetch Data Form */}
      <form onSubmit={fetchTableData} className="table-update-form-container">
        <input
          type="text"
          value={fetchTableName}
          onChange={(e) => setFetchTableName(e.target.value)}
          placeholder="Enter table name to fetch data"
          required
          className="table-update-input"
        />
        <button type="submit" className="table-update-button">
          Fetch Data
        </button>
        <button
          type="button"
          className="table-update-button"
          onClick={downloadCSV}
        >
          Download CSV
        </button>
        <button
          type="button"
          className="table-update-button"
          onClick={downloadJSON}
        >
          Download JSON
        </button>
      </form>

      {/* Insert Data Form */}
      <form onSubmit={insertTableData} className="table-update-form-container">
        <input
          type="text"
          value={insertTableName}
          onChange={(e) => setInsertTableName(e.target.value)}
          placeholder="Enter table name to insert data"
          required
          className="table-update-input"
        />
        <textarea
          value={insertData}
          onChange={(e) => setInsertData(e.target.value)}
          placeholder="Enter JSON data for insertion"
          rows="5"
          required
          className="table-update-textarea"
        ></textarea>
        <button type="submit" className="table-update-button">
          Insert Data
        </button>
      </form>

      {/* Response and Error Display */}
      <div className="table-update-response-container">
        {error && <div className="table-update-error">Error: {error}</div>}
        {response && (
          <pre className="table-update-response">
            {JSON.stringify(response, null, 2)}
          </pre>
        )}
      </div>
    </div>
  );
}

export default TableUpdate;
