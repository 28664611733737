import React, { useState, useEffect } from "react";
import axios from "axios";
import { Typography, IconButton, Divider, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CommonCard from "./CommonCard";
import BASE_URLS from "./constants";

const Referrals = ({ patientId }) => {
  const [referrals, setReferrals] = useState([]);

  useEffect(() => {
    const fetchReferrals = async () => {
      try {
        const response = await axios.post(
          `${BASE_URLS.PRODUCTION}/getReferralByPatientID`,
          {
            patientId: patientId,
          }
        );
        const data = response.data;
        console.log("Referrals:", data);
        data.sort(
          (a, b) => new Date(b.referral_date) - new Date(a.referral_date)
        );
        setReferrals(data);
      } catch (error) {
        console.error("Error fetching patient referrals:", error);
      }
    };
    fetchReferrals();
  }, [patientId]);

  return (
    <CommonCard collapsible={true} title="Referrals">
      <div style={{ backgroundColor: "transparent" }}>
        {referrals.map((referral, index) => (
          <React.Fragment key={referral.referral_id}>
            <ReferralItem index={index} referral={referral} />
            {index < referrals.length - 1 && (
              <Divider style={{ backgroundColor: "#efefef" }} />
            )}
          </React.Fragment>
        ))}
      </div>
    </CommonCard>
  );
};

const ReferralItem = ({ referral, index }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  // Prepare the entries to display, filtering out null or undefined values
  const entries = [
    {
      label: "Patient Name",
      value: `${referral.patient_FName} ${referral.patient_LName}`.trim(),
    },
    {
      label: "Referral Message",
      value: referral.referral_message,
    },
    {
      label: "Referred Doctor",
      value:
        `${referral.referred_doctor_FName} ${referral.referred_doctor_LName}`.trim(),
    },
    {
      label: "Referred Doctor Specialization",
      value: referral.referred_doctor_specialization,
    },
    {
      label: "Referred Doctor Phone",
      value: referral.referred_doctor_phone,
    },
    {
      label: "Referring Doctor",
      value:
        `${referral.referring_doctor_FName} ${referral.referring_doctor_LName}`.trim(),
    },
  ].filter(
    (entry) =>
      entry.value !== null && entry.value !== undefined && entry.value !== ""
  );

  // Handle the referral date
  const referralDate = new Date(referral.referral_date);
  const displayDate = isNaN(referralDate.getTime())
    ? "2021-09-03"
    : referralDate.toLocaleDateString();

  return (
    <div style={{ textAlign: "left" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          padding: "8px 0",
          backgroundColor: "transparent",
        }}
        onClick={handleToggle}
      >
        <Typography variant="body1" style={{ flexGrow: 1 }}>
          {`${index + 1}. ${displayDate}`}
        </Typography>
        <IconButton
          size="small"
          style={{
            transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s",
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div style={{ paddingLeft: 16, paddingBottom: 8, color: "#555" }}>
          {entries.map((entry, idx) => (
            <React.Fragment key={entry.label}>
              <div style={{ display: "flex", marginBottom: 4 }}>
                <Typography
                  variant="body2"
                  style={{ width: "180px", fontWeight: "bold" }}
                >
                  {entry.label}:
                </Typography>
                <Typography variant="body2">
                  {entry.value || "Empty"}
                </Typography>
              </div>
              {idx < entries.length - 1 && (
                <Divider
                  style={{
                    backgroundColor: "#f0f0f0",
                    height: 1,
                    width: "50%",
                    marginLeft: 0,
                    marginTop: 4,
                    marginBottom: 4,
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export default Referrals;