import React, { useState, useEffect } from "react";
import axios from "axios";
import { Typography, IconButton, Divider, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CommonCard from "./CommonCard";
import BASE_URLS from "./constants";

const MedicalTest = ({ patientId }) => {
  const [medicalTests, setMedicalTests] = useState([]);

  useEffect(() => {
    const fetchMedicalTests = async () => {
      try {
        const response = await axios.post(
          `${BASE_URLS.PRODUCTION}/getMedicalTest`,
          {
            patientId: patientId,
          }
        );
        const data = response.data;
        setMedicalTests(Array.isArray(data) ? data : [data]);
      } catch (error) {
        console.error("Error fetching patient medical tests:", error);
      }
    };
    fetchMedicalTests();
  }, [patientId]);

  return (
    <CommonCard collapsible={true} title="Medical Tests" isDividingLine={false}>
      <div style={{ backgroundColor: "transparent" }}>
        {medicalTests.map((medicalTest, index) => (
          <React.Fragment key={index}>
            <MedicalTestItem medicalTest={medicalTest} />
            {index < medicalTests.length - 1 && (
              <Divider style={{ backgroundColor: "#efefef" }} />
            )}
          </React.Fragment>
        ))}
      </div>
    </CommonCard>
  );
};

const MedicalTestItem = ({ medicalTest }) => {
  // Display the contents of the "0" key directly
  const zeroContent = medicalTest["0"] || {};

  // The "1" key contains the sections to be displayed in collapsible items
  const oneContent = medicalTest["1"] || {};

  // Filter out empty sections
  const sections = Object.entries(oneContent).filter(
    ([sectionKey, sectionValue]) =>
      sectionValue &&
      ((Array.isArray(sectionValue) && sectionValue.length > 0) ||
        (!Array.isArray(sectionValue) && Object.keys(sectionValue).length > 0))
  );

  return (
    <div style={{ textAlign: "left" }}>
      {/* Render the contents of "0" directly */}
      {renderKeyValuePairs(zeroContent, "180px")}

      {/* Render each item under "1" as its own collapsible section */}
      {sections.map(([sectionKey, sectionValue], index) => (
        <React.Fragment key={sectionKey}>
          <SectionCollapse title={formatKey(sectionKey)} data={sectionValue} />
          {index < sections.length - 1 && (
            <Divider style={{ backgroundColor: "#e0e0e0" }} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

const SectionCollapse = ({ title, data }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  // If data is empty or null, don't render the section
  if (
    !data ||
    (Array.isArray(data) && data.length === 0) ||
    (typeof data === "object" && Object.keys(data).length === 0)
  ) {
    return null;
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          padding: "8px 0",
          backgroundColor: "transparent",
        }}
        onClick={handleToggle}
      >
        <Typography variant="body1" style={{ flexGrow: 1 }}>
          {title}
        </Typography>
        <IconButton
          size="small"
          style={{
            transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s",
          }}
        >
          <ExpandMoreIcon style={{ fontSize: 24 }} />
        </IconButton>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div style={{ paddingLeft: 16, paddingBottom: 8, color: "#555" }}>
          {Array.isArray(data)
            ? data.map((item, idx) => (
                <div key={idx} style={{ marginBottom: 8 }}>
                  {renderKeyValuePairs(item, "200px")}
                  {idx < data.length - 1 && (
                    <Divider
                      style={{ backgroundColor: "#f0f0f0", margin: "8px 0" }}
                    />
                  )}
                </div>
              ))
            : renderKeyValuePairs(data, "180px")}
        </div>
      </Collapse>
    </div>
  );
};

const renderKeyValuePairs = (dataObj, labelWidth) => {
  const entries = Object.entries(dataObj).filter(
    ([key, value]) =>
      value !== null &&
      value !== undefined &&
      value !== "" &&
      !(Array.isArray(value) && value.length === 0) &&
      !(typeof value === "object" && Object.keys(value).length === 0)
  );

  if (entries.length === 0) {
    return null;
  }

  return entries.map(([key, value], idx) => (
    <React.Fragment key={key}>
      <div style={{ display: "flex", marginBottom: 4 }}>
        <Typography
          variant="body2"
          style={{ width: labelWidth, fontWeight: "bold" }}
        >
          {formatKey(key)}:
        </Typography>
        <Typography variant="body2">{String(value)}</Typography>
      </div>
      {idx < entries.length - 1 && (
        <Divider
          style={{
            backgroundColor: "#f0f0f0",
            height: 1,
            width: "50%",
            marginLeft: 0,
            marginTop: 4,
            marginBottom: 4,
          }}
        />
      )}
    </React.Fragment>
  ));
};

const formatKey = (key) => {
  // Format the key to replace underscores with spaces and capitalize words
  return key.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
};

export default MedicalTest;
