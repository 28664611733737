import React from "react";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { useCallback } from "react";
import HomeIcon from "@mui/icons-material/Home";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MessageIcon from "@mui/icons-material/Message";
import ManagementIcon from '@mui/icons-material/AccountTree';
import FavoriteIcon from "@mui/icons-material/Favorite";
import LogoutIcon from "@mui/icons-material/Logout";
import Sidebar from "./SideBar";
import TopNavMobile from "./TopNavMobile";

const PharmaceutiSideBar = (props) => {
  const navigate = useNavigate();

  const getItem = (label, key, icon, action, children, type) => ({
    key, // 菜单项的唯一标识
    icon, // 菜单项的图标
    action, // 菜单项的动作（点击后执行的函数）
    children, // 菜单项的子项
    label, // 菜单项的标签
    type, // 菜单项的类型
  });

  //包含退出按钮的侧边栏
  const menuItems = useMemo(
    () => [
      getItem("Dashboard", "Dashboard", <HomeIcon />, "/pharmaceuticals/dashboard"),
      getItem("Clinical Trial", "Clinical Trial", <CalendarMonthIcon />, "/pharmaceuticals/clinicaltrial"),
      getItem("Messages", "Messages", <MessageIcon />, "/pharmaceuticals/messages"),
      getItem("Management", "Management", <ManagementIcon />, "/pharmaceuticals/management"),
      getItem("Help", "Help", <FavoriteIcon />, "/pharmaceuticals/help"),
      getItem("Log out", "Logout", <LogoutIcon />, () => {// 定义登出菜单项，点击后清除用户并导航到登录页面
        props.clearUser();//调用props的clearUser方法清除用户信息
        navigate("/LogIn");//导航到登录页面
      }),
    ],
    [navigate, props]//useMemo的依赖项，当navigate或props变化时重新计算menuItems
  );
//不包含退出按钮的侧边栏
  const menuItemsNoLogout = [
    getItem("Dashboard", "Dashboard", <HomeIcon />, "/pharmaceuticals/dashboard"),
    getItem("Clinical Trial", "Clinical Trial", <CalendarMonthIcon />, "/pharmaceuticals/clinicaltrial"),
    getItem("Messages", "Messages", <MessageIcon />, "/pharmaceuticals/messages"),  
    getItem("Management", "Management", <ManagementIcon />, "/pharmaceuticals/management"),
    getItem("Help", "Help", <FavoriteIcon />, "/pharmaceuticals/help"),  ]

  const onLogOut = useCallback(() => { // 使用useCallback钩子定义登出函数
      props.clearUser(); // 调用props的clearUser方法清除用户信息
      navigate("/LogIn"); // 导航到登录页面
    }, [navigate, props]); // useCallback的依赖项，当navigate或props变化时重新定义onLogOut

  // 判断屏幕宽度是否小于等于605像素（小屏幕）
  let width = window.innerWidth;
  if (width <= 605) {
    return (
      <>
        <TopNavMobile // 返回TopNavMobile组件并传入menuItemsNoLogout和onLogOut作为属性
          menuItems={menuItemsNoLogout}
          onLogOut={onLogOut}
        />
      </>
    );
  } else {
    return (
      <>
        <Sidebar // 返回Sidebar组件并传入menuItems作为属性
          menuItems={menuItems}
        />
      </>
    );
  }
};


export default PharmaceutiSideBar;
